import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/CardItem.css'

const SingleSelectBoxCardItem = ({ index, option, onItemSelected}) => {


    const handleClick = (event) => {
        event.preventDefault();
        onItemSelected(option);
    };

    return (
        <label className={`hjicdk1 hhbXPR dwlkSV `} tabIndex={index.toString()} onClick={handleClick} style={{ display: 'flex' , border: option.checked?'2px solid #12B2B3':'' }}>
            <input name="goal" type="radio" className="jQNPoG" value={option.id} style={{ marginTop: "5px" }} />
            <div style={{ textAlign: 'start' }}>
                <span className="jxGXvKN">
                    <span className="ghbokT hXbiJA">{option.title}</span>
                </span>
                {(!option.subtitle && !(option.subtitle.trim() === '')) ? null : (
                    <p className="dezAzo eGxKKN">{option.subtitle}</p>
                )}
            </div>
            <span className="kSpLAz">
                <img alt={option.title} src={option.imageUrl} className="item-image1" />
            </span>
        </label>
    );
};


SingleSelectBoxCardItem.propTypes = {
    index: PropTypes.number.isRequired, // Adjust the PropTypes accordingly
    option: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
    }),
    onItemSelected: PropTypes.func.isRequired
};

export default SingleSelectBoxCardItem;
