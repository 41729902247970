import React from "react";
import '../../assets/styles/FormSquareRadioBoxWithImage.css';
import PropTypes from 'prop-types';
import SingleSelectBoxCardItem from "../carditems/SingleSelectBoxCardItem";
import DetermineBodyType from "../../components/DetermineBodyType";

const FormSqurareRadioBoxWithImages = ({ step, onSelected, showDetermineBodyType }) => {

    return (
        <div>
            <div className="iopLoU">
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            {!step || (!step.subtitle && !(step.subtitle.trim() === '')) ? null : (
                                <p className="dezAzo eGxKKN">{step.subtitle}</p>
                            )}
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>

                    </div>
                    <div className="gppALw">
                        <div className="liJdVi">
                            {step.options.map((option, index) => (
                                <SingleSelectBoxCardItem
                                    key={option.id}
                                    index={index}
                                    option={option}
                                    onItemSelected={(value) => onSelected(value)}
                                />
                            ))}
                            {showDetermineBodyType && <DetermineBodyType />}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

FormSqurareRadioBoxWithImages.propTypes = {
    onSelected: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string.isRequired,
                imageUrl: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default FormSqurareRadioBoxWithImages;