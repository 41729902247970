
import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import ProgressBarComponent from "../../layout/ProgressBarLayout";
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../reducers/progressSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../assets/styles/StepProducts.css'
import RightArrowButton from '../../layout/button/RightArrowButton';
import BottomArrowButton from '../../layout/button/BottomArrowButton';
import ToggleSlider from '../../components/ToggleSlider';
import MultipleItemSelectionCointainer from '../../layout/MultipleItemSelectionContainer';
import { postData } from '../../reducers/uploadDataSlice'
import CircularProgressBar from '../../components/CircularProgress';


const productMap = new Map();

const StepProducts = () => {

    const products = [
        {
            id: "1",
            title: "Veggies",
            subtitle: "",
            imageUrl: "",
            options: [
                {
                    id: "1",
                    title: "Broccoli",
                },
                {
                    id: "2",
                    title: "Cauliflower",
                },
                {
                    id: "3",
                    title: "Onion",
                },
                {
                    id: "4",
                    title: "Bell pepper",
                },
                {
                    id: "5",
                    title: "Eggplant",
                },
                {
                    id: "6",
                    title: "Cabbage",
                },
                {
                    id: "7",
                    title: "Asparagus",
                },
                {
                    id: "8",
                    title: "Spinach",
                },
                {
                    id: "9",
                    title: "Cucumber",
                },
                {
                    id: "10",
                    title: "Tomato",
                }
            ]
        },
        {
            id: "2",
            title: "Grain",
            subtitle: "",
            imageUrl: "",
            options: [
                {
                    id: "1",
                    title: "Rice",
                },
                {
                    id: "2",
                    title: "Quinoa",
                },
                {
                    id: "3",
                    title: "Couscous",
                },
                {
                    id: "4",
                    title: "Buckwheat",
                },
                {
                    id: "5",
                    title: "Millet groats",
                },
                {
                    id: "6",
                    title: "Amaranth",
                },
                {
                    id: "7",
                    title: "Semolina",
                },
                {
                    id: "8",
                    title: "Cornmeal",
                },
                {
                    id: "9",
                    title: "Bulgur",
                },
            ]
        },
        {
            id: "3",
            title: "Ingredients",
            subtitle: "",
            imageUrl: "",
            options: [
                {
                    id: "1",
                    title: "Avacado",
                },
                {
                    id: "2",
                    title: "Beans",
                },
                {
                    id: "3",
                    title: "Eggs",
                },
                {
                    id: "4",
                    title: "Mushrooms",
                },
                {
                    id: "5",
                    title: "Cottage Cheese",
                },
                {
                    id: "6",
                    title: "Milk",
                },
                {
                    id: "7",
                    title: "Tofu",
                },
                {
                    id: "8",
                    title: "Milk",
                },
                {
                    id: "9",
                    title: "Hummus",
                },
                {
                    id: "10",
                    title: "Plant milk",
                },
                {
                    id: "11",
                    title: "Protein powder",
                },
                {
                    id: "12",
                    title: "Vegan protein powder",
                }
            ]
        },
        {
            id: "4",
            title: "Meat & Fish",
            subtitle: "",
            imageUrl: "",
            options: [
                {
                    id: "1",
                    title: "Turkey",
                },
                {
                    id: "2",
                    title: "Beef",
                },
                {
                    id: "3",
                    title: "Chicken",
                },
                {
                    id: "4",
                    title: "Seafood",
                },
                {
                    id: "5",
                    title: "Pork",
                },
                {
                    id: "6",
                    title: "Fish",
                }
            ]
        },
        {
            id: "5",
            title: "Fruit & Berries",
            subtitle: "",
            imageUrl: "",
            options: [
                {
                    id: "1",
                    title: "Citrus",
                },
                {
                    id: "2",
                    title: "Apple",
                },
                {
                    id: "3",
                    title: "Pear",
                },
                {
                    id: "4",
                    title: "Seafood",
                },
                {
                    id: "5",
                    title: "Kiwi",
                },
                {
                    id: "6",
                    title: "Bananas",
                },
                {
                    id: "7",
                    title: "Persimmon",
                },
                {
                    id: "8",
                    title: "Peach",
                },
                {
                    id: "9",
                    title: "Berries",
                },
                {
                    id: "10",
                    title: "Grape",
                },
                {
                    id: "11",
                    title: "Pomegranate",
                },
                {
                    id: "12",
                    title: "Tropical(pineapple, mango, papaya, pitahaya)",
                }
            ]
        },
    ];


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [valueSelected, setValueSelected] = useState(false);
    const [allowHealthHavenDecide, setallowHealthHavenDecide] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Receiving side
    const currentStepIndex = 21;
    const { items, loading, error } = useSelector((state) => state.data);
    const { data, selecteddata } = useSelector((state) => state.upload);
    const [currentStepData, setCurrentStepData] = useState(items?.[currentStepIndex]);



    const onNext = async () => {
        if (valueSelected) {
            try {
                const result = await dispatch(
                    postData({
                        key:"products",
                        value :  Array.from(productMap.values()),
                        data: { 
                           products: Array.from(productMap.values()) },
                        docId: data && data.docId !== undefined ? data.docId : "",
                    })
                );
                if (result.success) {
                    navigate("/step-challenge", { state: { currentStepIndex: currentStepIndex + 1 } });
                } else {
                    console.error('Post data failed.');
                }
            } catch (error) {
                console.error('Error during postData:', error);
            }
        }
    };

    const onSelected = (title, selectedValues) => {

        productMap.set(title, {
            category: title,
            values: selectedValues
        });


        if (productMap.size === products.length) {
            setValueSelected(true);
        } else {
            setValueSelected(false);
        }
    }

    const onSwitchToggled = (value) => {
        setallowHealthHavenDecide(value);
        setValueSelected(value);
        if (value) {
            productMap.clear();
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        dispatch(updateProgress({ progress: (((currentStepIndex + 1) / 27) * 100), total: 27, increament: (currentStepIndex + 1) }));
        return () => {
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);

    return (
        <div className="grid-bg1">
            <div style={{ position: "fixed", width: '100%', backgroundColor: "black", zIndex: "1" }}>
                <Header />
                <ProgressBarComponent />
            </div>
            <div style={{ paddingTop: '120px', backgroundColor: 'black' }}>
                <div className='cKVRhv1'>
                    <div className='gppALw'>
                        <div className="osnNu">
                            <h1 className="bpzQjr bsCFUQ">Let us create a meal plan based on your preferences</h1>
                            <p className="dezAzo jxCKWD">You can always adjust the meal plan later</p>
                        </div>
                    </div>
                    <div className="gppALw">

                        <div id="selector-wrapper" className="kHAZvO1 fyBvSm">
                            <div style={{ display: 'flex', flexDirection: 'row', color: 'white', fontSize: "8px", alignItems: 'center', width: "100%", justifyContent: 'space-between', marginBottom: "20px" }}>
                                <h1 className="kqxsQt" style={{ width: "400px" }}>Let Health Haven choose</h1>
                                <ToggleSlider onToggleSwitch={onSwitchToggled} />
                            </div>
                            <span className="dezAzo cXlQlV">We create a balanced meal plan for you, which supplies you with the nutrients your body needs to work out effectively</span>
                        </div>
                    </div>
                    <div className="gppALw">
                        <div style={{ pointerEvents: allowHealthHavenDecide ? "none" : "auto", paddingBottom: '50px' }}>
                            {products.map((product, index) => (
                                <MultipleItemSelectionCointainer key={product.id} product={product} onSelected={onSelected} allowHealthHavenDecide={allowHealthHavenDecide} />
                            ))}
                        </div>
                        <div className="container-arrow" style={{ marginTop: '20px' }}>
                            {screenWidth >= 1023 && (
                                <div style={{
                                    opacity: valueSelected ? 1 : 0.5,
                                    pointerEvents: valueSelected ? 'auto' : 'none',
                                }}>
                                    <RightArrowButton
                                        label={"Continue"}
                                        onClick={onNext}
                                        width="200px"
                                    />
                                </div>
                            )}
                        </div>

                    </div>

                </div>
                {screenWidth < 1023 &&
                    <div style={{
                        opacity: valueSelected ? 1 : 0.5,
                        pointerEvents: valueSelected ? 'auto' : 'none',
                    }}>
                        <BottomArrowButton onClick={onNext} label="Continue" />
                    </div>
                }
            </div>
        </div>

    );
}


export default StepProducts;