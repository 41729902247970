import React, { useState, useEffect } from "react";
import '../../assets/styles/FormSquareRadioBoxWithImage.css';
import PropTypes from 'prop-types';
import RightArrowButton from "../button/RightArrowButton";
import BottomArrowButton from "../button/BottomArrowButton";
import MultiSelectCardItemCheckbox from "../carditems/MultiSelectCardItemCheckbox";
import InfoComponent from "../../components/InfoComponent";
import '../../assets/styles/MultiSelectCardItemCheckbox.css'


const FormSquareCheckBox = ({ step, onSubmit, showInfo = false, info }) => {


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [infoShown, setInfoShown] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [options, setOptions] = useState(step.options);
    const [linkedMap, setLinkedMap] = useState(new Map());



    const onItemChecked = (index, checked, option) => {
        if (option.title === 'None of the above') {
            const updatedOptions = options.map((option, i) => ({
                ...option,
                checked: i === index ? checked : false,
            }));
            setOptions(updatedOptions);
            linkedMap.clear();
            if (checked) {
                linkedMap.set(option.title, option);
            }
            setLinkedMap(linkedMap);            
        } else {
            const updatedOptions = options.map((option, currentIndex) => {
                if (option.title === "None of the above") {
                    return { ...option, checked: false };
                } else {
                    if (currentIndex === index) {
                        return { ...option, checked: checked };
                    }
                    return option;
                }
            });
            setOptions(updatedOptions);
            linkedMap.delete("None of the above");
            if (linkedMap.has(option.title)) {
                linkedMap.delete(option.title);
            } else {
                if(checked){
                    linkedMap.set(option.title, option);
                }else{
                    linkedMap.delete(option.title);
                }
            }
        }
        if(linkedMap.size > 0){
            setItemSelected(true);
        }else{
            setItemSelected(false);
        }
    }

    const onClicked = () => {
        if (showInfo) {
            if (infoShown) {
                onSubmit(
                    Array.from(linkedMap.values())
                );
            } else {
                setInfoShown(true);
                scrollToDivById("bottom_view");
            }
        } else {
            onSubmit(
                Array.from(linkedMap.values())
            );
        }
    }

    function scrollToDivById(id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    useEffect(() => {
        const checkedIfOptionisChecked = () => {
            if (step?.options && Array.isArray(step?.options)&& step) {

                // Example operation: logging each option
                step.options.forEach(option => {
                    if(option.checked){
                        if (!linkedMap.has(option.title)) {
                            linkedMap.set(option.title, option);
                        }
                    }
                });
            }

          if(linkedMap.size > 0){
            setItemSelected(true);
          }
        };
        checkedIfOptionisChecked();
    }, []);

    return (
        <div style={{ justifyContent: 'center' }}>
            <div className="iopLoU">
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            {!step || (!step.subtitle && !(step.subtitle.trim() === '')) ? null : (
                                <p className="dezAzo eGxKKN">{step.subtitle}</p>
                            )}
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>
                    </div>
                    <div className="gppALw">
                        <div className="liJdVi">
                            {options.map((option, index) => (
                                <MultiSelectCardItemCheckbox
                                    key={index}
                                    index={index}
                                    option={option}
                                    onItemSelected={onItemChecked}
                                />
                            )
                            )}
                            {infoShown && <InfoComponent info={info} showInfo={true} />}
                            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', pointerEvents: (linkedMap.size>0 && itemSelected)? "auto" : "none", opacity: (linkedMap.size>0 && itemSelected)? 1 : 0.4, marginTop: '20px' }}>
                                <div className="container-arrow">
                                    {screenWidth >= 1023 && (<RightArrowButton label={"Continue"} onClick={onClicked} width="200px" />)}
                                </div>
                            </div>
                            <div id="bottom_view" style={{marginBottom:'200px'}}></div>
                        </div>
                    </div>

                </div>
            </div>


            {screenWidth < 1023 &&
                <div style={{ pointerEvents: (linkedMap.size>0 && itemSelected) ? "auto" : "none", opacity: (linkedMap.size>0 && itemSelected) ? 1 : 0.4 }}>
                    <BottomArrowButton onClick={onClicked} label="Continue" />
                </div>}

        </div>
    );
}

FormSquareCheckBox.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                imageUrl: PropTypes.string,
            })
        ).isRequired,
    }).isRequired,
    showInfo: PropTypes.bool,
    info: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        checked: PropTypes.bool
    }),
};

export default FormSquareCheckBox;