
import React, { useEffect, useState } from "react";
import '../../assets/styles/FormSquareRadioBoxWithImage.css';
import PropTypes from 'prop-types';
import RightArrowButton from "../button/RightArrowButton";
import SquareRadioWithTextCardItem from "../carditems/SquareRadioWithTextCardItem";
import BottomArrowButton from "../button/BottomArrowButton";
import InfoComponent from "../../components/InfoComponent";

const FormRadioWithText = ({ step, onSelected, showInfo = false, info }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [infoShown, setInfoShown] = useState(false);

    const [itemSelected, setItemSelected] = useState(false);
    const [options, setOptions] = useState(step.options);
    const [selectedOption, setSelectedOption] = useState(null);



    const onItemChecked = (index, checked, option) => {
        const updatedOptions = options.map((option, i) => ({
            ...option,
            checked: i === index ? checked : false,
        }));
        setOptions(updatedOptions);
        setItemSelected(true);
        setSelectedOption(option);
    }

    const onClicked = () => {

        if (showInfo) {
            if (infoShown) {
                onSelected(selectedOption);
            } else {
                setInfoShown(true);
                scrollToDivById("bottom_view");

            }
        } else {
            onSelected(selectedOption);
        }
    }


    function scrollToDivById(id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }

    useEffect(() => {
        // Find the previously checked option
        const previouslyCheckedOption = options.find(option => option.checked);
        // If there's a previously checked option, set it as the selected option
        if (previouslyCheckedOption) {
            setSelectedOption(previouslyCheckedOption);
            setItemSelected(true);
        }
    }, []); 


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div style={{ justifyContent: 'center' }}>
            <div className="iopLoU">
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            {!step || (!step.subtitle && !(step.subtitle.trim() === '')) ? null : (
                                <p className="dezAzo eGxKKN">{step.subtitle}</p>
                            )}
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>
                    </div>
                    <div className="gppALw">
                        <div className="liJdVi">
                            {options.map((option, index) => (
                                <SquareRadioWithTextCardItem
                                    key={option.id}
                                    index={index}
                                    option={option}
                                    checked={option.checked ?? false}
                                    onItemSelected={onItemChecked}
                                />
                            ))}

                            <div id = "info-section" className="liJdVi1">
                            {infoShown && <InfoComponent info={info} />}
                            </div>
                        </div>
                        
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <div style={{ maxWidth: "424px", textAlign: 'end', marginBottom: '20px', width: '100%' }}>
                                {screenWidth > 1023 && <div style={{ alignSelf: "end", pointerEvents: itemSelected ? "auto" : "none", opacity: itemSelected ? 1 : 0.4 }}>
                                    <RightArrowButton label={"Continue"} onClick={onClicked} width="200px" />
                                </div>}
                            </div>
                        </div>
                        <div id="bottom_view" style={{marginBottom:'150px'}}></div>
                    </div>
                </div>
            </div>
            {screenWidth < 1023 &&
                <div  style={{ pointerEvents: itemSelected ? "auto" : "none", opacity: itemSelected ? 1 : 0.4 }}>
                    <BottomArrowButton onClick={onClicked} label={"Continue"} />
                </div>}
        </div>
    );
}

FormRadioWithText.propTypes = {
    onSelected: PropTypes.func.isRequired,
    showInfo: PropTypes.bool,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                imageUrl: PropTypes.string,
                checked: PropTypes.bool
            })
        ).isRequired,
    }).isRequired,
    info: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isSelected: PropTypes.bool
    }),
};

export default FormRadioWithText;