import React, { useState, useEffect } from "react";
import '../assets/styles/SquareRadiobox.css';

const SquareRadioBox = ({ option, onSelected, allowHealthHavenDecide }) => {
    const [clicked, setClicked] = useState(false);


    useEffect(() => {
        if (allowHealthHavenDecide) {
            setClicked(false);
        }
    }, [allowHealthHavenDecide]);


    const handleClick = () => {
        setClicked(!clicked);
        onSelected(option);
    };

    const containerStyles = {
        border: clicked ? '2px solid #12B2B3' : '2px solid transparent',
    };

    return (
        <div
            className="blJLHD hPRORS dDZAwI"
            onClick={handleClick}
            style={containerStyles} >
            <div className="eBRzvd" style={{ color: 'white' }}>{option.title}</div>
        </div>
    );
};

export default SquareRadioBox;
