import React, { useEffect, useState } from 'react';
import '../../assets/styles/FormSingleTextInput.css';
import RightArrowButton from '../button/RightArrowButton';
import BottomArrowButton from '../button/BottomArrowButton';
import PropTypes from 'prop-types';

const FormSingleTextInput = ({ step, onSubmit }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [measureSystem, setMeasureSystem] = useState('METRIC');
    const [currentHeight, setCurrentHeight] = useState(step.seletedheight || '');
    const [measure, setMeasure] = useState(measureSystem === 'IMPERIAL' ? '' : ' cm');
    const [error, setError] = useState('');

    const onDone = (event) => {
        event.preventDefault();
        const height = parseInt(currentHeight, 10);
        if (measureSystem === 'IMPERIAL') {
            // Handle IMPERIAL system if needed
        } else {
            if (height < 90) {
                setError('Height must be greater than or equal to 90 cm.');
            } else if (height > 245) {
                setError('Height must be less than or equal to 245 cm.');
            } else {
                onSubmit({
                    height: currentHeight,
                    measure: measureSystem === 'IMPERIAL' ? 'ft, in' : 'cm'
                });
            }
        }
    };

    const handleMeasureSystemChange = (event) => {
        const value = event.target.value;
        setMeasureSystem(value);
        setCurrentHeight(''); // Reset height on measure system change
        setMeasure(value === 'IMPERIAL' ? '' : ' cm');
    };

    const handleHeightChange = (event) => {
        const value = event.target.value;
        const numericValue = value.replace(/\D/g, '');
        setCurrentHeight(numericValue);
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setCurrentHeight(step.seletedheight || '');
    }, [step.seletedheight]);

    return (
        <div>
            <form noValidate="" className="erPtxw" data-gtm-form-interact-id="0">
                <div className="klBxhW">
                    <div className="cKVRhv">
                        <div className="gppALw">
                            <div className="osnNu">
                                <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                            </div>
                        </div>
                        <div className="gppALw" style={{ color: 'white' }}>
                            <div className="cUiCiF">
                                <span className="kWqtoM">Units</span>
                                <div className="kxtXWv">
                                    <label id="metric-radio" className={measureSystem === 'METRIC' ? 'kHAZvO jcRkmu czwczU' : 'hjicdk3 jcRkmu czwczU'} tabIndex="0">
                                        <input
                                            name="measureSystem"
                                            type="radio"
                                            className="jQNPoG"
                                            value="METRIC"
                                            checked={measureSystem === 'METRIC'}
                                            onChange={handleMeasureSystemChange}
                                        />
                                        <span className="eBRzvd">cm</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="gppALw">
                            <label className="ieunKf">
                                <span className="efFBMo">Height ({measureSystem === 'IMPERIAL' ? 'ft, in' : 'cm'})</span>
                                <span className="dlDjfT">
                                    <input
                                        type="text"
                                        name="currentHeightImperial"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        className="fPLSVc"
                                        autoComplete="off"
                                        value={currentHeight}
                                        onChange={handleHeightChange}
                                    />
                                    <span className="hzkkDc">
                                        <span className="fhaFSA" style={{ opacity: 1 }}>
                                            <span style={{ opacity: 0, color: 'white' }}>{currentHeight}</span>
                                            <span>{measure}</span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                            {error && <p style={{ color: 'red', alignSelf: 'start', fontSize: '12px' }}>{error}</p>}
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', pointerEvents: currentHeight === '' ? 'none' : 'auto', opacity: currentHeight === '' ? 0.4 : 1, marginTop: '20px' }}>
                            <div className="container-arrow">
                                {screenWidth >= 1023 && (
                                    <RightArrowButton
                                        label={"Continue"}
                                        onClick={onDone}
                                        width="200px"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {screenWidth < 1023 && (
                        <div style={{ pointerEvents: currentHeight === '' ? 'none' : 'auto', opacity: currentHeight === '' ? 0.4 : 1 }}>
                            <BottomArrowButton onClick={onDone} label="Continue" />
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

FormSingleTextInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default FormSingleTextInput;
