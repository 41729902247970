import React from "react";
import "../../assets/styles/CommitmentDialog.css";
import CloselIcon from '@mui/icons-material/Close';


const CommitmentDialog = ({ onResume }) => {

    return (
        <div theme="DEFAULT" className="kgPnpb lgEKak" style={{ alignItems: "center", inset: "0px", display: "flex", overflow: "auto", position: "fixed" }}>
            <div tabIndex="-1" style={{ margin: "auto" }}>
                <div className="hFIkTS1 ModalInner">
                    <button type="button" title="Close" className="jwPRtY" style={{ color: 'white' }} onClick={onResume}>
                        <CloselIcon />
                    </button>
                    <div className="hHfqWf">
                        <div className="gxFBtW">🎉</div>
                        <p className="dezAzo gWIxoW">Almost done!</p>
                    </div>
                    <h1 className="bpzQjr cTeCEL">
                        <span className="kFVZPo">Are you ready</span> to make the commitment?
                    </h1>
                    <label id="selector-wrapper" className="hjicdk123 fytPbx" tabIndex="0" onClick={onResume}>
                        <input name="commitment" type="radio" className="jQNPoG" value="TOMORROW" />Yes, I will do my first workout tomorrow 👌
                    </label>
                    <label id="selector-wrapper" className="hjicdk123 fytPbx" tabIndex="0" onClick={onResume}>
                        <input name="commitment" type="radio" className="jQNPoG" value="TODAY" />Yes! I will do my first workout today! 😎
                    </label>
                    <label id="selector-wrapper" className="hjicdk123 fytPbx" tabIndex="0" onClick={onResume}>
                        <input name="commitment" type="radio" className="jQNPoG" value="NOT_READY" />I’m not ready to make the commitment
                    </label>
                </div>
            </div>
        </div>
    );
}

export default CommitmentDialog;