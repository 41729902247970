import React from 'react';
import '../assets/styles/CustomDot.css';

const CustomDot = ({ onClick, active }) => {
    return (
        <li className={active ? 'custom-dot active' : 'custom-dot'} onClick={onClick}>
            <button/>
        </li>
    );
};

export default CustomDot;