import React, { useRef } from "react";
import './../assets/styles/FinalResult.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReviewCardItem from "../layout/carditems/ReviewCardItem";
import CustomDot from "./CustomDot";

const ReviewSection = () => {
    const carouselRef = useRef(null);

    const reviewerList = [
        {
            name: "Ash Karandawala",
            review: "The Health Haven app is easy to navigate and use. I was quickly connected with a local PT who helped me achieve my goals. All comms and payments were made easy through HH",
            userimage: ''
        },
        {
            name: "Jessica Rodriguez",
            review: "This app is an incredible way to find a trainer! On my own I didn’t know where to start and my local gym was very costly, so I was totally lost. Finding this helped me find a trainer to reach my goals!",
            userimage: '',
        },
        {
            name: "Fafiolu Newall",
            review: "Amazing app; I was able to quickly and easily find a personal trainer and nutritionist which was proving difficult to do on my own. Would highly recommend!",
            userimage: '',
        },
        {
            name: "Glenn Coburn",
            review: "Fantastic app, easy to navigate and the best trainers, supportive and results focused training!",
            userimage: '',
        },
        {
            name: "Tom Vidjak",
            review: "Very easy to use app. Overall great experience, would recommend",
            userimage: '',
        },
        {
            name: "Marcus Yeow",
            review: "As someone looking for 1 on 1 training this app really helped me out! Interface was really intuitive and streamlined. Training itself was quality too, can’t wait to explore more of this app.",
            userimage: 'marcus.png'
        },
        {
            name: "Felix Medd",
            review: "Can't recommend enough! The app is for helping you connect with PTs that have expertise in specific areas, as well as providing the ability to review many different trainers to find one that is most suitable",
            userimage: 'felix.png'
        },
        {
            name: "Ashish Thapa",
            review: "I am a NHS worker and I am self isolating. I had a really hard time to spend my days. My activities came down to almost zero and my diet started to get messier...I have never seen anyone so dedicated with their work and it seems he knew what he was doing. I am going to follow his plan and stick to it. I have full trust in the advice I've been given as they are the most professional in this area. I recommend this service to everyone trying to live a healthy life. Not to be thought twice. Just get on with it. Get the app",
            userimage: ''
        }
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 767 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1
        }
    };

    return (
        <div className="juFqh">
            <h3 className="dcMtwF eHeNa-d">We helped over </h3>
            <h1 className="lfFgMS jFGiq"><span>100</span> people</h1>
            <p className="fKTIxx">to achieve their dream physique</p>
            <div className="lbaJjy">
                <div className="egVRIa">
                    <div className="ReviewsCarousel" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <div style={{ width: '100%', maxWidth: '1044px', padding: '0px 4px', overflow: 'hidden' }}>
                            <Carousel
                                ref={carouselRef}
                                arrows={true}
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                dotListClass="custom-dot-list-style"
                                responsive={responsive}
                                renderDotsOutside={true}
                                customDot={<CustomDot />}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                centerMode={false}
                                autoPlaySpeed={4000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType={"mobile"}
                            >
                                {reviewerList.map((review, index) => (
                                    <ReviewCardItem key={index} review={review} />
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewSection;
