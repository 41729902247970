import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import ProgressBarComponent from "../../layout/ProgressBarLayout";
import DynamicFormBuilder from "../../layout/formbuilder/DynamicFormBuider";
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../reducers/progressSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { postData } from '../../reducers/uploadDataSlice'
import { fetchItems } from '../../reducers/getStepsSlice';
import CircularProgressBar from '../../components/CircularProgress';

const StepLevelOfFitness = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const currentStepIndex = 10;
    const { items, loading, error } = useSelector((state) => state.data);
    const { data, selecteddata } = useSelector((state) => state.upload);
    const [leveOfFitness, setLeveOfFitness] = useState(null);


    useEffect(() => {
        dispatch(fetchItems());
    }, [dispatch]);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);
    

    const onNext = async (value) => {
        try {
            const result = await dispatch(
                postData({
                    key:'level_of_fitness',
                    value: value,
                    data: { level_of_fitness: value },
                    docId: data && data.docId !== undefined ? data.docId : "",
                })
            );
            if (result.success) {
                navigate('/step-workouts', { state: { currentStepIndex: currentStepIndex + 1 } });
            } else {
                console.error('Post data failed.');
            }
        } catch (error) {
            console.error('Error during postData:', error);
        }
    };

    useEffect(() => {
        dispatch(updateProgress({ progress: (((currentStepIndex + 1) / 27) * 100), total: 27, increament: (currentStepIndex + 1) }));
        return () => {
        };
    }, []);


    useEffect(() => {

        if(selecteddata && selecteddata.level_of_fitness){
            setLeveOfFitness(selecteddata.level_of_fitness);
        }
    }, [selecteddata.level_of_fitness]);


    const currentStepData = { ...items?.[currentStepIndex],  selectedLevelOfFitness: leveOfFitness };


    return (
        <div style={{ backgroundColor: "black" }}>
            <div style={{ position: "fixed", width: '100%', backgroundColor: 'black' }}>
                <Header />
                <ProgressBarComponent />
            </div>
            <div style={{ color: 'white', paddingTop: '120px' }}>
                {loading ? (
                    <CircularProgressBar />
                ) : error ? (
                    <div style={{ backgroundColor: 'black' }}></div>
                ) : currentStepData ? (
                    <DynamicFormBuilder step={currentStepData} onNext={onNext} />
                ) : (
                    <div style={{ backgroundColor: 'black' }}></div>
                )}
            </div>
        </div>
    );
}


export default StepLevelOfFitness;