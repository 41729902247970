
import { createSlice } from '@reduxjs/toolkit';
import NetworkService from '../services/network/networkservice';

const networkservice = new NetworkService();

const uploadDataSlice = createSlice({
  name: 'upload',
  initialState: {
    data: {},
    selecteddata :{},
    error: null,
  },
  reducers: {
    uploadDataStart(state) {
      state.status = true;
      state.data = {};
      state.error = null;
    },
    uploadSuccess(state, action) {
      state.data = action.payload;
      state.error = null;
    },
    uploadDataFailure(state, action) {
      state.error = "Failed to upload the data";
      state.error = action.payload;
    },
    setSelectedValue(state, action) {
      if (!state.selecteddata) {
        state.selecteddata = {};
      }
      const {key, value } = action.payload;
      state.selecteddata[key] = value;  // Use destructuring for better readability

    },
    resetSelectedData(state,action) {
      state.selecteddata = {};
      state.data = {};
    }
  },
});

export const { uploadDataStart, uploadSuccess, uploadDataFailure,setSelectedValue, resetSelectedData} = uploadDataSlice.actions;


export const postData = (payload) => async (dispatch) => {
  dispatch(setSelectedValue(payload));
  try {
    const data = await networkservice.postData(payload);
    if (data.success) {
      dispatch(uploadSuccess(data));
    } else {
      dispatch(uploadDataFailure(data));
    }
    return data;
  } catch (error) {
    const data = { success: false, message: error.message };
    dispatch(uploadDataFailure(data));
    return data;
  }
}

export const  updateSelectedOption = (payload) => async (dispatch) => {
  dispatch(setSelectedValue(payload));
}


export const  resetSelectedValue = () => async (dispatch) => {
  dispatch(resetSelectedData({}));
}


export const resetData = () => async (dispatch) => {
  dispatch(uploadDataStart());
}

export const onCheckoutSuccess = (payload) => async (dispatch) => {
  
  try {
    const data = await networkservice.onCheckoutSuccess(payload.attributes);
    if (data.success) {
    } else {
    }
    return data;
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export default uploadDataSlice.reducer;