import React, { useState } from 'react';
import '../assets/styles/ToggleSlider.css';


const ToggleSlider = ({ onToggleSwitch }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    onToggleSwitch(!checked);
  };

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <span className="switch" />
    </label>

  );
};

export default ToggleSlider;