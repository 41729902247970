import React from 'react';
import '../../assets/styles/BottomArrowButton.css'
import rightArrow from '../../assets/images/right-arrow.png';


const BottomArrowButton = ({ onClick, label }) => {
    return (
        <div className="gcuvsh">
            <button type="submit" title="Continue" className="fdiIEf hGJFMt" onClick={onClick}>
                <p className="kbMuAn">{label}</p>
                <span>
                    <img src={rightArrow} alt='' style={{ width: '20px', height: '20px' }} />
                </span>
            </button>
        </div>
    );
};

export default BottomArrowButton;
