import React, { useState } from 'react';
import '../../assets/styles/HowdoesItWork.css';
import users from '../../assets/images/people.png';
import pick from '../../assets/images/picking.png';
import clipboard from '../../assets/images/clipboard.png';
import analysis from '../../assets/images/graph-analysis.png';
import step from '../../assets/images/advancement.png';
import community from '../../assets/images/discussion.png';





const faqs = [
    { question: "Let us know your health goals", answer: "Answer a few short questions to let us know what your immediate and long-term fitness goals are, as well as your training history so we can best tailor our pursuit for your trainer. Then, subscribe to Health Haven Premium by selecting the payment option that suits you.", icon: users },
    { question: "Take your pick", answer: "Select from a carefully handpicked shortlist of trainers we present to you based on the information you provided. Don't like any of the options we've presented? No problem, we'll iterate until you find the one. It's not always love at first sight.", icon:pick },
    { question: "Start planning", answer: "Jump on a call to get to know your coach and create a training schedule. Throughout your journey, you will continue to fine-tune your training and diet to be as effective and time-efficient as possible.", icon:clipboard},
    { question: "Streamline your day-to-day", answer: "Use our mobile app to access your exercises and plans, book in for your 1-2-1s and manage your relationship with your trainer whilst carrying on with the things you currently enjoy doing. Updates from your trainer will help you stay on course.",icon:community},
    { question: "Monitor Progress", answer: "Together with your trainer, you can monitor your progress, reward accomplishments, and fine-tune your routine using detailed insights.",icon:analysis},
    { question: "With you every step of the way", answer: "A member of our team will always be just an email away and we will always make sure someone is available to jump on a call if necessary to help you along the way. Our in-house team aren't just VAs and technical support - we have multiple nutritionists and health fanatics onboard who can help guide you in the right direction.",icon:step}


];

function HowdoesItWorkItem({ faq }) {
    return (
        <div className="howdoesitwork-item">
            <div>
                <img src={faq.icon} alt={faq.icon} className="faq-image"  style={{color:'white'}}/>
                <div>
                    <h4 className="howdoesitwork-question">
                        {faq.question}
                    </h4>
                    <p className="howdoesitwork-answer">{faq.answer}</p>
                </div>
            </div>
        </div>
    );
}

const HowDoesItworksSection = () => {
    return (
        <div style={{ width: '100%', justifyContent: 'center', textAlign: 'center', display: 'flex', margin: '30px auto' }}>
            <div className="howdoesitwork-container">
                <h3 className="howdoesitwork-title">How does it work?</h3>
                <div className="howdoesitwork-grid">
                    {faqs.map(faq => <HowdoesItWorkItem key={faq.question} faq={faq} />)}
                </div>
            </div>
        </div>

    );
}

export default HowDoesItworksSection;