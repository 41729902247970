
import React from "react";
import '../../assets/styles/Main.css'
import screen from '../../assets/images/appTeaser.png'
import rightArrow from '../../assets/images/right-arrow.png'
import appstore from '../../assets/images/download-from-appstore.svg'
import playstore from '../../assets/images/download-from-playstore.svg'
import { useNavigate } from 'react-router-dom';
import { resetData } from "../../reducers/uploadDataSlice";
import { useDispatch } from "react-redux";


const Main = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const onClick = (event) => {
        event.preventDefault();
        dispatch(resetData());
        navigate("/step-goal");
    };


    return (
        <div className="dBqGlA">
            <div className="jxBQkj">
                <h1 className="lfFgMS jbOtEl">Health Haven</h1>
                <div className="cDpolK1 jEFptg" style={{display:'flex', justifyContent:'center'}}>
                    <p >At last, an app that makes better health, sleep and longevity accessible and effective for all.</p>
                </div>
                <div className="gDLWVz" onClick={onClick}>
                    <span className="eOPdCo">
                        <p className="kbMuAn">Choose a program</p>
                        <img src={rightArrow} alt="right-arrow" style={{ width: "20px", height: "20px", marginLeft: "30px" }} />
                    </span>
                </div>
                <div className="gxSiS">
                    <div className="lgDkiv">
                        <img className="imgQrT" alt="is an app that makes regular workouts accessible, effective and joyful" src={screen} style={{}} />
                    </div>
                </div>
                <div className="ceGSfP">
                    <a href="https://apps.apple.com/au/app/health-haven/id1467627663" className="imgPaStore">
                        <img src={appstore} alt="download-from-appstore" style={{ height: "55px", width: '170px' }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.gumbuddy.umarraj.healthhaven" className="imgPaStore">
                        <img src={playstore} alt="download-from-appstore" style={{ height: "55px", width: '170px' }} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Main;