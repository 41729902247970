import React, { useEffect , useState} from 'react';
import Header from "../../layout/header/Header";
import ProgressBarComponent from "../../layout/ProgressBarLayout";
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../reducers/progressSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import DynamicFormBuilder from '../../layout/formbuilder/DynamicFormBuider';
import { postData } from '../../reducers/uploadDataSlice'
import { fetchItems } from '../../reducers/getStepsSlice';


const StepTargetWeight = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const info = {
    "icon": "muscle.png",
    "title": '37% of Havenistas*',
    "description": "responded in the same way. Health Haven will help you to develop the right habits, regardless of your experience, ability or disability."
  }

  const currentStepIndex = 9;

  const { items} = useSelector((state) => state.data);
  const { data, selecteddata } = useSelector((state) => state.upload);
  const [weight, setWeight] = useState(null);


  useEffect(() => {
    dispatch(fetchItems());
}, [dispatch]);



  const onNext = async (value) => {
    try {
      const result = await dispatch(
        postData({
          key:'weight',
          value:value,
          data: { weight: value },
          docId: data && data.docId !== undefined ? data.docId : "",
        })
      );
      if (result.success) {
        navigate('/step-level-of-fitness', { state: { currentStepIndex: currentStepIndex + 1 } });
      } else {
        console.error('Post data failed.');
      }
    } catch (error) {
      console.error('Error during postData:', error);
    }
  };

  useEffect(() => {
    dispatch(updateProgress({ progress: (((currentStepIndex + 1) / 27) * 100), total: 27, increament: (currentStepIndex + 1) }));
    return () => {
    };
  }, []);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
}, [location.pathname]);



useEffect(() => {
  if(selecteddata && selecteddata.height && selecteddata.height.height){
      setWeight(selecteddata.weight);
  }
}, [selecteddata.weight]);


const currentStepData = { ...items?.[currentStepIndex], currentWeight: weight};


  return (
    <div className="grid-bg1">
      <div style={{ position: "fixed", width: '100%', backgroundColor: "black" }}>
        <Header />
        <ProgressBarComponent />
      </div>
      <div style={{ paddingTop: '120px', backgroundColor: "black", paddingBottom: '50px' }}>
        <DynamicFormBuilder step={currentStepData} onNext={onNext} info={info} showInfo={true} />
      </div>
    </div>

  );
}


export default StepTargetWeight;