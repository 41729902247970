import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import ProgressBarComponent from "../../layout/ProgressBarLayout";
import DynamicFormBuilder from "../../layout/formbuilder/DynamicFormBuider";
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../reducers/progressSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { postData } from '../../reducers/uploadDataSlice';
import { fetchItems } from '../../reducers/getStepsSlice';
import CircularProgressBar from '../../components/CircularProgress';


const StepTargetBody = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const currentStepIndex = 1;
  const { items, loading, error, selectedBodyGoal } = useSelector((state) => state.data);
  const { data, selecteddata } = useSelector((state) => state.upload);
  const item = items[currentStepIndex];
  const [options, setOptions] = useState([]);


  const onNext = async (value) => {
    try {
      const result = await dispatch(
        postData({
          key: "target_body",
          value: value,
          data: { target_body: value },
          docId: data && data.docId !== undefined ? data.docId : "",
        })
      );
      if (result.success) {
        navigate('/step-bodytype', { state: { currentStepIndex: currentStepIndex + 1 } });
      } else {
        console.error('Post data failed.');
      }
    } catch (error) {
      console.error('Error during postData:', error);
    }
  };

  useEffect(() => {

    const updateOptions = (options) => {
      if (selecteddata && selecteddata.target_body && options) {
        var updatedOptions = options.map(option => ({
          ...option,
          checked: (selecteddata.target_body.id === option.id && selecteddata.target_body.title === option.title) ? true : false,
        }));
        setOptions(updatedOptions);
      } else {
        setOptions(options);
      }
    };

    const getOptions = () => {
      if (selectedBodyGoal === null) {
        updateOptions(item.options.loose_weight);
      } else {
        if (selectedBodyGoal.id === 1) {
          updateOptions(item.options.loose_weight);
        } else if (selectedBodyGoal.id === 2) {
          updateOptions(item.options.gain_muscle_mass);
        } else {
          updateOptions(item.options.get_sherdded);
        }
      }
    };
    getOptions();
  }, [selectedBodyGoal, selecteddata.target_body]);


  // useEffect(() => {
  //   const setSelectedOption = () => {

  //   };

  //   setSelectedOption();
  // }, []);

  useEffect(() => {
    dispatch(fetchItems());
  }, [dispatch]);


  useEffect(() => {
    dispatch(updateProgress({ progress: (((currentStepIndex + 1) / 27) * 100), total: 27, increament: (currentStepIndex + 1) }));
    return () => {
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
  }, [location.pathname]);




  const step = { ...item, subtitle: `Ok, so your goal is to ${selectedBodyGoal.title}`, options: options };

  return (
    <div className="grid-bg1">
      <div style={{ position: "fixed", width: '100%', backgroundColor: "black" }}>
        <Header />
        <ProgressBarComponent />
      </div>
      <div style={{ height: '100%', color: 'white', backgroundColor: 'black' }}>
        {loading ? (
          <CircularProgressBar />
        ) : error ? (
          <div style={{ backgroundColor: 'black' }}></div>
        ) : step ? (
          <div style={{paddingTop: '120px'}}>
          <DynamicFormBuilder step={step} onNext={onNext} />
          </div>
        ) : (
          <div style={{ backgroundColor: 'black' }}></div>
        )}
      </div>
    </div>
  );
}


export default StepTargetBody;