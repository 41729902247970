import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import RightArrowButton from '../../layout/button/RightArrowButton';
import BottomArrowButton from '../../layout/button/BottomArrowButton';
import '../../assets/styles/StepName.css'
import SmallProgressBarComponent from '../../components/SmallPrograssBarComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBarComponent from '../../layout/ProgressBarLayout';
import { postData } from '../../reducers/uploadDataSlice'
import { useDispatch, useSelector } from 'react-redux';



const StepName = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [name, setName] = useState('');


    const { data, selecteddata } = useSelector((state) => state.upload);

    const handleInputChange = (event) => {
        const inputName = event.target.value;
        setName(inputName);
    };


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);


    const onNext = async () => {
        try {
            const result = await dispatch(
                postData({
                    key:'name',
                    value: { name: name },
                    data: { name: name },
                    docId: data && data.docId !== undefined ? data.docId : "",
                })
            );
            if (result.success) {
                navigate("/step-date-of-birth");
            } else {
                console.error('Post data failed.');
            }
        } catch (error) {
            console.error('Error during postData:', error);
        }
    };


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    useEffect(() => {
        if (selecteddata && selecteddata.name) {
            setName(selecteddata.name.name || '');
        }
    }, [selecteddata.name]);


    return (
        <div>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "transparent" }}>
                <Header />
                <div style={{ visibility: 'hidden' }} >
                    <ProgressBarComponent />
                </div>
            </div>
            <div className="klBxhW" style={{ paddingTop: '120px', backgroundColor: "black", paddingBottom: '50px' }}>
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            <div className="cqjfzM jCSgRm">
                                <span className="sc-66945658-1 ijvsZU">✅</span><p className="sc-d6bbf5f0-4 sc-66945658-3 cDpolK">Your workout and meal plan are almost ready!</p>
                            </div>
                            <h1 className="bpzQjr bsCFUQ">Let's create your account</h1>
                            <div className="csXCYg glYDxw">
                                <div className="kkiseL">
                                    <span className="eABpMJ">
                                        <SmallProgressBarComponent index={1} total={3} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sc-eb5bc1e6-2 gppALw step-component-wrapper step-component-wrapper_1">
                        <label className="sc-ce1ca726-0 ieunKf">
                            <span className="sc-ce1ca726-1 efFBMo">What should we call you?</span>
                            <span className="sc-ce1ca726-2 dlDjfT">
                                <input
                                    type="text"
                                    name="name"
                                    pattern=""
                                    enterKeyHint="next"
                                    className="fPLSVc"
                                    placeholder='Name'
                                    value={name}
                                    autoComplete="off"
                                    onChange={handleInputChange} />
                            </span>
                        </label>

                    </div>
                    <div className="container-arrow">
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'end', pointerEvents: name === '' ? "none" : "auto", opacity: name === "" ? 0.4 : 1, marginTop: "20px" }}>
                            {screenWidth >= 1023 && (<RightArrowButton label={"Continue"} onClick={onNext} width="200px" />)}
                        </div>
                    </div>
                </div>
                {screenWidth < 1023 &&
                    <div style={{ pointerEvents: name === '' ? "none" : "auto", opacity: name === "" ? 0.4 : 1 }}>
                        <BottomArrowButton onClick={onNext} label="Continue" />
                    </div>}
            </div>
        </div>
    );

}


export default StepName;