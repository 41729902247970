import React from 'react';

const RightArrowButton = ({ width, onClick, label }) => {
  return (
    <div
      onClick={onClick}
      style={{
        maxWidth: width,
        width: "100%",
        display: "inline-flex",
        cursor: 'pointer',
        outline: "none",
        border: "none",
        padding: "16px",
        WebkitBoxAlign: "center",
        alignItems: "center",
        backgroundColor: "#12B2B3",
      }}
    >
      <div style={{ fontSize: "18px", fontWeight: '700', color: 'white' }}>
        {label}
      </div>
      <span
        style={{
          marginLeft: '60px',
          width: "30px",
          height: "20px",
          color: 'white'
        }}>
        &rarr;
      </span>
    </div>
  );
};

export default RightArrowButton;
