
import React from "react";
import '../../assets/styles/FinalResult.css';


const ProductPlanCardItem = ({ plan, index, onPlanSelected }) => {



    const onClicked = () => {
        onPlanSelected(index, plan)
    }

    return (
        <div className="kfkPfX" onClick={onClicked}>
            <label id="selector-wrapper" className={plan.checked ? "kHAZvO cwyBUq" : "kWqhrX cwyBUq"} tabIndex={index}>
                <input name="pricingPlan" type="radio" value="322" defaultChecked={plan.checked} />
                <span className="dNuCWe">
                    <span className="fyblzY">{plan.product}</span>
                    <span style={{ display: "block" }}>
                        <span className="lhlHag bTYyBy">{plan.totalprice}<span>{plan.currency}</span>
                        </span>
                    </span>
                </span>
                <span className="hrtFdt" style={{ marginLeft: "auto" }}>
                    <p className="fKEBTI fikCKZ">{plan.perday} <span>{plan.currency}</span></p>
                    <span className="lbtHQX hIowsA">per day</span>
                </span>
            </label>
            {plan.popular && <span className="ebnUab">Most popular</span>}
        </div>

    );

}

export default ProductPlanCardItem;