import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './app/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { PostHogProvider} from 'posthog-js/react'
import { BrowserRouter as Router } from 'react-router-dom';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}>
     <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
        <App />
        </Router>
      </PersistGate>
    </Provider>
    </PostHogProvider>
  </React.StrictMode>
);

reportWebVitals();
