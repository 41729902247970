
import { createSlice } from '@reduxjs/toolkit';
import NetworkService from '../services/network/networkservice';
const baseURL = 'https://us-central1-gymbuddy-af65a.cloudfunctions.net'; // Your base URL


const networkservice = new NetworkService(baseURL);

const getPlanSlice = createSlice({
    name: 'plans',
    initialState: {
        plans: [],
        loading: false,
        error: null,
    },
    reducers: {
        fetchPlansStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchPlansSuccess(state, action) {
            state.loading = false;
            state.plans = action.payload;
            state.error = null;
        },
        fetchPlansFailure(state, action) {
            state.loading = false;
            state.error = action.payload.erorr;
        },
    },
});

export const { fetchPlansStart, fetchPlansSuccess, fetchPlansFailure } = getPlanSlice.actions;

// Thunk action for making the API call
export const getPlans = () => async (dispatch) => {
    try {
        dispatch(fetchPlansStart());
        const result = await  networkservice.getProducts();
        const plans = [];
        result.data.map((item, index) => {
            plans.push({
                "id": item['id'],
                "product": item['name'],
                "totalprice": (item.price / 100).toFixed(2),
                "perday": (item.price / (7 * 100)).toFixed(2),
                "currency": "£",
                "checked": item['name'] === "4-WEEK PLAN"?true:false,
                "popular": item['name'] === "4-WEEK PLAN"?true:false,
            });
        });
        plans.sort((a, b) => parseFloat(a.totalprice) - parseFloat(b.totalprice));
        dispatch(fetchPlansSuccess(plans));
    } catch (error) {
        dispatch(fetchPlansFailure(error.message));
    }
};


export default getPlanSlice.reducer;
