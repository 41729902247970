import React, { useEffect, useState } from 'react';
import "../../assets/styles/FinalResult.css";
import logo from '../../assets/images/logo.png';
import star from '../../assets/images/star.png';

 
const ReviewCardItem = ({ review }) => {
    const [imageSrc, setImageSrc] = useState('');


    useEffect(() => {
        if(review.userimage !== ''){
            import(`../../assets/images/reviewer/${review.userimage}`).then(icon => {
                setImageSrc(icon.default);
              });
        }
      }, [review.userimage]);

    return (
        <div>
            <article role="tabpanel" className="fRcffV active" style={{ boxSizing: "borderBox", margin: "0px" }}>
                <div className="gkjvVm">
                    <div className="kJeQBI">
                        <div className="ctmhGW">
                            {review.userimage && <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                                <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                    <img alt={review.userimage}  src={imageSrc} aria-hidden="true" aria-placeholder={logo} style={{ display: "block", maxWidth: "100%",  background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", width: '24px', height: '24px',   borderRadius:"150px" }} />
                                </span>
                            </span>}
                            <p className="sgECtKm erYcnL">{review.name}</p>
                        </div>
                        <div className="cDJtBv">
                            <img src={star} alt='star1' className="hIzoLx" style={{ width: '16px', height: '16px', marginRight: '2px' }} />
                            <img src={star} alt='star2' className="hIzoLx" style={{ width: '16px', height: '16px', marginRight: '2px' }} />
                            <img src={star} alt='star3' className="hIzoLx" style={{ width: '16px', height: '16px', marginRight: '2px' }} />
                            <img src={star} alt='star4' className="hIzoLx" style={{ width: '16px', height: '16px', marginRight: '2px' }} />
                            <img src={star} alt='star5' className="hIzoLx" style={{ width: '16px', height: '16px', marginRight: '2px' }} />
                        </div>
                    </div>
                    <div className="isjlys">
                        <p className="dezAzo eJpQV">{review.review}</p>
                    </div>
                </div>
            </article>
        </div>
    )

}

export default ReviewCardItem;