import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import RightArrowButton from '../../layout/button/RightArrowButton';
import BottomArrowButton from '../../layout/button/BottomArrowButton';
import '../../assets/styles/StepName.css';
import SmallProgressBarComponent from '../../components/SmallPrograssBarComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBarComponent from '../../layout/ProgressBarLayout';
import ProceedToMyPlanDialog from '../../components/dialog/ProceedToMyPlanDialog';
import { postData, uploadResult } from '../../reducers/uploadDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const StepEmail = () => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#12b2b3' : '#12b2b3',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#808080' : '#808080',
            boxSizing: 'border-box',
        },
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { data, selecteddata } = useSelector((state) => state.upload);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    
    const [email, setEmail] = useState('');
    const [isOpted, setOptOut] = useState(false);
    const [error, setError] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (selecteddata && selecteddata.email) {
            setEmail(selecteddata.email.email || '');
            setOptOut(selecteddata.email.isOptedForMarketing || false);
        }
    }, [selecteddata.email]);

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        navigate("/step-commitment");
    };

    const handleInputChange = (event) => {
        const inputName = event.target.value;
        setError('');
        setEmail(inputName);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onSwitchToggled = (event) => {
        setOptOut(event.target.checked);
    };

    const onNext = async () => {
        const isValidEmail = validateEmail(email);
        if (isValidEmail) {
            try {
                const result = await dispatch(
                    postData({
                        key: "email",
                        value: {
                            email: email,
                            isOptedForMarketing: isOpted
                        },
                        data: {
                            email: email,
                            isOptedForMarketing: isOpted
                        },
                        docId: data && data.docId !== undefined ? data.docId : "",
                    })
                );
                if (result.success) {
                    openDialog();
                } else {
                    console.error('Post data failed.');
                }
            } catch (error) {
                console.error('Error during postData:', error);
            }
        } else {
            setError('Please use a valid email address');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    return (
        <div>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "transparent" }}>
                <Header />
                <ProgressBarComponent showBar={false} />
            </div>
            <div className="klBxhW" style={{ paddingTop: '120px', backgroundColor: "black", paddingBottom: '50px' }}>
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            <div className="cqjfzM jCSgRm">
                                <span className="ijvsZU">✅</span>
                                <p className="cDpolK">Your workout and meal plan are almost ready!</p>
                            </div>
                            <h1 className="bpzQjr bsCFUQ">Let's create your account</h1>
                            <div className="csXCYg glYDxw">
                                <div className="kkiseL">
                                    <span className="eABpMJ">
                                        <SmallProgressBarComponent index={3} total={3} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gppALw step-component-wrapper step-component-wrapper_1">
                        <label className="ieunKf">
                            <span className="efFBMo">Email</span>
                            <span className="dlDjfT">
                                <input
                                    type="email"
                                    name="email"
                                    className="fPLSVc"
                                    autoComplete="off"
                                    placeholder='name@example.com'
                                    value={email}
                                    onChange={handleInputChange}
                                />
                            </span>
                        </label>
                        {error && <p style={{ color: 'red', alignSelf: 'start', fontSize: '12px' }}>{error}</p>}
                        <div className="cJQjQb">
                            <div className="cDpolK" style={{ textAlign: 'start', fontSize: '14px' }}>Am I currently opted in to receive marketing emails?</div>
                            <div className='ouItrP'>
                                <AntSwitch checked={isOpted} onChange={onSwitchToggled} inputProps={{ 'aria-label': 'ant design' }} />
                            </div>
                        </div>
                        <span className="efFBMo" style={{ textAlign: 'start', marginTop: '30px' }}>We respect your privacy and take protecting it very seriously — no spam</span>
                    </div>
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', pointerEvents: email === '' ? "none" : "auto", opacity: email === "" ? 0.4 : 1, marginTop: "20px" }}>
                        <div className="container-arrow">
                            {screenWidth >= 1023 && (<RightArrowButton label={"Continue"} onClick={onNext} width="200px" />)}
                        </div>
                    </div>
                </div>
                {screenWidth < 1023 &&
                    <div style={{ pointerEvents: email === '' ? "none" : "auto", opacity: email === "" ? 0.4 : 1 }}>
                        <BottomArrowButton onClick={onNext} label="Continue" />
                    </div>}
            </div>
            {isDialogOpen && <ProceedToMyPlanDialog isOpen={isDialogOpen} onClose={closeDialog} />}
        </div>
    );
}

export default StepEmail;
