import { collection, getDocs, orderBy, query, addDoc, setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';



export async function getStepsData() {
  const collectionRef = collection(db, 'step_questionarie');
  const sortedAndFilteredQuery = query(
    collectionRef,
    orderBy('order') // Replace 'yourFieldName' with the actual field name
  );

  try {
    const querySnapshot = await getDocs(sortedAndFilteredQuery);
    const documentsData = querySnapshot.docs.map((doc) => (doc.data()));
    return {
      steps: documentsData
    };
  } catch (error) {
    return {
      steps: []
    };
  }
}

export async function postData(data) {

  if (data.docId === "") {
    try {
      const collectionRef = collection(db, 'healthhaven_steps_data');
      const docRef = await addDoc(collectionRef, data.data);
      return {
        success: true,
        docId: docRef.id,
      };
    } catch (error) {
      console.error('Error adding document: ', error);
      return {
        success: false,
        error: error.message,
      };
    }
  } else {
    try {
      const docRef = doc(db, 'healthhaven_steps_data', data.docId);
      await setDoc(docRef, data.data, { merge: true });

      return {
        success: true,
        docId: data.docId,
      };
    } catch (error) {
      console.error('Error updating document: ', error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
}


export async function getResults(docId) {
  const documentRef = doc(db, 'healthhaven_steps_data', docId);

  try {
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      // Document exists, return its data
      return {
        success: true,
        results: documentSnapshot.data()
      };
    } else {
      // Document does not exist
      return {
        success: false,
        message: "No results found"
      };
    }
  } catch (error) {
    return {
      results: {
        success: false,
        message: error.message
      },
    };
  }
}


export async function updateCheckoutsData(data) {
  const documentRef = doc(db, 'payment', data);

  try {
    const documentSnapshot = await getDoc(documentRef);

    if (documentSnapshot.exists()) {
      // Document exists, return its data
      return {
        success: true,
        results: documentSnapshot.data()
      };
    } else {
      // Document does not exist
      return {
        success: false,
        message: "No results found"
      };
    }
  } catch (error) {
    return {
      results: {
        success: false,
        message: error.message
      },
    };
  }
}