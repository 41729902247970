import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/MultiSelectCardItemCheckbox.css'

const MultiSelectCardItemCheckboxWithImage = ({ index, option, onItemSelected, checked = false }) => {



    const onChange = (value) => {
    };


    const handleClick = (event) => {
        event.preventDefault();
        onItemSelected(index, !checked, option);
    };

    return (
        <label className={`hjicdk12 hhbXPR dwlkSV ${checked ? 'isChecked' : ''}`}
            tabIndex={index.toString()}
            onClick={handleClick}>
            <input name="goal" className="jQNPoG" />
            <div style={{ textAlign: 'start' }}>
                <span className="jxGXvKN">
                    <span className="checkbox-title">
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={onChange}
                        />
                        <span className="ghbokT hXbiJA" style={{ marginLeft: '5px' }}>{option.title}</span>
                    </span>
                </span>
                {(!option.subtitle && option.subtitle !== undefined && !(option.subtitle.trim() === '')) ? null : (
                    <p className="dezAzo eGxKKN">{option.subtitle}</p>
                )}
            </div>
            <span className="kSpLAz">
                <img alt={option.title} src={option.imageUrl} className="item-image2" />
            </span>
        </label>
    );
};


MultiSelectCardItemCheckboxWithImage.propTypes = {
    index: PropTypes.number.isRequired, // Adjust the PropTypes accordingly
    option: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        imageUrl: PropTypes.string,
    }),
    onItemSelected: PropTypes.func.isRequired,
};

export default MultiSelectCardItemCheckboxWithImage;
