import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import { useNavigate, useLocation } from 'react-router-dom';
import RightArrowButton from '../../layout/button/RightArrowButton';
import '../../assets/styles/FormRangeWithCondition.css';
import rangeImage from '../../assets/images/FROM_5_TO_9.webp'
import BottomArrowButton from "../../layout/button/BottomArrowButton";
import tick from '../../assets/images/tick.png'

const StepNutrition = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const currentStepIndex = 4;
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    const onClicked = () => {
        navigate('/step-diets', { state: { currentStepIndex: currentStepIndex + 1 } });
    }


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);



    return (
        <div style={{ height: '100%' }}>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "transparent" }}>
                <Header />
            </div>
            <div className="fQWqWj">
                <div className="jlxXQZ grid-bg">
                    <div className="uxnsv">
                        <span className="ePONvi visible">
                            <span>
                                <span >
                                    <img alt="" aria-hidden="true" src={rangeImage} style={{ display: "block", maxWidth: "100%", width: "190px", height: "475px", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", alignSelf: 'center' }} />
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="fOdZdy">
                    <div className="ltAyV">
                        <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                            <div className="osnNu">
                                <h1 className="bpzQjr bsCFUQ"><span style={{ color: '#12B2B3', fontSize: "32px" }}>81%</span> of your results are about nutrition</h1>
                            </div>
                        </div>
                        <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                            <div className="irmowo">
                                <div className="bUfyfo">
                                    <div style={{ width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: "start", color: 'white', textAlign: 'start' }}>
                                        <p>
                                            To make the most gains in muscle mass and strength, you need:
                                        </p>

                                        <span style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                            <img src={tick} alt='tick_1' style={{ marginRight: "10px" }} />
                                            <p>
                                                Enough total calories each day.
                                            </p>
                                        </span>
                                        <span style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                            <img src={tick} alt='tick_1' style={{ marginRight: "10px" }} />
                                            <p>
                                                Adequate protein to rebuild the tissue you break down from lifting (hypertrophy).
                                            </p>
                                        </span>
                                        {screenWidth > 1023 && <div style={{ alignSelf: "end", marginTop: "20px" }}>
                                            <RightArrowButton label={"Got it"} onClick={onClicked} width="200px" />
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                        {screenWidth < 1023 && <BottomArrowButton onClick={onClicked} label="Got it" />}

                    </div>
                </div>
            </div>
        </div>
    )

}

export default StepNutrition;