import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import NetworkService from '../services/network/networkservice';

const networkservice = new NetworkService();

export const fetchItems = createAsyncThunk('data/fetchItems', async () => {
    const response = await networkservice.getData();
    return response.steps; // Adjust the path as necessary based on your API response
});

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        items: [],
        loading: false,
        error: null,
        selectedBodyGoal: null,

    },
    reducers: {
        setUpdatedBodyGoal(state, action) {
            state.selectedBodyGoal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setUpdatedBodyGoal } = dataSlice.actions;

export const selectedBodyGoal = (goal) => (dispatch) => {
    dispatch(setUpdatedBodyGoal(goal));
};

export default dataSlice.reducer;
