import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStepIndex: 0,
};


export const indexSlice = createSlice({
  name: 'index',
  initialState,
  reducers: {
    onPrevSteps: (state) => {
      return { ...state, currentStepIndex: state.currentStepIndex - 1 };
    },
    onNextSteps: (state) => {
      return { ...state, currentStepIndex: state.currentStepIndex + 1 };
    },
  },
});

export const { onPrevSteps, onNextSteps } = indexSlice.actions;

export default indexSlice.reducer;
