
import React from "react";
import ProgressBar from "../components/ProgressBar";
import '../assets/styles/ProgressBarLayout.css';

const SmallProgressBarComponent = ({ index, total }) => {

  return (
    <div>
      <div className="sticky-container">
        <div className="csXCYg xhIjl" style={{ marginBottom: '0px' }}>
          <div className="kkiseL1">
            <ProgressBar
              progressStyle={{
                top: "10%",
                left: "10%",
                right: "10%",
                height: '4px',
                width: '93%',
                background: "#393939"
              }}
              fillerStyle={{
                width: `${((index / total) * 100) - 12}%`,
                background: '#12B2B3',
                height: "4px",
              }}
            />
            <div>
              <p className="gECtKm cvkoXM">{(index)}/{total}</p></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallProgressBarComponent;