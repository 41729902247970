import React from "react";
import '../assets/styles/DetermineBodyType.css'
import deterBodyTypeImage from '../assets/images/determine-body-type.svg';

const DetermineBodyType = () => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: "40px" }}>
            <div className="cYbNRy">
                <div className="dLgqLh">
                    <h3 className="dcMtwF hmGzV">Test to determine your body type</h3>
                    <div className="cfwOSv">
                        <span style={{ boxSizing: "border-box", display: "inline-block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                            <span style={{ boxSizing: "border-box", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                <img alt="" src={deterBodyTypeImage} style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                            </span>
                        </span>
                    </div>
                </div>
                <p className="dezAzo kUUxCL">Grip your opposite wrist using your thumb and index finger. If you're right-handed, use your right hand to grab your left wrist.</p>
                <div className="fXZhxO">
                    <p className="dezAzo eCjhXJ">
                        <strong>Thumb and index finger:</strong>
                    </p>
                    <p className="dezAzo">
                        <b>Ectomorph</b> — wrapping around with ease.<br /><b>Mesomorph</b> — touching each other.<br /><b>Endomorph</b> — doesn’t come into contact.
                    </p>
                </div>
            </div>
        </div>
    );

}

export default DetermineBodyType;