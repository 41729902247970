import React from "react";
import '../../assets/styles/Reviews.css'
import Header from "../../layout/header/Header";
import ReviewSection from "../../components/Review";
import { useNavigate } from 'react-router-dom';
import rightArrow from '../../assets/images/right-arrow.png'

const Reviews = () => {
    const navigate = useNavigate();

    const onClick = (event) => {
        event.preventDefault();
        navigate("/step-goal");
    };

    return (
        <div>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "black", zIndex: 3 }}>
                <Header />
            </div>
            <div className="gINUjI">
                <div className="iKRgKP">
                    <button type="button" title="" className="jHYaJm">
                        <p className="sc-248d0d06-0 fbjKBd">Home</p>
                    </button>
                </div>
                <section className="byJmIv fWIJiO">
                    <div className="jWvJVz">
                        <h1 className="hcLSri dNPhAg">HealthHaven Reviews</h1>
                        <p className="kPzTO hULOsv">Read HealthHaven app reviews from customers who've already reached their muscle-building goals!</p>
                        <div className="hDyKSx">
                            <div className="gDLWVz1" onClick={onClick}>
                                <span className="eOPdCo">
                                    <p className="kbMuAn">Let' go</p>
                                    <img src={rightArrow} alt="right-arrow" style={{ width: "20px", height: "20px", marginLeft: "30px" }} />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <ReviewSection/>
                <div className="nkAzd">
                    <div className="gWYEah">
                        <h2 className="OXTho">Join now</h2>
                        <div className="hDyKSx">
                            <div className="gDLWVz" onClick={onClick}>
                                <span className="eOPdCo">
                                    <p className="kbMuAn">Choose a Program</p>
                                    <img src={rightArrow} alt="right-arrow" style={{ width: "20px", height: "20px", marginLeft: "30px" }} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="fEisXp">
                    <ul className="immPOy">
                        <li className="cvVVtt"><a title="Terms of service" className="sc-22cde6cd-6 bHDknR" href="/terms-and-conditions">Terms and Condition</a></li>
                        <div style={{width:'30px'}}></div>
                        <li className="cvVVtt"><a href="/support" title="Support" rel="noopener noreferrer" target="_blank" className="sc-22cde6cd-6 bHDknR">Support</a></li>
                    </ul>
                </footer>
            </div>
        </div>
    );
}

export default Reviews;