// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


//production
const firebaseConfig = {
  apiKey: "AIzaSyC2opWgYXd18MshcggX1wVJGOaZQWlm9F8",
  authDomain: "gymbuddy-af65a.firebaseapp.com",
  databaseURL: "https://gymbuddy-af65a.firebaseio.com",
  projectId: "gymbuddy-af65a",
  storageBucket: "gymbuddy-af65a.appspot.com",
  messagingSenderId: "890138898590",
  appId: "1:890138898590:web:0f641401f99cc5e7c80b8f",
  measurementId: "G-4B1MB51N4H"
};


//development
// const firebaseConfig = {
//   apiKey: "AIzaSyBgZpP8P8CsoEl-m9Zccm7k194wsOVZWWA",
//   authDomain: "healthhavendev.firebaseapp.com",
//   databaseURL: "https://healthhavendev-default-rtdb.firebaseio.com",
//   projectId: "healthhavendev",
//   storageBucket: "healthhavendev.appspot.com",
//   messagingSenderId: "329408442197",
//   appId: "1:329408442197:web:4721dbbc1a7e2d66b009dc",
//   measurementId: "G-18WR3QS2B1"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();



export { db, analytics }; 