import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { getClientSecret } from '../../reducers/getResultSlice';
import '../../assets/styles/CheckoutForm.css'; // Import your CSS file
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

import logo from '../../assets/images/logo.png';
import { resetSelectedValue } from "../../reducers/uploadDataSlice";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const CheckoutForm = ({ selectedPlan }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const results = useSelector((state) => state.results.results);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        elements.submit();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        try {
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                elements
            });

            if (error) {
                throw new Error(error.message);
            }

            const data = await getClientSecret({
                amount: Math.round(selectedPlan.totalprice * 100),
                currency: "gbp",
                payment_method: paymentMethod.id,
                off_session: false,
                confirm: true,
                confirmation_method: 'manual',
                receipt_email: results.email,
            });

            const { confirmError } = await stripe.confirmPayment({
                elements: elements,
                clientSecret: data.clientSecret,
                confirmParams: {
                    return_url: 'https://start.healthhavenapp.com/complete',
                },
            });

            if (confirmError) {
                throw new Error(confirmError.message);
            } else {
                setIsLoading(false);
                dispatch(resetSelectedValue())
                navigate('/complete',{replace:true});
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            setMessage(error.message || 'An unexpected error occurred.');
            setIsLoading(false);
        }
    }

    return (
        <div className="checkout-form">
            <div className='checkout-form-header'>
            <img alt="Health Haven" src={logo} decoding="async" data-nimg="intrinsic" style={{ display: 'block', width: '60px', height: '60px', marginRight:'10px'}} />
            <div className="checkout-form-product">
                <h4>{selectedPlan.product}</h4>
                <p style={{fontSize:'14px',fontWeight:'400', top:0}}>Total Price: <span>{selectedPlan.currency}</span><span style={{fontWeight:700}}>{selectedPlan.totalprice}</span></p>
            </div>
            </div>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" />
                <button
                    disabled={isLoading || !stripe || !elements}
                    className="pay-now-button">

                    {isLoading ? <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: (theme) => (theme.palette.mode === 'light' ? '#ffffff' : '#ffffff'),
                            animationDuration: '550ms',
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={30}
                        thickness={2} /> : "Pay now"}
                </button>
                {message && <div className="payment-message"><span style={{ fontSize: '14px' }}>⚠</span>{message}</div>}
            </form>
        </div>
    );
}

export default CheckoutForm;
