import React, { useState } from 'react';
import '../../assets/styles/WhyChooseUs.css';
import balance from '../../assets/images/balance.png';
import saveMoney from '../../assets/images/save-money.png';
import discussion from '../../assets/images/discussion.png';
import favrioute from '../../assets/images/favorites.png';



const faqs = [
    { question: "Pay only what you're comfortable with", answer: "With multiple pricing plans available, we leave the choice up to you as to how much you want to pay for Premium. We shortlist the trainers who match your budget, and will always keep it this way.", icon: saveMoney },
    { question: "Compare trainers side-by-side", answer: "Take your pick. Get full visibility into your future trainer's experience, niche, services, testimonials, location (the list goes on).", icon:balance },
    { question: "Community", answer: "Be part of the latest fast-growing global fitness community, where a diverse group of fitness experts, newcomers, gym-goers and athletes alike come together to share, motivate and improve.", icon:discussion},
    { question: "Hire with confidence", answer: "Fully refundable secured bookings, without the overwhelming paperwork. Integrated session feedback and reviews in-app give you a streamlined outlet to let us know of any issues or concerns you have, and for us to help out where needed.",icon:favrioute}
];

function WhyChooseUsItem({ faq }) {
    return (
        <div className="chooseus-item">
            <div>
                <img src={faq.icon} alt={faq.icon} className="faq-image"  style={{color:'white'}}/>
                <div>
                    <h4 className="chooseus-question">
                        {faq.question}
                    </h4>
                    <p className="chooseus-answer">{faq.answer}</p>
                </div>
            </div>
        </div>
    );
}

const WhyChooseSection = () => {
    return (
        <div style={{ width: '100%', justifyContent: 'center', textAlign: 'center', display: 'flex', background: 'black', margin: '0px auto' }}>
            <div className="chooseus-container">
                <h3 className="chooseus-title">Why choose us?</h3>
                <p className="chooseus-intro">The only all-in-one platform that democratises access to premium digital personal training, combined with a dedicated team of real people working day and night to ensure your better health.</p>
                <div className="chooseus-grid">
                    {faqs.map(faq => <WhyChooseUsItem key={faq.question} faq={faq} />)}
                </div>
            </div>
        </div>

    );
}

export default WhyChooseSection;