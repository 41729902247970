import React from 'react';
import '../../assets/styles/CustomDialog.css';

const CustomDialog = ({ onClose, children }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="dialog-overlay">
      <div className="dialog">
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <div className="dialog-content">{children}</div>
      </div>
    </div>
  );
};

export default CustomDialog;