import React, { useEffect } from "react";
import '../assets/styles/MultipleItemSelectionContainer.css'
import SquareRadioBox from "../components/SquareRadiobox";
import PropTypes from 'prop-types';


const MultipleItemSelectionCointainer = ({ product, onSelected, allowHealthHavenDecide }) => {

    var linkedMap = new Map();

    const onItemSelected = (option) => {
        if (linkedMap.has(option.title)) {
            linkedMap.delete(option.title);
        } else {
            linkedMap.set(option.title, option);
        }
        onSelected(product.title, Array.from(linkedMap.values()))

    }

    useEffect(() => {
        return () => {
            linkedMap.clear();
        };
    }, []);
    
    return (
        <div className=" gppALw " style={{ marginBottom: '10px' }}>
            <div className="eNLagR">
                <div className="btlCS"><span className="izRESW">{product.title}</span></div>
                <div className="VxtJX">
                    {product.options.map((o) => (
                        <SquareRadioBox key={o.id} option={o} onSelected={onItemSelected} allowHealthHavenDecide={allowHealthHavenDecide} />
                    ))}
                </div>
            </div>
        </div>
    );

}

MultipleItemSelectionCointainer.propTypes = {
    onSelected: PropTypes.func.isRequired,
    option: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })
        )
    })
};


export default MultipleItemSelectionCointainer;