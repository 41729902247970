import React, { useState, useEffect } from "react";
import '../../assets/styles/FormSquareRadioBoxWithImage.css';
import PropTypes from 'prop-types';
import MultiSelectCardItem from "../carditems/MultiSelectCardItemWithImage";
import RightArrowButton from "../button/RightArrowButton";
import BottomArrowButton from "../button/BottomArrowButton";

const linkedMap = new Map();

const FormSquareImageCheckboxWithImages = ({ step, onSelected }) => {


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [options, setOptions] = useState(step.options);


    const onItemChecked = (index, checked, option) => {

        const updatedOptions = options.map((option, currentIndex) => {
            if (currentIndex === index) {
                return { ...option, checked: checked };
            }
            return option;
        });
        setOptions(updatedOptions);
        if (linkedMap.has(option.title)) {
            linkedMap.delete(option.title);
        } else {
            linkedMap.set(option.title, option);
        }

    }

    const onClicked = () => {

        if (linkedMap.size > 0) {
            onSelected(Array.from(linkedMap.values()));
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const checkedIfOptionisChecked = () => {
            if (step?.options && Array.isArray(step?.options)&& step) {
                // Example operation: logging each option
                step.options.forEach(option => {
                    if(option.checked){
                        if (!linkedMap.has(option.title)) {
                            linkedMap.set(option.title, option);
                        } 
                    }
                });
            }
        };
        checkedIfOptionisChecked();
    }, []);


    return (
        <div style={{ justifyContent: 'center' }}>
            <div className="iopLoU">
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            {!step || (!step.subtitle && !(step.subtitle.trim() === '')) ? null : (
                                <p className="dezAzo eGxKKN">{step.subtitle}</p>
                            )}
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>
                    </div>
                    <div className="gppALw">
                        <div className="liJdVi">
                            {options.map((option, index) => (
                                <MultiSelectCardItem
                                    key={option.id}
                                    index={index}
                                    option={option}
                                    onItemSelected={onItemChecked}
                                    checked={option.checked}
                                />
                            ))}
                        </div>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' ,pointerEvents: (linkedMap.size>0) ? "auto" : "none", opacity: (linkedMap.size>0)  ? 1 : 0.4, marginTop: '20px' }}>
                            <div className="container-arrow">
                                {screenWidth >= 1023 && (<RightArrowButton label={"Continue"} onClick={onClicked} width="200px" />)}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ pointerEvents: (linkedMap.size>0)  ? "auto" : "none", opacity: (linkedMap.size>0)  ? 1 : 0.4 }}>
            {screenWidth < 1023 && <BottomArrowButton onClick={onClicked} label="Continue"/>}
            </div>

        </div>
    );
}

FormSquareImageCheckboxWithImages.propTypes = {
    onSelected: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                imageUrl: PropTypes.string,
                checked: PropTypes.bool
            })
        ).isRequired,
    }).isRequired,
};

export default FormSquareImageCheckboxWithImages;