import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import ProgressBarComponent from "../../layout/ProgressBarLayout";
import DynamicFormBuilder from "../../layout/formbuilder/DynamicFormBuider";
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../reducers/progressSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { postData } from '../../reducers/uploadDataSlice';
import { fetchItems } from '../../reducers/getStepsSlice';
import CircularProgressBar from '../../components/CircularProgress';

const StepLevelOfFat = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const currentStepIndex = 3;
    const { items, loading, error } = useSelector((state) => state.data);
    const { data, selecteddata } = useSelector((state) => state.upload);
    const [index, setIndex] = useState(3);

    const onNext = async (value) => {
        try {
            const result = await dispatch(postData({
                key: "body_fat",
                value: value,
                data: { body_fat: value },
                docId: data && data.docId !== undefined ? data.docId : "",
            }));
            
            if (result.success) {
                navigate('/step-problem-areas', { state: { currentStepIndex: currentStepIndex + 1 } });
            } else {
                console.error('Post data failed.');
            }
        } catch (error) {
            console.error('Error during postData:', error);
        }
    };

    useEffect(() => {
        dispatch(fetchItems());
    }, [dispatch]);

    useEffect(() => {
        if (selecteddata && selecteddata.body_fat) {
            setIndex(selecteddata.body_fat.index);
        }
    }, [selecteddata.body_fat]);

    useEffect(() => {
        dispatch(updateProgress({
            progress: (((currentStepIndex + 1) / 27) * 100),
            total: 27,
            increment: (currentStepIndex + 1)
        }));
    }, [currentStepIndex, dispatch]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    const currentStepData = { ...items?.[currentStepIndex], subtitle: ``, selectedIndex: index };

    return (
        <div style={{ height: "100%", padding: '0px' }}>
            <div style={{ position: "fixed", width: '100%', backgroundColor: 'transparent' }}>
                <Header />
                <ProgressBarComponent />
            </div>
            <div style={{ height: '100%', color: 'white',  backgroundColor: 'black' }}>
                {loading ? (
                    <CircularProgressBar />
                ) : error ? (
                    <div style={{ backgroundColor: 'black' }}>An error occurred while loading data.</div>
                ) : currentStepData ? (
                    <DynamicFormBuilder step={currentStepData} onNext={onNext} />
                ) : (
                    <div style={{ backgroundColor: 'black' }}>No step data available.</div>
                )}
            </div>
        </div>
    );
}

export default StepLevelOfFat;
