import React from 'react';
import '../assets/styles/CircularProgress.css';
import logo from '../assets/images/logo.png';


const CircularProgressBar = () => {
    return (
        <div className="circular-progress">
            <svg className="circular-progress-svg" viewBox="25 25 50 50">
                <circle className="circular-progress-circle" cx="50" cy="50" r="20" >
                </circle>
            </svg>
            <div className="circular-progress-image-container">
                <img className="circular-progress-image" src={logo} alt="logo" />
            </div>
        </div>
    );
};

export default CircularProgressBar;