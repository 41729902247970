// progressBarSlice.js

import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  progress: 0,
  total: 0,
  increament: 0
};

// Create a slice
const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState,
  reducers: {
    // Action to update the progress
    updateProgress: (state, action) => {
      const { progress, total, increament } = action.payload
      // Ensure the progress is between 0 and 1002
      state.progress = Math.min(Math.max(progress, 0), 100) - 7;
      state.total = total;
      state.increament = increament;
    },
  },
});

// Export actions and reducer
export const { updateProgress } = progressBarSlice.actions;
export default progressBarSlice.reducer;

// Selectors
export const selectProgress = (state) => state.progressBar.progress;
