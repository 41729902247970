import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import ProgressBarComponent from "../../layout/ProgressBarLayout";
import DynamicFormBuilder from "../../layout/formbuilder/DynamicFormBuider";
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../../reducers/progressSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { postData } from '../../reducers/uploadDataSlice';
import { fetchItems, selectedBodyGoal } from '../../reducers/getStepsSlice';
import CircularProgressBar from '../../components/CircularProgress';

const StepBodyGoals = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const currentStepIndex = 0;
    const { items, loading, error } = useSelector((state) => state.data);
    const { data, selecteddata } = useSelector((state) => state.upload);
    const [currentStepData, setCurrentStepData] = useState(items?.[currentStepIndex]);


    useEffect(() => {
        dispatch(fetchItems());
    }, [dispatch]);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    const onNext = async (value) => {
        try {
            dispatch(selectedBodyGoal(value));
            const result = await dispatch(
                postData({
                    key: "body_goal",
                    value: value,
                    data: { body_goal: value },
                    docId: data?.docId || "",
                })
            ); // Unwrap to handle success and error cases

            if (result.success) {
                navigate('/step-target-body', { state: { currentStepIndex: currentStepIndex + 1 } });
            } else {
                console.error('Post data failed.');
            }
        } catch (error) {
            console.error('Error during postData:', error);
        }
    };


    useEffect(() => {
        dispatch(updateProgress({
            progress: ((currentStepIndex + 1) / 27) * 100,
            total: 27,
            increament: currentStepIndex + 1,
        }));
    }, [currentStepIndex, dispatch]);


    useEffect(() => {
        const setSelectedOption = () => {
            if (selecteddata && selecteddata.body_goal && currentStepData) {
                var updatedOptions = currentStepData.options.map(option => ({
                    ...option,
                    checked: selecteddata.body_goal.id === option.id ? true : false,
                }));

                setCurrentStepData(prevData => ({
                    ...prevData,
                    options: updatedOptions,
                }));
            }
        };

        setSelectedOption();
    }, [selecteddata.body_goal]);


    return (
        <div className="grid-bg1">
            <div style={{ position: "fixed", width: '100%', backgroundColor: "black" }}>
                <Header />
                <ProgressBarComponent />
            </div>
            <div style={{ color: 'white',backgroundColor: 'black' }}>
                {loading ? (
                    <CircularProgressBar />
                ) : error ? (
                    <div style={{ backgroundColor: 'black' }}></div>
                ) : currentStepData ? (
                    <div style={{paddingTop: '120px'}}>
                    <DynamicFormBuilder step={currentStepData} onNext={onNext} />
                    </div>                ) : (
                <div style={{ backgroundColor: 'black' }}></div>
                )}
            </div>
        </div>
    );
};

export default StepBodyGoals;
