import React, { useState, useEffect, useRef } from "react";
import Header from "../../layout/header/Header";
import "../../assets/styles/FinalResult.css";
import image15To17 from '../../assets/images/FROM_15_TO_19.webp';
import info from '../../assets/images/info.png';
import badge0 from '../../assets/images/badges/0.svg';
import badge1 from '../../assets/images/badges/1.svg';
import badge2 from '../../assets/images/badges/2.png';
import badge3 from '../../assets/images/badges/3.svg';
import muckup1 from '../../assets/images/muckups/mockup.svg';
import muckup2 from '../../assets/images/muckups/mockup.svg';
import rightArrow from '../../assets/images/right-arrow.png';
import instragram from '../../assets/images/instagram.png'
import { calculateBMI, calculateWaterIntakeInOunces, calculateRecommendedDailyCalorieIntake, getBodyFatRange } from '../../reducers/getResultSlice'
import ProductPlanCardItem from "../../layout/carditems/ProductPlanItem";
import {useSelector } from 'react-redux';
import muscleImage from '../../assets/images/muscle.png';
import "react-multi-carousel/lib/styles.css";
import moneyBack from '../../assets/images/money_back_gurantee.svg';
import CustomDialog from "../../components/dialog/CustomDialog";
import StaticFaqSection from "../../components/Faq";
import {
  Elements
} from '@stripe/react-stripe-js';
import CheckoutForm from "../../layout/forms/CheckoutForm";

import { loadStripe } from '@stripe/stripe-js';
import ReviewSection from "../../components/Review";
import { useNavigate, useLocation } from 'react-router-dom';


const FinalResult = () => { 
  
  const navigate = useNavigate();

  const stripePromise = loadStripe('pk_live_YISYyAciQjoPXfGuQenzxszu00V4ebwiDB');

  const initialOptions = {
    mode: 'payment',
    appearance: {
      theme: 'Minimal',
    },
    amount: 0.0,
    currency: 'gbp',
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
    paymentMethodCreation: 'manual',
  };



  const [imageSrc, setImageSrc] = useState('');

  const results = useSelector((state) => state.results.results);
  const products = useSelector((state) => state.plans.plans)

  const [plans, setPlans] = useState(products);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [options, setOptions] = useState(initialOptions);


  



  const onPlanSelected = (index, plan) => {
    console.log("Selected Plan", plan);
  
    // Update the plans state to mark the selected plan as checked
    const updatedPlans = plans.map((plan, i) => ({
      ...plan,
      checked: i === index,
    }));
    setPlans(updatedPlans);
  
    // Set the selected plan state
    setSelectedPlan(plan);  
    // Update the options object with the selected plan details
    const updatedOptions = {
      ...options,
      // amount: Math.ceil(parseFloat(plan.totalprice) * 100), // Convert price to smallest currency unit and round up
      amount: 7899, // Convert price to smallest currency unit and round up
      currency: "GBP", // Set currency to GBP
      title: plan.product, // Set title to the product name
    };
  
    // Set the options state with the updated options
    setOptions(updatedOptions);
  };

  const onGetMyPlanClick = (event) => {
    const targetElement = document.getElementById('select-plan');

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }

  const handleCloseDialog = (e) => {
    setIsDialogOpen(false);
  }


  const getBMIString = (bmi) => {
    if (bmi < 18.5) {
      return "Underweight";
    } else if (bmi >= 18.5 && bmi < 24.9) {
      return "Normal weight";
    } else if (bmi >= 25 && bmi < 29.9) {
      return "Overweight";
    } else if (bmi >= 30) {
      return "Obese";
    }
  }

  const handleEvent = () => {
    navigate('/complete',{replace:true});
  };

  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  });




  // Set selectedPlan initially if checked value of any product is true
  useEffect(() => {
    const initiallySelectedPlan = plans.find(plan => plan.checked);
    if (initiallySelectedPlan) {
      setSelectedPlan(initiallySelectedPlan);
      options.amount = Math.ceil(parseFloat(initiallySelectedPlan.totalprice)*100);
      options.currency = "gbp";
      options.title = initiallySelectedPlan.product;
    }
  }, [plans]);





  useEffect(() => {
    import(`../../assets/images/${results.body_fat.imagePath}`).then(icon => {
      setImageSrc(icon.default);
    });
  }, [results.body_fat.imagePath]);


  const handleSubmitClick = (e) => {
    e.preventDefault();

  console.log("Selected Plan", selectedPlan);
    setIsDialogOpen(true);
  };


  return (

    <div className="grid-bg1">
      <div style={{ position: "sticky", width: '100%', height: '100%', backgroundColor: "black" }}>
        <div style={{ position: "fixed", width: '100%', backgroundColor: "black", zIndex: 3 }}>
          <Header />
        </div>
        <div className="dPbQIl">
          {/* ---------------------------------- */}
          <section className="hVZVwo">
            <div className="gSdIbF">
              <div className="jrfHvj">
                <div className="eJQloD">
                  <div className="heDlSC">
                    <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                      <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                        <img alt="" aria-hidden="true" src={imageSrc} style={{ display: "block", maxWidth: "100%", width: "140px", height: "350px", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="eJQloD">
                  <div className="heDlSC">
                    <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                      <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                        <img alt="" aria-hidden="true" src={results.target_body.imageUrl} style={{ display: "block", maxWidth: "100%", width: "140px", height: "350px", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="fXVnti">
                <div className="bGoIGZ">
                  <p className="ecFHhf">Now</p>
                </div>
                <div className="bGoIGZ">
                  <p className="ecFHhf">6 months</p>
                </div>
              </div>
              <div className="OJDXK">
                <div className="dMLxmX">
                  <span className="jKdujb">Body fat</span>
                  <span className="hDWZWp">{results.body_fat.range}%</span>
                  <p className="fPSkut kEwaiX">Body muscles</p>
                  <div className="kIVshV"><div className="bdXizj">
                  </div>
                    <div className="cUwwiO">
                    </div>
                    <div className="cUwwiO">
                    </div><div className="cUwwiO">
                    </div><div className="cUwwiO">
                    </div>
                  </div>
                </div>
                <div className="dMLxmX">
                  <span className="jKdujb">Body fat</span>
                  <span className="hDWZWp">{getBodyFatRange(results)}</span>
                  <p className="fPSkut kEwaiX">Body muscles</p>
                  <div className="kIVshV">
                    <div className="bdXizj">
                    </div>x
                    <div className="bdXizj">
                    </div>
                    <div className="bdXizj">
                    </div>
                    <div className="bdXizj">
                    </div>
                    <div className="bdXizj">
                    </div>
                  </div>
                </div>
              </div>
              <p className="gECtKm jaTiyn">*The image is not intended to represent the user. Results vary per person and are not guaranteed.</p>
            </div>
            <h1 className="lfFgMS1 jMwAxD" >Personal summary based on your answers</h1>
          </section>

          {/* ---------------------------- */}
          <div className="khojTM">
            <div className="fJgeSu">
              <div className="cMXlHu">
                <img src={info} alt="info-icon" className="hAXDHU" />
              </div>
              <p className="dezAzo guONjc">Current BMI</p>
              <p className=" dezAzo eMkHFL">
                <span className="rtJBA evNftI">
                  <span>{calculateBMI(results.weight, results.height.height).toFixed(2)}</span> BMI</span></p>
              <div className="ktbeEO">
                <div className="cdcujb">
                </div>
                <div className="fgwdrk">
                  <span className="kNHdTk">Underweight</span>
                  <span className="kNHdTk">Obese</span>
                </div>
              </div>
              <div className="eaRkvO">
                <p className="cxWgCs">{getBMIString(calculateBMI(results.weight, results.height.height).toFixed(2))}</p>
                <p className="ebehgr">The body mass index (BMI) is a measure that uses your height and weight to work out if your weight is healthy.</p>
              </div>
            </div>
            <div className="cooBle">
              <span className="cukhrM">recommended</span>
              <div className="hXfmOV">
                <div className="lFpGz">🍔</div>
                <div className="bWebSz">
                  <p className="dezAzo elndyf">Daily calorie intake</p>
                  <div className="eKxreK">
                    <span>{calculateRecommendedDailyCalorieIntake(results).toFixed(2)}</span> cal</div>
                </div>
              </div>
              <div className="bmGVMM">
                <div className="chnYuq">
                </div>
                <div className="eJDjjQ" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span className="cBkZfO">1000 kcal</span>
                  <span className="cBkZfO">5000 kcal</span>
                </div>
              </div>
            </div>
            <div className="bzNGGh">
              <span className="cukhrM">recommended</span>
              <div className="hXfmOV">
                <div className="lFpGz">💧</div>
                <div className="bWebSz">
                  <p className="dezAzo gEYMIQ">Daily water intake</p>
                  <div className="fxCnbD">{calculateWaterIntakeInOunces(results.weight)}</div>
                </div>
              </div>
              <div className="hSzqML">
                <div className="fjveaj">
                  <div className="gYIXoe"></div>
                  <div className="gYIXoe"></div>
                  <div className="gYIXoe"></div>
                  <div className="gYIXoe"></div>
                  <div className="gYIXoe"></div>
                  <div className="gYIXoe"></div>
                  <div className="gCGjQg"></div>
                  <div className="gCGjQg"></div>
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------ */}
          <h1 className="lfFgMS jYjMog">Shredded plan for {results.name} is <b>ready!</b></h1>

          {/* ----------------------------- */}
          <div className="cDmkPL">
            <ul className="dtPVxR">
              <li className="bWxWQq">
                <div className="iNLxjs">🎯</div>
                <div className="itfSmn">
                  <p className="cvqcMd">Goal</p>
                  <p className="eREnij">{results.body_goal.title}</p>
                </div>
              </li>
              <li className="bWxWQq">
                <div className="iNLxjs">⏳</div>
                <div className="itfSmn">
                  <p className="cvqcMd">Workout Duration</p>
                  <p className="eREnij">{results.workout_duration.title}</p>
                </div>
              </li>
              <li className="bWxWQq">
                <div className="iNLxjs">💪</div>
                <div className="itfSmn">
                  <p className="cvqcMd">Fitness Level</p>
                  <p className="eREnij">{results.level_of_fitness.fitnessLevel.title}</p>
                </div>
              </li>
              <li className="bWxWQq">
                <div className="iNLxjs">👟</div>
                <div className="itfSmn">
                  <p className="cvqcMd">Place to Workout</p>
                  <p className="eREnij">{results.workout_place.title}</p>
                </div>
              </li>
            </ul>
            <div className="UnrjU">
              <div className="iNLxjs">📆</div>
              <div className="itfSmn">
                <p className="cvqcMd">Workout Frequency</p>
                <p className="hSlFBB">{results.workout_frequency.title}</p>
              </div>
            </div>
          </div>

          {/* --------------------------- */}

          {/* <div className="ehulve">
            <div className="dEzpXA">
              <h2 className="rtJBA jiwYsD">Goals and areas to improve on for the next 6 months also include:</h2>
              <ul className="dXEqtV">
                {results.additional_goal.map((goal, index) => (
                  <li key={index} className="cMVbfJ">
                    <span style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                      <img src={tick} alt='tick_1' style={{ marginRight: "10px" }} />
                    </span>
                    <p className="cDpolK fSKttw">{goal.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
          {/* ------------------------------- */}
          <section className="eMmwoO">
            <div className="cwJHo">
            </div>
            <div className="eKcvtO">
              <ul className="kgsOUU">
                <li className="dXYGbD">
                  <img src={badge0} alt="PersonalFinal_Personalized_workout" className="gHIWvh" />
                  <p className="bShIVs">Personalized exercise program - your needs considered.</p>
                  <img src={badge1} alt="PersonalFinal_Clear_and_easy_to_follow" className="gHIWvh" />
                  <p className="bShIVs">Clear, logical and progressive plan</p>
                </li>
                <li className="dXYGbD">
                  <img src={badge2} alt="PersonalFinal_Visible_results" className="gHIWvh" />
                  <p className="bShIVs">Tangible results after the first month</p>
                  <img src={badge3} alt="PersonalFinal_Progress_tracking" className="gHIWvh" />
                  <p className="bShIVs">Progress tracking and analysis</p>
                </li>
              </ul>
              <div className="AcJuU">
                <img src={muckup2} alt="" className="hsuApy" />
                <img src={muckup1} alt="" className="gUMxIB" />
              </div>
            </div>
          </section>
          {/* ----------------------------------- */}

          <section className="PrJOx">
            <div className="hhGCCz">
              <h1 className="bpzQjr jHmcQA">Your Health, Heightened.</h1>
              <ul className="hzIbjB">
                <li className="cATWjc">
                  <span className="eGelwk">☑️</span>
                  <p className="eBRzvd gZifrk">Build a habit and develop the correct tools and techniques</p>
                </li>
                <li className="cATWjc"><span className="eGelwk">☑️</span>
                  <p className="eBRzvd gZifrk">Lose excessive body fat &amp; improve workout intensity, lung capacity, sleep and so much more</p>
                </li>
                <li className="cATWjc">
                  <span className="eGelwk">☑️</span>
                  <p className="eBRzvd gZifrk">Realise better health today, working closely with one of our world-leading trainers throughout your journey</p>
                </li>
              </ul>
            </div>
          </section>

          {/* --------------------------------------- */}
          <section className="idHQzB">
            <button type="button" title="Get my plan" className="fdiIEf" onClick={onGetMyPlanClick}>
              <p className="kbMuAn">Get my plan</p>
              <img src={rightArrow} className="eITCMH" />
            </button>
          </section>

          {/* --------------------------------------------- */}

          <section className="dNUxgu">
            <div className="kecdPy">
              <div className="iMDOAZ">
                <div className="bJpRLk">
                  <div className="hLHEEU">
                    <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                      <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                        <img alt="" aria-hidden="true" src={image15To17} style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                      </span>
                    </span>
                  </div>
                  <div className="jjFSPS">
                    <div className="kfFrHt" style={{ color: "white" }}>
                      <p className="fyblzY kCrxig">Become a Havenista. You wont look back.</p>
                      <ul className="dRVBGu">
                        <li className="cdNInI">
                          <span className="lbtHQX cOiKxN">john**</span>
                          <span className="lbtHQX dkUPYA">1 month plan</span>
                        </li>
                        <li className="sc-1b9cbfa8-3 cdNInI">
                          <span className="lbtHQX cOiKxN">lucas**</span>
                          <span className="lbtHQX dkUPYA">1 month plan</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="iiCiTU">
                <div className="kNrDXr">
                  <div className="WHgXf" style={{ display: "none" }}>
                    <section className="hWoZIk">
                      <header className="hMLZfk">
                        <button type="button" title="Close" className="DjmCs">
                          <svg viewBox="0 0 24 24" className="euqBEn">
                          </svg>
                        </button>
                      </header>
                      <div className="izdUpT">
                        <div className="jTOIdQ active">
                          <div className="efGXPp">
                            <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                              <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                <img alt="" aria-hidden="true" src="" style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                              </span>
                              <img alt="PayPal" src="" decoding="async" data-nimg="intrinsic" style={{ position: "absolute", inset: "0px", boxSizing: "borderBox", padding: "0px", border: "none", margin: "auto", display: "block", width: "0px", height: "0px", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }} />
                              <noscript></noscript>
                            </span>
                          </div>
                        </div>
                        <div className="jTOIdQ">
                          <div className="efGXPp">
                            <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                              <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                <img alt="" aria-hidden="true" src="" style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                              </span>
                              <img alt="Card" src="" decoding="async" data-nimg="intrinsic" style={{ position: "absolute", inset: "0px", boxSizing: "borderBox", padding: "0px", border: "none", margin: "auto", display: "block", width: "0px", height: "0px", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }} />
                              <noscript></noscript>
                            </span>
                            <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                              <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                <img alt="" aria-hidden="true" src="" style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                              </span>
                              <img alt="ApplePay" src="" decoding="async" data-nimg="intrinsic" style={{ position: "absolute", inset: "0px", boxSizing: "borderBox", padding: "0px", border: "none", margin: "auto", display: "block", width: "0px", height: "0px", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }} />
                              <noscript></noscript>
                            </span>
                            <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                              <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                <img alt="" aria-hidden="true" src="" style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                              </span>
                              <img alt="GooglePay" src="" decoding="async" data-nimg="intrinsic" style={{ position: "absolute", inset: "0px", boxWizing: "borderBox", padding: "0px", border: "none", margin: "auto", display: "block", width: "0px", height: "0px", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }} />
                              <noscript></noscript>
                            </span>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <form noValidate="" className="eTaYcj" id="select-plan">
                    <h2 className="rtJBA cEOkUp">Choose the best plan for you</h2>
                    <div className="xIfj">
                      {plans.map((plan, index) => (
                        <ProductPlanCardItem key={plan.id} plan={plan} index={index} onPlanSelected={onPlanSelected} />
                      ))}
                    </div>

                    <div className="gtUsHR">
                      <div className="hwvors">
                        <img src={muscleImage} alt={`Icon for ${info.title}`} loading="lazy" style={{ width: '18px', height: '18px', marginTop: '5px' }} />
                      </div>
                      <div className="fsAiQT">
                        <p className="dezAzo">People using plan for 3 months achieve twice as many results as for 1 month</p>
                        <p className="dEecnr">*According to a study by Health Haven, 2022</p>
                      </div>
                    </div>
                    <div className="hlLcfN" style={{ pointerEvents: "auto", opacity:  1, alignSelf: 'end' }}>

                      {/* <a href={checkoutUrl} className="lemonsqueezy-button"> */}
                      <button type="submit" title="Continue" className="fdiIEf"
                        onClick={handleSubmitClick}>
                        <p className="kbMuAn">Continue</p>
                        <img src={rightArrow} className="eITCMH" />
                      </button>
                      {/* </a> */}
                      {/* <script src="https://assets.lemonsqueezy.com/lemon.js" defer></script> */}
                    </div>
                  </form>
                  {isDialogOpen && (
                  <CustomDialog onClose={handleCloseDialog}>
                    <div style={{ maxHeight: '95vh', overflowY: 'auto' }}> {/* Apply inline styles for scrollable content */}
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm onClose={handleCloseDialog} selectedPlan={selectedPlan} />
                    </Elements>
                    </div>
                  </CustomDialog>
                   )}  
                </div>
                <div className="hFLdo">
                  <p className="dezAzo">By continuing, you agree that if you don't cancel at least 24 hours prior to the end of the current period, you will automatically be charged {selectedPlan.totalprice} {selectedPlan.currency} every month until you cancel in settings. Learn more about our cancellation and refund policy in <a title="" href="/subscription-terms">Subscription Terms</a>
                  </p>
                  <p className="dezAzo">By continuing, I agree with <a href="/terms-of-service">Terms of service</a>,
                    <a href="/privacy-policy">Privacy policy</a>,
                    <a href="/refund-policy">Refund policy</a>,
                    <a href="/subscription-terms">Subscription Terms</a>,
                    <a href="/cookie-policy">Cookie policy</a>
                  </p>
                  <p className="dezAzo">And I agree that my credit/debit card data will be stored and used for repeated purchase offers by you in the event of payment failure</p>
                </div>
              </div>
            </div>
          </section>

          {/* ------------------------------------------------- */}

          <section className="iobIND">
            <div className="icgHWB">
              <p className="ktapds">Money-Back Guarantee</p>
              <div className="kHOQOr">
                <span style={{ boxSizing: "borderBox", display: "block", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}>
                  <img alt="Money-Back Guarantee" src={moneyBack} decoding="async" data-nimg="fill" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "borderBox", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%", objectFit: "contain" }} />
                </span>
              </div>
            </div>
            <div className="guXZjB">
              <p className="cDpolK1 eODAWk">We believe that our plan may work for you and you’ll get visible results in 4 weeks! We are even ready to completely refund you within 30 days after purchase if you don’t get visible results and can demonstrate that you have followed our plan.</p>
              <p className="cDpolK1 eODAWk">Find out more about the applicable limitations in our <a style={{ color: "#12B2B3" }} title="" href="/refund-policy">money-back policy</a></p>
            </div>
          </section>

          {/* ------------------------------------------------- */}
          
          <StaticFaqSection/>

          {/* --------------------------------------------------- */}

          <ReviewSection/>

          {/*------------------------------------------- */}


          <section className="dDqJit" style={{}}>
            <div className="kytiAU">
              <p className="jGNoGa">
                <span className="fCiHyJ">Follow us</span> to become a real Havenista!</p>
              <div className="BYXOS">
                <a title="Instagram" rel="noopener noreferrer" href="http://www.instagram.com/healthhavenuk" className="cXatAT">
                  <span style={{ boxSizing: "borderBox", display: "inlinBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }}>
                    <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }}>
                      <img style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e" />
                    </span>
                    <img alt="Instagram" src={instragram} decoding="async" data-nimg="intrinsic" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "borderBox", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }} />
                  </span>
                </a>
                <div style={{ width: "20px" }}></div>
                <a title="Facebook" rel="noopener noreferrer" href="http://www.facebook.com/healthhavenofficial" className="cXatAT"><span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, position: "relative", maxWidth: "100%" }}>
                  <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: "100%" }}>
                    <img style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2732%27%20height=%2732%27/%3e" />
                  </span>
                  <img alt="Facebook" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgOC4wNUMwIDUuMjAyNTUgMCAzLjc3ODgyIDAuNTYyOTMzIDIuNjk1MTNDMS4wMzczMSAxLjc4MTkxIDEuNzgxOTEgMS4wMzczMSAyLjY5NTEzIDAuNTYyOTMzQzMuNzc4ODIgMCA1LjIwMjU1IDAgOC4wNSAwSDE1Ljk1QzE4Ljc5NzUgMCAyMC4yMjEyIDAgMjEuMzA0OSAwLjU2MjkzM0MyMi4yMTgxIDEuMDM3MzEgMjIuOTYyNyAxLjc4MTkxIDIzLjQzNzEgMi42OTUxM0MyNCAzLjc3ODgyIDI0IDUuMjAyNTUgMjQgOC4wNVYxNS45NUMyNCAxOC43OTc1IDI0IDIwLjIyMTIgMjMuNDM3MSAyMS4zMDQ5QzIyLjk2MjcgMjIuMjE4MSAyMi4yMTgxIDIyLjk2MjcgMjEuMzA0OSAyMy40MzcxQzIwLjIyMTIgMjQgMTguNzk3NSAyNCAxNS45NSAyNEg4LjA1QzUuMjAyNTUgMjQgMy43Nzg4MiAyNCAyLjY5NTEzIDIzLjQzNzFDMS43ODE5MSAyMi45NjI3IDEuMDM3MzEgMjIuMjE4MSAwLjU2MjkzMyAyMS4zMDQ5QzAgMjAuMjIxMiAwIDE4Ljc5NzUgMCAxNS45NVY4LjA1WiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzE0NDFfNzM5KSIvPgo8cGF0aCBkPSJNMTYuNjY2NyAxNS4zMzMzTDE3LjIgMTJIMTRWOS42NjY2NkMxNCA4LjczMzMyIDE0LjMzMzMgNy45OTk5OSAxNS44IDcuOTk5OTlIMTcuMzMzM1Y0LjkzMzMyQzE2LjQ2NjcgNC43OTk5OSAxNS41MzMzIDQuNjY2NjYgMTQuNjY2NyA0LjY2NjY2QzExLjkzMzMgNC42NjY2NiAxMCA2LjMzMzMyIDEwIDkuMzMzMzJWMTJIN1YxNS4zMzMzSDEwVjI0QzExLjMzMzMgMjQgMTIuNjY2NyAyNCAxNCAyNFYxNS4zMzMzSDE2LjY2NjdaIiBmaWxsPSJ3aGl0ZSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzE0NDFfNzM5IiB4MT0iMTIiIHkxPSIyMy4yOTg4IiB4Mj0iMTIiIHkyPSIwIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiMwMDYyRTAiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMTlBRkZGIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==" decoding="async" data-nimg="intrinsic" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, boxSizing: "borderBox", padding: 0, border: "none", margin: "auto", display: "block", width: 0, height: 0, minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%" }} />
                </span>
                </a>
              </div>
              <p className="fKEBTI keiusE">Any questions? We're <a title="" href="mailto:support@healthhaven.co.uk" style={{ color: "#" }}>here</a> to help</p>
              <p className="lhlHag kpEdpO">Copyright ® 2022 HealthHaven Pvt. Ltd
                All Rights Reserved</p>
            </div>
          </section>

        </div>

      </div>
    </div>
  )
}


export default FinalResult;


