import React, { useState, useEffect } from "react";
import '../assets/styles/InfoComponent.css';
import logo from '../assets/images/logo.png';

const InfoComponent = ({ info }) => {

    const [iconSrc, setIconSrc] = useState(logo);

    useEffect(() => {
        import(`../assets/images/${info.icon}`).then(icon => {
            setIconSrc(icon.default);
        });
    }, [info.icon]);

    return (
        <div style={{ marginBottom: '20px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="QwIiP">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", width: '100%' }}>
                    <img src={iconSrc} alt={`Icon for ${info.title}`} loading="lazy" style={{ width: '30px', height: '30px' , color: "white" }} />
                    <h1 className="cDpolK exXzgm">{info.title}</h1>
                </div>
                <p className="dezAzo1 iWSHCx">
                    {info.description}
                </p>
            </div>
        </div>
    );
}

export default InfoComponent;
