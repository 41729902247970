import React, { useState } from 'react';
import '../assets/styles/StaticFaqSection.css';

const faqs = [
    { question: "How does coaching work on Health Haven?", answer: "Your Health Haven coach is a real person who's on-call to support you. They'll message you often, build a new training plan for you each week, and respond in real-time as you need changes or have questions. Once you join Health Haven, you'll schedule a Strategy call so that you can get to know your coach and discuss your preferences and goals. Once your workouts begin, you’ll hear from your coach often in the Health Haven app. You’ll be able to book in your 1-2-1 sessions at times that suit you, track your progress through the app as you complete your workouts and meet your daily nutritional targets, and reach out to your coach and the Health Haven team at any time with questions.", icon: "rocket" },
    { question: "What if I don't have gym membership?", answer: "No problem! Your coach will create your custom workout plan with your unique situation in mind. Your workouts will be designed around your location and the equipment you have available - whether you are at home with no equipment, working out in a fully-stocked gym, or modifying your routine on vacation! Your coach can include outdoor runs and group fitness classes as well. Their goal is to create a convenient and realistic training plan that you can stick to."},
    { question: "Can I change my coach?", answer: "We are here to help you to change your coach at any time. Our team is dedicated to your success, and we'll make sure we find your perfect match. We can connect you with one of our Coach Advisors to help you select a new coach." },
    { question: "What's the cancellation policy?", answer: "We want you to be completely satisfied with your experience. You can try Health Haven risk-free for the first month, and we'll give you a full refund if you decide to cancel. After the first month, you can cancel at any time, and we won't charge you again."},
    { question: "What kind of time commitment do I have to make?", answer: "Health Haven is designed to fit into your schedule — not the other way around. Your coach will design and schedule your workouts based on what's realistic for you and can make changes during the week to accommodate your busy life." },
    { question: "What if I have an injury or special needs?", answer: "If your medical professional has cleared you for exercise, then working with a personal coach can be highly beneficial for preventing or managing many common chronic conditions and injuries. Your Health Haven coach will design safe, effective workouts that meet your specific needs." },
    { question: "Are there any extra fees?", answer: "Nope, what you see is what you get." }

];

function FaqItem({ faq }) {
    return (
        <div className="faq-item">
            <h4 className="faq-question">
             {faq.question}
            </h4>
            <p className="faq-answer">{faq.answer}</p>
        </div>
    );
}

export default function StaticFaqSection() {
    return (
        <div style={{ width: '100%', justifyContent: 'center', textAlign:'center', display:'flex' }}>
            <div className="faq-container">
                <h3 className="faq-title">Frequently Asked Questions</h3>
                <p className="faq-intro">Didn't find the answers you were looking for ? or If you're just not satisfied, Send us a message of your query and we'll have all your doubts cleared.</p>
                <div className="faq-grid">
                    {faqs.map(faq => <FaqItem key={faq.question} faq={faq} />)}
                </div>
            </div>
        </div>

    );
}