import React from 'react';
import logo from '../../logo.svg';
import '../../assets/styles/Header.css'
import RightDrawerNav from '../RightDrawerNav';
import PropTypes from 'prop-types';

const Header = ({ isHumbergerVisible }) => {
  return (
    <header className="app-header">
      <a title="" className="fZErNR" href="/">
        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
          <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
            <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src={logo} />
          </span>
          <img alt="Health Haven" src={logo} decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
        </span>
      </a>
      {isHumbergerVisible && (<div className='heCXBQ'>
        <RightDrawerNav />
      </div>
      )}
    </header>
  );
};

Header.defaultProps = {
  isHumbergerVisible: true
};

Header.propTypes = {
  isHumbergerVisible: PropTypes.bool,
};

export default Header;

