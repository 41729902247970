import React from "react";
import "../../assets/styles/TermsAndCondition.css";
import Header from "../../layout/header/Header";

const TermsAndCondition = () => {
  return (
    <div style={{justifyContent:'center', display:'flex', background:'radial-gradient(34.93% 44.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%)', backgroundColor:'black'}}>
          <div style={{ position: "fixed", width: '100%', backgroundColor: "black" }}>
                <Header isHumbergerVisible = {false} />
            </div>
    <div  className="aSuiop">
    <div>
      <p><b>Application Clients and Users &#8211; Health Haven Registration terms and conditions <br/> <br/>Our terms <br/></b></p>
      <p><b> <br/>1. These terms <br/></b></p>
      <p>1.1 These are the terms and conditions on which you agree to use the Health Haven mobile application.</p>
      <p>1.2 Please read these terms carefully before you register with us. These terms tell you who we are, how we will provide products to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these terms, please contact us to discuss.</p>
      <br/>
      <p><b>2. Information about us and how to contact us</b></p>
       <p>2.1 We are HEALTH HAVEN TECHNOLOGIES LTD a Limited Liability Company in England and Wales. Our company registration number is 11113878 and our registered office is at 22 Seymour Rd, Kingston upon Thames, KT1 4HW.</p>
      <p>2.2 You can contact us by telephoning our customer service team at +44 20 8123 5723 / +1 347 441 0993 or <br/>by WhatsApp'ing +1 646 957-700, or by writing to us at support@healthhaven.co.uk, or by mailing us <br/>through the internal Contact Us feature within the application. <br/></p>
      <p>2.3 If we have to contact you we will do so by telephone or by writing to you at the email address or postal address you provided to us in your registration.</p>
       <p>2.4 When we use the words &quot;writing&quot; or &quot;written&quot; in these terms, this includes emails.</p>

      <p> <br/><b>3. Our contract with you <br/></b></p>
      <p>3.1 Our acceptance of your registration will take place when we email you to accept it, at which point a contract will come into existence between you and us.</p>
      <p>3.2 By becoming a member and registered user of Health, you agree to our Cookie Policy, Cookie Notice and agree to having your details stored on a database a our range of accessible by our range and products and services, including the mobile application and www.findhealthcoach.com  website</p>
      </div>
      <div>
        <br/>
       
        <p><b>4. Our products</b></p>
      <p>4.1 The images of the mobile application that you can see on the website are for illustrative purposes only, and the
      appearance and design of the actual product may differ and will be subject to updates. It does however have all of the functionality detailed on the website.</p>
      <br/>
      <br/>
      
      <p><b>5. Your rights, terms and conditions for use of the product</b> <br/></p>
      <p>5.1 By being a registered user, you have the right to search for, connect and book in to any service provided by a personal trainer, sports coach or fitness professional subject to their fees </p>
      <p>5.2 You are able to search for and connect with any other user in the application and view their profile and other relevant information.</p>
        <p>5.3 You may communicate with all such users through the chat feature, however you may not: </p>
      <p>(a) Entice away trainers or users of any kind from the platform 
      <br/>(b) Agree to make bookings for sessions with coaches outside of the application </p>
      <p>Should you participate in any of the activities listed above, you may be subject to action as described in Section 6.1. Should you feel any user of any kind is attempting to undertake such activities, you must inform us of this as soon as possible. <br/></p>
       <p>5.4 Where booking into face to face appointments with personal trainers, coaches or fitness professionals, it is their
      responsibility to make sure area you are both meeting is neutral, safe, available and that they have relevant insurance as detailed in Section 12.</p>

      <p>5.5 You must pay for these services prior to booking in a face to face session or massage therapy session, and on a rolling monthly (every 4 weeks) basis for workout/nutrition planning and fitness advice (until you cancel if you decide to do so). This is all done through Stripe within the app. </p>
      <p>5.6 You are under no obligation to commit to a minimum number of sessions or bookings with any given provider within any given timeframe, however you must commit to all sessions that you pay for and attend in a timely fashion.</p>
      <p>5.7 You must be completely honest when following up with your feedback and reviews of your experience with any given service provider, and these must be completed within 48 hours of the given session.</p>
      <p>5.8 Failure to attend any given session may result in a suitable fee as communicated to you beforehand, which may take the form of an increased fare for your next booking, or a potential suspension or even cancellation of your membership with the platform. Regardless of the fee, the payment for the session will be made to the coach and will be non-negotiable in this scenario. </p>
      <p>5.9 Health Haven bears no responsibility for the success, failure or quality of any given service that you participate in with relation to how it is offered by a given trainer or fitness professional.</p>
     
      <p> <br/><b>6. Our rights to make changes to your membership with us <br/></b></p>
      <p>6.1 Should you at any point breach any of the terms and conditions listed in Section 5, we may terminate or suspend your membership and notify you of this.</p>
      <p>6.2 If this occurs whilst you have outstanding payments, you will need to pay these first prior to us terminating your contract. If payments are owed to you, such as for service providers failing to attend a session, we will make sure to credit these to your account before your membership is cancelled.</p>
      <p>6.3 The way in which we determine such breaches may be a result of consistent bad service provider feedback or by other means. You will be made aware should feedback be consistently negative.</p>

      <p> <br/><b>7. Selecting your services <br/></b></p>
      <p>7.1 If you are unable to attend a session you must let the service provider, and ourselves via one of the contact </p>
      </div>
      <div>
        <p>methods detailed above, know of this change to your availability no less than 48 hours prior to the scheduled session. Failure to do so may result in the actions detailed in Section 6.</p>

      <p>7.2 If you have planned to carry out a session with a service provider (be it a face to face personal training session,massage therapy or any other service) in a public place and for extraordinary reasons outside of those detailed in 5.4 you are unable to carry out the session, both yourself and the service provider must mention this in your feedback form and/or contact us so that we may allow the you to have another session at no extra charge and address the issue.</p>
      <p>7.3 If the service provider has informed us of any changes to his/her availability or lack of availability with respect to a booked session, if it is within the 48 hours prior to the arranged session we will compensate you accordingly.</p>
      <p>7.4 If you arrange for a meeting to be at your house/private property, it is your responsibility to ensure the premises are suitable and safe for your session </p> 

      <p><br/><b>8. Your rights to end the contract <br/></b></p>
      <p>8.1 You may cancel your contract at anytime, provided:</p>
      <p>(a) You have completed all outstanding client services</p>
      <p>(b) You have paid all outstanding fees, where due</p>
      <p> 8.2 You may not cancel your contract until those items listed in 8.1 are finished.</p>
      <p> 8.3 You may cancel for any legal reasons, such as (but not limited to):</p>
      <p> (a) Failure on our behalf to refund you within the agreed timeframe following an agreement that you were either not provided the service you paid for, or that it was provided to an overwhelmingly unacceptable degree of quality.</p>

      <p><b>9. How to end the contract with us (including if you have changed your mind)</b></p>
       <p>9.1 To end the contract with us, please let us know by doing one of the following:</p>
      <p>(a) <b>Phone or email</b>. Call customer services on 07923626250 or email us at support@healthhaven.co.uk.</p>
      <p>Please provide your name, home address, details of your reason and, where available, your phone <br/>number and email address.</p>
      <p>(b) <b>By post</b>. Write up a document detailing the reasons for your cancellation and post it to the postal address detailed above. Or simply write to us at that address, including details of what you bought, when you ordered or received it and your name and address.</p>

      <p> <br/><b>10. Our rights to end the contract </b></p>
      <p>10.1 We may end the contract for a product at any time by writing to you if: </p>
      <p> (a) You fail to comply with any of the terms stated in Section 5.</p>
      <p> (b) You do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to carry out our services;</p> 
      <p>(c) You have been found to have lied on one of your feedback forms (following an investigation from inconsistency between feedback from yourself and the service provider)</p>
      <br/>
      <p>10.2 We may write to you to let you know that we are going to stop providing the product. We will let you know at least 2 weeks in advance of our stopping the supply of the product and will refund any sums you have paid in advance for products which will not be provided. </p>
      </div>

      <div><p> <br/><b>11. If there is a problem with the product</b></p>
      <p>11.1 If you have any questions or complaints about the product, please contact us. You can telephone our</p>
      <p>customer service team at 07923626250 or write to us at support@healthhaven.co.uk.</p> 
      <p>11.2 If you wish to exercise your legal rights between you and the client, it is your responsibility to exercise these.</p>
      
      <p> <br/><b>12. Indemnity <br/></b></p>
      <p>Under the terms and conditions of any personal trainer, fitness professional or sports coach of any kind becoming an approved member of our network, they must: </p>
      <p>12.1 Take out and maintain throughout the term of this Agreement, adequate insurance with an insurance office of repute to protect themselves against any liabilities arising out of this Agreement and shall produce, at the request of the Company or indeed an individual/client, a copy of the insurance policy or policies and relevant renewal receipts for inspection by the Company;</p>
      <p>12.2 Take out and maintain throughout the term of this Agreement, adequate liability insurance coverage (minimum &#163;5 million cover) with an insurance office of repute to protect themselves against any liabilities arising out of this Agreement in respect of all and any contractors/employees they utilise to carry out the Services and shall produce, at the request of the Company, a copy of the insurance policy or policies and relevant renewal receipts for inspection by the Company.</p>
      
      <p> <br/><b>13. Price and payment</b></p>
      <p>13.1 The price of the service you offer to a given client is decided by the service provider you connect with and select, and will be paid by you to the company according to the following:</p>
       <p>a.) For face to face meetings and massage therapies, the payment must be made before you can progress any further to the booking of the session</p>
       <p>b.) For workout and nutrition planning and fitness advice, the amount will be paid by you every month (as these services are booked in monthly sections), recurring after the first payment is made upon initiation of this service unless you decide to stop this service.</p>
      <p>13.2 If for any reason there is an issue with the payment by you, we shall contact you accordingly to notify you and arrange the payment as quickly as possible </p>
      <p>13.3 If for any reason there is a problem with the submission of the feedback forms from you and the service provider following a session (for example if there is a dispute between the two parties), we shall attempt to mediate the issue and come to a resolution, and as a result, no refund will be made until this is complete.</p>
      <p>13.4 No refund shall be made if the service provider has attended the session, even if you are not happy with the service given.</p>
      <p>13.5 Should you or the service provider cancel a session, it must be done with at least 48 hours&#8217; notice. </p>
      
      <p><br/><b>14. Our responsibility for loss or damage suffered by you </b></p>
      <p>14.1 If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us during the sales process.</p>
      <p>14.2 We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or <br/>subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the products; and for defective products under the Consumer Protection Act 1987.</p>
      </div>

      <div><p> <br/><b>15. How we may use your personal information </b></p>
      <p>15.1 We will only use your personal information as set out in our privacy policy, available on the website.</p>

      <p> <br/><b>16. Other important terms </b></p>
      <p>16.1 We may transfer our rights and obligations under these terms to another organization. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</p>
      <p>16.2 You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</p>

      <p><br/><b>17. Disputes </b></p>
      <p>17.1 We will do our best to resolve any disputes over these terms. If you wish to take court proceedings against us,you must do so within the United Kingdom.</p>
      <p>17.2 Relevant United Kingdom law will apply to these terms.</p>
      <p>17.3 These terms are subject to English law.</p>
      <p><b>Website T&amp;C&#8217;s</b></p>
      <p><b>1. Terms and Conditions</b></p>
      <p><b>1.1</b> These Terms and Conditions describe the rules and regulations for the use of the Health Haven Technologies Limited's Website (referred to as Health Haven throughout).</p>
      <p><b>1.2</b> By accessing this website we assume you accept these terms and conditions. Do not continue to use Health Haven if you do not agree to take all of the terms and conditions stated on this page.</p>
      <p><b>1.3</b> The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to you, the person log on this website and compliant to the Company's terms and conditions. &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to our Company. &quot;Party&quot;, <br/> &quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
      
      <p><b>2. Cookies</b></p>
      <p><b>2.1</b> We employ the use of cookies. By accessing Health Haven, you agreed to use cookies in agreement with the Health Haven Technologies Limited's Privacy Policy.</p>
      <p><b>2.2</b> Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
      <p><b>3. License </b></p>
      <p><b>3.1</b> Unless otherwise stated, Health Haven Technologies Limited and/or its licensors own the intellectual property rights for all material on Health Haven. All intellectual property rights are reserved. You may access this from Health Haven for your own personal use subjected to restrictions set in these terms and conditions.</p>
      <p><b>4. Restrictions </b></p>
      <p><b>4.1</b> You are specifically restricted from all of the following: </p>
      <p>(a) publishing any Health Haven material in any other media; </p>
      <p>(b) selling, sublicensing and/or otherwise commercializing any Health Haven material;</p>
      <p>(c) publicly performing and/or showing any Health Haven material;</p>
      <p>(d) using Health Haven in any way that is or may be damaging to Health Haven; </p>
      <p>(e) using Health Haven in any way that impacts user access to Health Haven; </p>
      <p>(f) using Health Haven contrary to applicable laws and regulations, or in any way may cause harm to the Health Haven, or to any person or business entity;</p>
      <p>(g) engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to Health Haven;</p>
      <p>(h) using Health Haven to engage in any advertising or marketing. <br/></p>
      <p><b>4.2</b> Certain areas of Health Haven are restricted from being accessed by you and Health Haven Technologies Limited may further restrict access by you to any areas of Health Haven, at any time, in absolute discretion. Any user ID and password you may have for Health Haven are confidential and you must maintain confidentiality as well.</p>
      <p><b>5. Your Content </b></p>
      <p><b>5.1</b> In these Website Standard Terms and Conditions, &quot;Your Content&quot; shall mean any audio, video text, images or other material you choose to display on Health Haven. By displaying Your Content, you grant Health Haven Technologies Limited a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
      <p><b>5.2</b> Your Content must be your own and must not be invading any third-party's rights. Health Haven Technologies Limited reserves the right to remove any of Your Content from Health Haven at any time without notice.</p>
      
      <p><b>6. No warranties </b></p>
      <p><b>6.1</b> Health Haven is provided &quot;as is,&quot; with all faults, and Health Haven Technologies Limited expresses no representations or warranties, of any kind related to Health Haven or the materials contained on Health Haven. Also, nothing contained on Health Haven shall be interpreted as advising you.</p>
      <p><b>7. Limitation of liability </b></p>
      <p><b>7.1</b> In no event shall Health Haven Technologies Limited, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of Health Haven whether such liability is under contract.  Health Haven Technologies Limited, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of Health Haven.</p>
      
      <p><b>8. Indemnification</b></p>
      <p><b>8.1</b> You hereby indemnify to the fullest extent Health Haven Technologies Limited from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms. </p>
      <p><b>9. Severability </b></p>
      <p><b>9.1</b> If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
      
      <p><b>10. Variation of Terms </b></p>
      <p><b>10.1</b> Health Haven Technologies Limited is permitted to revise these Terms at any time as it sees fit, and by using Health Haven you are expected to review these Terms on a regular basis. <br/></p>
      
      <p><b>11. Assignment</b></p>
      <p><b>11.1</b> The Health Haven Technologies Limited is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or <br/>subcontract any of your rights and/or obligations under these Terms.</p>
      <p><b>12. Entire Agreement</b></p>
      <p><b>12.1</b> These Terms constitute the entire agreement between Health Haven Technologies Limited and you in relation to your use of Health Haven, and supersede all prior agreements and understandings.</p>
      <p><b>13. Governing Law &amp; Jurisdiction</b></p>
      <p><b>13.1</b> These Terms will be governed by and interpreted in accordance with the laws of the State of gb, and you submit to the non-exclusive jurisdiction of the state and federal courts located in gb for the resolution of any disputes.</p>
      </div>
    </div>
    </div>
  );
}

export default TermsAndCondition;
