
import React from 'react';
import '../../assets/styles/CookiePolicy.css';
import Header from '../../layout/header/Header';
import '../../assets/styles/CookiePolicy.css';

const CookiePolicy = () => {

  const handleClick = () => {
    window.location.href = `mailto:support@healthhaven.co.uk`;
  };


  return (

    <div className="cookie-policy-container">
    <Header isHumbergerVisible={false} />
    <div className="content">
    <div class="kHLZZt">
      <div class="jkzPLI"><p>
        <h1>Cookie policy</h1>
      </p>
        <p>This cookie policy explains how we use cookies and other similar technologies when you use our service, what tracking technologies are and why we use them. It also explains how you can control their use.</p>
        <p>If you have any questions you can contact us at <a href="mailto:support@healthhaven.co.uk" style={{ color: "#12B2B3" }} onClick={handleClick}>support@healthhaven.co.uk</a>.</p>
        <p><h2>Cookies</h2></p>
        <p>A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic device) when you visit our website to store a range of information, for example, your language preference, or browser and device you are using to view the website. Those cookies are set by us and called <em>first-party cookies</em>. We also use <em>third party cookies</em>, which are cookies from a domain different from the domain of our website, for our advertising and marketing efforts.</p>
        <p><em>Session cookies</em> expire each time you close your browser and do not remain on your device afterwards. These cookies allow our website to link your actions during a particular browser session.</p>
        <p><em>Persistent cookies</em> will remain on your device for a period of time, and will expire on a set expiration date, or when you delete them manually from your cache. These cookies are stored on your device in between browser sessions and allow your preferences and actions across our website to be remembered.</p>
        <p><strong>Similar technologies</strong></p>
        <p>In addition to cookies, there are other similar technologies that may be used on the web or in mobile ecosystems.</p>
        <ul>
          <li><p><em>Web beacon</em> is a small, transparent image (also known as “pixel tag”, “web bug”, “GIF”) that contains a unique identifier. It is embedded into a website or an email. When your browser reads the code of the website it communicates with our server in order to display the image and, through this process, we are able to acquire information such as technical specifications of your device, operating systems and settings being used. It can also track your activities during a session. Also, it enables us to recognize when each email was opened, from what IP address and from what device. We use this information to improve our email communication.</p></li>
          <li><p><em>Software development kits (SDK)</em> are third-party software development kits that may be installed in our mobile applications. SDKs help us understand how you interact with our mobile applications and collect certain information about the device and network you use to access the application.</p></li>
          <li><p><em>Local shared objects</em>, commonly called “Flash cookies”, are pieces of data that websites which use Adobe Flash may store on a user's computer to facilitate the Flash functionality. We may use Adobe Flash to display graphics, interactive animations and other enhanced functionality. Local shared objects can track similar parameters to cookies but they can also provide information on your use of the specific feature that the cookie is enabling. For example, if it facilitates the display of a video then we can receive details of how much of the video was watched and when viewing stopped. Flash cookie management tools can be accessed directly via <a href="http://www.adobe.com" style={{ color: "#12B2B3" }}>www.adobe.com</a>.</p></li>
          <li><p><em>HTML5 local storage</em>. HTML 5 is the fifth version of the HTML language and contains functionality that allows information to be stored locally within the browser’s data files. HTML5 local storage operates in a similar way to cookies but differs in that it can hold greater amounts of information and does not rely on an exchange of data with the website’s server.</p></li>
          <li><p><em>Fingerprinting</em> is a technique that involves combining a set of information elements in order to uniquely identify a particular device. These information elements include, for example: data from configuration of the device, CSS information, JavaScript objects, installed fonts, installed plugins with the browser, use of any APIs, HTTP header information, clock information.</p></li>
          <li><p><em>Device Identifiers</em> are identifiers comprised of numbers and letters, which are unique to each specific device. These include Apple’s ID for Advertisers (IDFA) and Google’s Android Advertising ID (AAID). They are stored on the device and are used to recognize you and your devices across different apps and devices for marketing and advertising purposes. You can reset your device identifier or opt out of personalized advertising in the settings of your device.</p></li>
          <li><p><em>Social widgets</em> are buttons or icons provided by third-party social media providers that allow you to interact with those social media services when you view a web page or a mobile app screen. These social widgets may collect browsing data, which may be received by the third party that provided the widget, and are controlled by the third parties.</p></li>
        </ul>
        <p><h2>How can you manage your cookies and similar technologies?</h2></p>
        <ol>
          <li><p><strong>Consent management tool</strong></p></li>
        </ol>
        <p>You can manage your cookie preferences on a consent management platform that appears when you visit the website or when you click on the “<em>Privacy”</em> icon at the right bottom corner of any page of our website. It may be necessary to refresh the page for the updated settings to take effect. Essential cookies cannot be disabled, nor can the tool be used to block cookies on third-party websites linked to our website.</p>
        <ol start="2">
          <li><p><strong>Browser and device settings</strong></p></li>
        </ol>
        <p>Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
        <ul>
          <li><p><a href="https://support.google.com/chrome/answer/95647" style={{ color: "#12B2B3" }}>Chrome</a></p></li>
          <li><p><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" style={{ color: "#12B2B3" }}>Firefox</a></p></li>
          <li><p><a href="https://www.opera.com/help/tutorials/security/cookies/" style={{ color: "#12B2B3" }}>Opera</a></p></li>
          <li><p><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" style={{ color: "#12B2B3" }}>Internet Explorer</a></p></li>
          <li><p><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" style={{ color: "#12B2B3" }}>Safari</a></p></li>
          <li><p><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" style={{ color: "#12B2B3" }}>Edge</a></p></li>
        </ul>
        <p>Detailed instructions on how to control your cookies through browser settings can also be found here: <a href="https://www.aboutcookies.org/how-to-control-cookies/" style={{ color: "#12B2B3" }}>https://www.aboutcookies.org/how-to-control-cookies/</a>.</p>
        <p>Please note that blocking all cookies will have a negative impact on the usability of many websites. If you block cookies, you will not be able to use all the features on our website.</p>
        <p>To reset your device identifier or opt-out of personalized advertising, follow <a href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en" style={{ color: "#12B2B3" }}>Google instructions</a> or <a href="https://support.apple.com/en-gb/HT205223#:~:text=To%20reset%20your%20Advertising%20Identifier%20on%20macOS%2C%20choose%20Apple%20menu,be%20less%20relevant%20to%20you." style={{ color: "#12B2B3" }}>Apple instructions</a>.</p>
        <p>To opt out of data usage by Google Analytics follow instructions: <a href="https://tools.google.com/dlpage/gaoptout" style={{ color: "#12B2B3" }}>https://tools.google.com/dlpage/gaoptout</a>.</p>
        <ol start="3">
          <li><p><strong>Opt-out of internet-based advertising</strong></p></li>
        </ol>
        <p>The third-party advertisers, ad agencies and vendors with which we work may be members of the Network Advertising Initiative, the Digital Advertising Alliance Self-Regulatory Program for Online Behavioral Advertising, the European Digital Advertising Alliance. To opt-out of interest-based advertising from the participating companies, visit the following links:</p>
        <ul>
          <li><p>Network Advertising Initiative</p> <a href="http://optout.networkadvertising.org/" style={{ color: "#12B2B3" }}>http://optout.networkadvertising.org/</a></li>
          <li><p>Digital Advertising Alliance</p> <a href="http://optout.aboutads.info/" style={{ color: "#12B2B3" }}>http://optout.aboutads.info/</a></li>
          <li><p>Digital Advertising Alliance (Canada)</p> <a href="http://youradchoices.ca/choices" style={{ color: "#12B2B3" }}>http://youradchoices.ca/choices</a></li>
          <li><p>Digital Advertising Alliance (EU)</p> <a href="http://www.youronlinechoices.com/" style={{ color: "#12B2B3" }}>http://www.youronlinechoices.com/</a></li>
          <li><p>DAA AppChoices page</p> <a href="http://www.aboutads.info/appchoices" style={{ color: "#12B2B3" }}>http://www.aboutads.info/appchoices</a></li>
        </ul>
        <p><h2>Cookies list</h2></p>
        <p>You can find more information about the cookies and similar technologies we use and the purposes for which we use them in the table below:</p>
        <p><strong>1. Strictly necessary cookies.</strong> These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the website will not then work.</p>
        <p><em>Strictly necessary cookies enable you to benefit from our website features. We use cookies to process your answers, and then to generate a personalized workouts plan.</em></p>
        <div className="table-wrapper">
          <table><tbody><tr><td><p><strong>Cookie</strong></p></td><td><p><strong>Type</strong></p></td><td><p><strong>Source</strong></p></td><td><p><strong>Purpose</strong></p></td><td><p><strong>Expiry</strong></p></td></tr><tr><td><p>AWSALBCORS</p></td><td><p>1st party</p></td><td><p>Amazon</p></td><td><p>This cookie is managed by Amazon Web Services and is used for load balancing. <a href="https://aws.amazon.com/ru/privacy/" style={{ color: "#12B2B3" }}>Privacy policy</a></p></td><td><p>7 days</p></td></tr><tr><td><p>receiveupdates</p></td><td><p>1st party</p></td><td><p>HealthHaven</p></td><td><p>Cookie helps store user’s consent to marketing emails.</p></td><td><p>1 day</p></td></tr><tr><td><p>CookieConsent</p></td><td><p>1st party</p></td><td><p>Quantcast</p></td><td><p>Stores the user's cookie consent state for the current domain.</p></td><td><p>365 days</p></td></tr><tr><td><p>utm_campaign</p></td><td><p>1st party</p></td><td><p>HealthHaven</p></td><td rowspan="3"><p>We use these cookies to store from where you came to our website.</p></td><td><p>365 days</p></td></tr><tr><td><p>utm_medium</p></td><td><p>1st party</p></td><td><p>HealthHaven</p></td><td><p>365 days</p></td></tr><tr><td><p>utm_source</p></td><td><p>1st party</p></td><td><p>HealthHaven</p></td><td><p>365 days</p></td></tr><tr><td><p>rootUrl</p></td><td><p>1st party</p></td><td><p>HealthHaven</p></td><td><p>Defines default root url for website.</p></td><td><p>365 days</p></td></tr></tbody></table></div>
        <p><strong>2. Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors, to know which pages are the most and least popular, and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily. If you do not allow these cookies we will not know when you have visited our website, and will not be able to monitor its performance.</p>
        <div className="table-wrapper"><table><tbody><tr><td><p><strong>Cookie</strong></p></td><td><p><strong>Type</strong></p></td><td><p><strong>Source</strong></p></td><td><p><strong>Purpose</strong></p></td><td><p><strong>Expiry</strong></p></td></tr><tr><td><p>_ga</p></td><td><p>1st party</p></td><td rowspan="3"><p>Google Analytics</p></td><td rowspan="3"><p>This cookie is used for Google's real-time bidding advertising exchange. <a href="https://policies.google.com/privacy" style={{ color: "#12B2B3" }}>Privacy policy</a></p></td><td><p>730 days</p></td></tr><tr><td><p>_gid</p></td><td><p>1st party</p></td><td><p>1 day</p></td></tr><tr><td><p>_gat</p></td><td><p>1st party</p></td><td><p>1 day</p></td></tr><tr><td><p>amplitude_testunimeal.com</p></td><td><p>1st party</p></td><td><p>Amplitude</p></td><td rowspan="2"><p>These cookies are used by Amplitude for session tracking for analytics purposes. <a href="https://amplitude.com/privacy" style={{ color: "#12B2B3" }}>Amplitude $Privacy policy.</a></p></td><td><p>365 days</p></td></tr><tr><td><p>amplitude_cookie_test</p></td><td><p>1st party</p></td><td><p>Amplitude</p></td><td><p>Session</p></td></tr></tbody></table></div>
        <p><strong>3. Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region). They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.</p>
        <div className="table-wrapper"><table><tbody><tr><td><p><strong>Cookie</strong></p></td><td><p><strong>Type</strong></p></td><td><p><strong>Source</strong></p></td><td><p><strong>Purpose</strong></p></td><td><p><strong>Expiry</strong></p></td></tr><tr><td><p>_pin_unauth</p></td><td><p>1st party</p></td><td><p>Pinterest</p></td><td><p>This cookie is used to track usage of services. <a href="https://policy.pinterest.com/en/cookies" style={{ color: "#12B2B3" }}>Pinterest $Cookie policy</a></p></td><td><p>365 days</p></td></tr></tbody></table></div>
        <p><strong>4. Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. These cookies may be set through our website by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.</p>
        <p><strong>4.1.</strong> We use Meta Pixel to collect data about your actions on our Service. This data may include which pages you visit, the time you spend on each page, and the actions you take. The data collected is used to measure the effectiveness of our advertising campaigns and to personalize the content and ads presented to you. The information collected through Meta Pixel is transmitted to Meta. Your browser establishes a direct connection with the Meta server as soon as you have agreed to the use of tracking technologies requiring your consent.</p>
        <p>The processing of this data by Meta takes place within the framework of Meta’s <a href="https://www.facebook.com/policy.php" style={{ color: "#12B2B3" }}>data policy</a>.</p>
        <p>To opt-out from Meta’s interest-based ads follow these instructions from <a href="https://www.facebook.com/help/568137493302217" style={{ color: "#12B2B3" }}>Meta</a>.</p>
        <div className="table-wrapper"><table><tbody><tr><td><p><strong>Cookie</strong></p></td><td><p><strong>Type</strong></p></td><td><p><strong>Source</strong></p></td><td><p><strong>Purpose</strong></p></td><td><p><strong>Expiry</strong></p></td></tr><tr><td><p>_fbp</p></td><td><p>1st party</p></td><td><p>Facebook</p></td><td><p>This cookie is used to deliver a series of advertisement products such as real-time bidding from third party advertisers using Facebook.</p></td><td><p>90 days</p></td></tr><tr><td><p>fr</p></td><td><p>3rd party</p></td><td><p>Facebook</p></td><td><p>This cookie enables ad delivery or retargeting. It contains a browser and user unique ID combination, used for targeted advertising.</p></td><td><p>90 days</p></td></tr><tr><td><p>_pinterest_ct_ua</p></td><td><p>1st party</p></td><td><p>Pinterest</p></td><td><p>This cookie is used to track usage of services. <a href="https://policy.pinterest.com/en/cookies" style={{ color: "#12B2B3" }}>Pinterest Cookie policy</a></p></td><td><p>365 days</p></td></tr><tr><td><p>_dlt</p></td><td><p>1st party</p></td><td><p>Google</p></td><td><p>This cookie is used for Google's real-time bidding advertising exchange.</p></td><td><p>Session</p></td></tr></tbody></table></div>
        <p><strong>Changes to this policy</strong></p>
        <p>We may change this policy from time to time, when we do we will inform you by updating the “Last updated” date below.</p>
        <p>Last updated: 10 March 2022</p></div></div>
    </div>
  </div>

  );
}

export default CookiePolicy;