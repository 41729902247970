
import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProgressBar from "../components/ProgressBar";
import backIcon from '../assets/images/back.png';
import '../assets/styles/ProgressBarLayout.css';

const ProgressBarComponent = ({ showBar = true }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const currentStepIndex = location.state ? location.state.currentStepIndex : 0;

  const currentProgress = useSelector((state) => state.progress.progress);
  const total = useSelector((state) => state.progress.total);


  const onGoBack = () => {
    navigate(-1);
  };


  return (
    <div>
      <div className="sticky-container">
        <div className="csXCYg xhIjl">
          <div className="kkiseL">
            <div className="bbiyBI" onClick={onGoBack}>
              <img src={backIcon} alt="back" style={{ width: "24px", height: "24px", padding: "6px" }} />
            </div>
            {showBar &&
              <ProgressBar
                style={{ width: "100%", marginRight: "20px" }}
                progressStyle={{
                  top: "10%",
                  left: "0%",
                  right: "0%",
                  height: '4px',
                  width: '100%',
                  background: "#393939"
                }}
                fillerStyle={{
                  width: `${currentProgress - 7}%`,
                  background: '#12B2B3',
                  height: "4px",
                }}
              />}
            {showBar && <div className="gECtKm cvkoXM">{(currentStepIndex + 1)}/{total}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBarComponent;