// store.js

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer,   FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import indexReducer from '../reducers/indexSlice';
import dataReducer from '../reducers/getStepsSlice';
import progressReducer from '../reducers/progressSlice';
import uploadDataReducer  from '../reducers/uploadDataSlice';
import resultReducer  from '../reducers/getResultSlice';
import plansReducer  from '../reducers/getPlanSlice';


import { combineReducers } from '@reduxjs/toolkit';
// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  // Add any additional configuration options as needed
};

// Create the root reducer
const rootReducer = combineReducers({
  data: dataReducer,
  index: indexReducer,
  progress: progressReducer,
  upload: uploadDataReducer,
  results: resultReducer,
  plans : plansReducer
});

// Wrap the root reducer with redux-persist
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

// Create the Redux persistor
export const persistor = persistStore(store);
