import React from 'react';
import FormSqurareRadioBoxWithImages from "../forms/FormSquareRadioBoxWithImage";
import PropTypes from 'prop-types';
import FormRangeWithCondition from '../forms/FormRangeWithCondition';
import FormSquareImageCheckboxWithImages from '../forms/FormSquareCheckboxWithImage';
import FormRadioWithText from '../forms/FormRadioWithText';
import FormSingleTextInput from '../forms/FormSingleTextInput';
import FormSquareCheckBox from '../forms/FormSquareCheckBox';
import FormMultiTextInput from '../forms/FormMultiTextInput';
import FormRangeWithInfo from '../forms/FormRangeWithInfo';


const DynamicFormBuilder = ({ step, onNext, showInfo = false, info, showDetermineBodyType = false }) => {

  switch (step.type) {
    case 'square_radiobox_with_image':
      return (<>
        <FormSqurareRadioBoxWithImages step={step} onSelected={onNext} showDetermineBodyType={showDetermineBodyType} />
      </>);
    case 'range_with_condition':
      return (
        <>
          <FormRangeWithCondition step={step} onSelected={onNext} />
        </>
      );
    case 'square_checkbox_with_image':
      return (<>
        <FormSquareImageCheckboxWithImages step={step} onSelected={onNext} />
      </>);
    case 'square_text_radiobox':
      return (<>
        <FormRadioWithText step={step} onSelected={onNext} showInfo={showInfo} info={info} />
      </>);

    case 'form_radio_with_text':
      return (<>
        <FormRadioWithText step={step} onSelected={onNext} showInfo={showInfo} />
      </>);
    case 'single_input_field':
      return (<>
        <FormSingleTextInput step={step} onSubmit={onNext} />
      </>);
    case 'multi_input_field':
      return (<>
        <FormMultiTextInput step={step} onSubmit={onNext} info={info} showInfo={showInfo} />
      </>);
    case 'square_checkbox':
      return (<>
        <FormSquareCheckBox step={step} onSubmit={onNext} info={info} showInfo={showInfo} />
      </>);
    case 'range_with_info':
      return (<>
        <FormRangeWithInfo step={step} onSubmit={onNext} />
      </>);
    default:
      return (
        <div>
        </div>
      );
  }

}

DynamicFormBuilder.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        imageUrl: PropTypes.string,
        checked: PropTypes.bool
      })
    ),
  }),
  info: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }),
  showDetermineBodyType: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  next_route: PropTypes.string,
  showInfo: PropTypes.bool,
};


export default DynamicFormBuilder;