import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import RightArrowButton from '../../layout/button/RightArrowButton';
import BottomArrowButton from '../../layout/button/BottomArrowButton';
import '../../assets/styles/StepName.css'
import SmallProgressBarComponent from '../../components/SmallPrograssBarComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBarComponent from '../../layout/ProgressBarLayout';
import { postData } from '../../reducers/uploadDataSlice'
import { useDispatch, useSelector } from 'react-redux';


const StepDateOfBirth = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [error, setError] = useState('');

    const { data, selecteddata } = useSelector((state) => state.upload);


    const calculateAge = (dobString) => {
        const dob = new Date(dobString);
        const today = new Date();

        let age = today.getFullYear() - dob.getFullYear();


        if (
            today.getMonth() < dob.getMonth() ||
            (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())
        ) {
            age--;
        }

        return age;
    };


    const handleInputChange = (event) => {

        const inputDate = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        setError("");

        if (inputDate.length <= 2) {
            setDateOfBirth(inputDate);
        } else if (inputDate.length <= 4) {
            const date = inputDate.substring(0, 2) + '/' + inputDate.substring(2);
            setDateOfBirth(date);
        } else {
            const date = inputDate.substring(0, 2) + '/' + inputDate.substring(2, 4) + '/' + inputDate.substring(4, 8);
            setDateOfBirth(date);
        }

    };


    const onNext = async () => {
        const age = calculateAge(dateOfBirth);
        if (age <= 18) {
            setError('You must be 18 or over to use our product')
        } else {
            try {
                const result = await dispatch(
                    postData({
                        key:"date_of_birth",
                        value: { date_of_birth: dateOfBirth },
                        data: { date_of_birth: dateOfBirth },
                        docId: data && data.docId !== undefined ? data.docId : "",
                    })
                );
                if (result.success) {
                    navigate("/step-email");
                } else {
                    console.error('Post data failed.');
                }
            } catch (error) {
                console.error('Error during postData:', error);
            }
        }
    };


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);


    useEffect(() => {
        if (selecteddata && selecteddata.date_of_birth) {
            setDateOfBirth(selecteddata.date_of_birth.date_of_birth || '');
        }
    }, [selecteddata.date_of_birth]);


    return (
        <div>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "transparent" }}>
                <Header />
                <ProgressBarComponent showBar={false} />
            </div>
            <div className="klBxhW" style={{ paddingTop: '120px', backgroundColor: "black", paddingBottom: '50px' }}>
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            <div className="cqjfzM jCSgRm">
                                <span className="sc-66945658-1 ijvsZU">✅</span><p className="sc-d6bbf5f0-4 sc-66945658-3 cDpolK">Your workout and meal plan are almost ready!</p>
                            </div>
                            <h1 className="bpzQjr bsCFUQ">Let's create your account</h1>
                            <div className="csXCYg glYDxw">
                                <div className="kkiseL">
                                    <span className="eABpMJ">
                                        <SmallProgressBarComponent index={2} total={3} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sc-eb5bc1e6-2 gppALw step-component-wrapper step-component-wrapper_1">
                        <label className="sc-ce1ca726-0 ieunKf">
                            <span className="sc-ce1ca726-1 efFBMo">What's your date of birth?</span>
                            <span className="sc-ce1ca726-2 dlDjfT">
                                <input
                                    type="text"
                                    date="date"
                                    pattern="(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\d{4}"
                                    enterKeyHint="next"
                                    className="fPLSVc"
                                    maxLength={10}
                                    value={dateOfBirth}
                                    placeholder="DD/MM/YYYY" // Add the placeholder attribute
                                    onChange={handleInputChange} />
                            </span>
                        </label>
                        {error && <p style={{ color: 'red', alignSelf: 'start', fontSize: '12px' }}>{error}</p>}
                    </div>
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'end', pointerEvents: dateOfBirth === '' ? "none" : "auto", opacity: dateOfBirth === "" ? 0.4 : 1, marginTop: "20px" }}>
                        <div className="container-arrow">
                            {screenWidth >= 1023 && (<RightArrowButton label={"Continue"} onClick={onNext} width="200px" />)}
                        </div>
                    </div>
                </div>
                {screenWidth < 1023 &&
                    <div style={{ pointerEvents: dateOfBirth === '' ? "none" : "auto", opacity: dateOfBirth === "" ? 0.4 : 1 }}>
                        <BottomArrowButton onClick={onNext} label="Continue" />
                    </div>}

            </div>

        </div>
    );

}


export default StepDateOfBirth;