import React from 'react';
import PropTypes from 'prop-types';
import './StepGoalItemCard.css'; // Import your CSS file for styling

const StepGoalItemCard = ({ imageSrc, title, bottomText, onItemSelected }) => {

  const handleClick = (event) => {
    event.preventDefault();
    onItemSelected(title)
  }
  return (
    <div className="hjicdk bSTudT" onClick={handleClick}>
      <img className="item-image" src={imageSrc} alt={title} />
      <div className="cEmxiN gtVJbr">{bottomText}</div>
    </div>
  );
};

StepGoalItemCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};

export default StepGoalItemCard;
