import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import { useNavigate, useLocation } from 'react-router-dom';
import RightArrowButton from '../../layout/button/RightArrowButton';
import '../../assets/styles/FormRangeWithCondition.css';
import BottomArrowButton from "../../layout/button/BottomArrowButton";
import svg from "../../assets/images/collestrol.svg";


const StepLevelOfHormones = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const currentStepIndex = location.state ? location.state.currentStepIndex : 0;

    const onClicked = () => {
        navigate('/step-time', { state: { currentStepIndex: currentStepIndex } });
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);
    

    return (
        <div className="grid-bg1">
            <div style={{ position: "fixed", width: '100%', backgroundColor: "black" }}>
                <Header />
            </div>
            <div style={{ paddingTop: '120px', backgroundColor: 'black' }}>
                <div className="klBxhW">
                    <div className="cKVRhv">
                        <div className="gppALw">
                            <div className="osnNu">
                                <h1 className="bpzQjr bsCFUQ">Level of Cortisol & Testosterone</h1>
                            </div>
                        </div>
                        <div style={{ width: "100%", maxWidth: '424px' }}>
                            <img
                                src={svg}
                                // replace with the path to your SVG file
                                alt="Description of the SVG image"
                                style={{ width: "100%", maxWidth: '424px', height: '250px' }}  // adjust the styling as needed
                            />
                        </div>
                        <div className="bmQrBr" style={{ color: "white", textAlign: 'start' }}><h3 style={{ fontSize: "28px" }}>Our lifestyle programs are crafted to ensure a balanced testosterone/cortisol ratio. </h3>
                            <div style={{ fontSize: "18px" }}>
                                <p> Both cortisol and testosterone levels increase linearly in response to physical exercise. </p>
                                <p>However, it is of vital importance to consider them in order to reach a specific threshold of exercise intensity.</p>
                                <p>Otherwise, physical stressors can result in excessive cortisol secretion, which decreases the production of testosterone.</p>
                                <p>Overtraining and susceptibility to certain diseases and disorders could be the result of an extreme stressor and a low level of testosterone. </p>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', marginTop: "30px" }}>
                            <div className="container-arrow">
                                {screenWidth >= 1023 && (<RightArrowButton
                                    label={"Got it"}
                                    onClick={onClicked}
                                    width="170px"
                                />)}
                            </div>
                        </div>
                    </div>
                    {screenWidth < 1023 &&
                        <BottomArrowButton onClick={onClicked} label="Got it" />
                    }
                </div>
            </div>
        </div>
    );
}

export default StepLevelOfHormones;