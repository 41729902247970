import React, { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CommitmentDialog from "../../components/dialog/CommitmentDialog";
import Header from "../../layout/header/Header";
import { useDispatch, useSelector } from 'react-redux';
import { getResults } from '../../reducers/getResultSlice';
import { getPlans } from "../../reducers/getPlanSlice";
import { useNavigate, useLocation } from 'react-router-dom';


const StepCommitment = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    const [percentage, setPercentage] = useState(0);
    const [isPaused, setPaused] = useState(false);
    const [title, setTitle] = useState("Analysing data...");

    const uploadData = useSelector((state) => state.upload.data);


    const resumeProgress = () => {
        setTimeout(() => {
            navigate('/final-v2',{replace:true});
        }, 2000);
    }


    useEffect(() => {
        const interval = setInterval(() => {
            setPercentage((prevPercentage) => {
                if (prevPercentage === 40) {
                    setPaused(true);
                    setTitle("Building goal achivement path");
                } else if (prevPercentage === 20) {
                    setTitle("Measuring body profile");
                } else if (prevPercentage === 60) {
                    setTitle("Suggesting workout plan");
                } else if (prevPercentage === 80) {
                    setTitle("Meal Plan");
                } else if (prevPercentage === 99) {
                    setPaused(true);
                    setTitle("Your personalized workout plan is ready");
                    resumeProgress();
                }

                if (!isPaused) {
                    // Resume updating the progress when not paused
                    const newPercentage = prevPercentage + 1;

                    if (newPercentage >= 100) {
                        clearInterval(interval);
                    }

                    return newPercentage;
                }
                return prevPercentage;

            });
        }, 50);

        return () => clearInterval(interval);
    }, [isPaused]);

    useEffect(() => {
        dispatch(getPlans());
    }, []);

    useEffect(() => {
        dispatch(getResults(uploadData && uploadData.docId !== undefined ? uploadData.docId : ""));
    }, []);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);


    const handleResume = () => {
        setPaused(false);
    };

    return (

        <div className="grid-bg1">
            <div style={{ position: "fixed", width: '100%', height: '100%', backgroundColor: "black" }}>
                <Header />
                <div style={{ justifyContent: 'center', width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: "200px", height: '200px', alignSelf: 'center' }}>
                        <CircularProgressbar
                            value={percentage}
                            strokeWidth={6}
                            text={`${percentage}%`}
                            styles={{
                                root: {},
                                path: {
                                    stroke: "#12B2B3",
                                    strokeLinecap: 'butt',
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    transform: 'none',
                                    transformOrigin: 'center center',
                                },
                                trail: {
                                    stroke: '#8D8D8D',
                                    strokeLinecap: 'butt',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                },
                                text: {
                                    fill: 'white',
                                    fontSize: '24px',
                                    backgroundColor: "#12B2B3"
                                },
                                background: {
                                    fill: '#12B2B3',
                                },
                            }}
                        />
                    </div>
                    <p style={{ color: "white", fontSize: '20px', alignSelf: 'center', marginTop: '20px' }}>{title}</p>
                    {isPaused && percentage === 41 && <CommitmentDialog onResume={handleResume} />}
                </div>
            </div>
        </div>

    );
};

export default StepCommitment;
