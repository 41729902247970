
import { createSlice } from '@reduxjs/toolkit';
import NetworkService from '../services/network/networkservice';

const baseURL = 'https://us-central1-healthhavendev.cloudfunctions.net'; // Your base URL


const networkservice = new NetworkService(baseURL);

const resultSlice = createSlice({
    name: 'results',
    initialState: {
        results: {},
        loading: false,
        error: null,
    },
    reducers: {
        fetchResultStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchResultSuccess(state, action) {
            state.loading = false;
            state.results = action.payload.results;
            state.error = null;
        },
        fetchResultFailure(state, action) {
            state.loading = false;
            state.error = action.payload.erorr;
        },
    },
});

export const { fetchResultStart, fetchResultSuccess, fetchResultFailure } = resultSlice.actions;

// Thunk action for making the API call
export const getResults = (docId) => async (dispatch) => {
    try {
        dispatch(fetchResultStart());
        const result = await networkservice.getResults(docId);
        dispatch(fetchResultSuccess(result));
    } catch (error) {
        dispatch(fetchResultFailure(error.message));
    }
};


export const getClientSecret = async (param) => {
    const clientSecret = await networkservice.getClientSecret(param);
    return clientSecret;
};

export const calculateBMI = (weight, height) => {

    if (weight.measure === 'kg') {
        var heightM = height / 100;
        // Calculate BMI using the formula: weight (kg) / (height (m))^2
        var bmi = weight.current_weight / (heightM * heightM);
        return bmi;
    } else {
        var heightIn = height / 2.54;
        // Calculate BMI using the formula: weight (kg) / (height (m))^2
        var bmi1 = (weight.current_weight / (heightIn * heightIn)) * 703;
        return bmi1;
    }
}


export const calculateRecommendedDailyCalorieIntake = (results) => {

    const bmr = (10 * parseFloat(results.weight.target_weight)) + (6.25 * parseFloat(results.height.height)) - (5 * calculateAGE(results.date_of_birth))+5;

    var workout_frequency = 1.2;
    if (results.workout_frequency.id === 1) {
        workout_frequency = 1.375;
    } else if (results.workout_frequency.id === 2) {
        workout_frequency = 1.55;
    } else if (results.workout_frequency.id === 3) {
        workout_frequency = 1.725;
    } else if (results.workout_frequency.id === 4) {
        workout_frequency = 1.9;
    }
    const tdee = bmr * workout_frequency;

    if(results.body_goal.id === 1){
        return tdee - 500;
    }else if(results.body_goal.id === 2){
        return tdee + 250;
    }else if(results.body_goal.id === 3){
        return tdee-500+640;
    }
    return tdee;
}

export const getBodyFatRange = (results) => {
    if(results.target_body.title.trim() === 'Slim Body'){
        return "15-17 %";
    }else if (results.target_body.title.trim() === 'Slim, Shredded body'){
        return "12-14 %";
    }else if (results.target_body.title.trim() === 'Athelete'){
        return "8-10 %";
    }else if (results.target_body.title.trim() === 'Hero'){
        return "8-10 %";
    }else if (results.target_body.title.trim() === 'Bodybuilder'){
        return "6-8 %";
    }else if (results.target_body.title.trim() === 'Beach Body'){
        return "10-12 %";
    }else if (results.target_body.title.trim() === 'Workout body'){
        return "10-12 %";
    }else if (results.target_body.title.trim() === 'Crossfit body'){
        return "10-12 %";
    }else{
        return "15-17 %";
    }
}

export const calculateAGE = (date) => {
    const dob = new Date(date);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    return age;
}

export const calculateWaterIntakeInOunces = (weight) => {
    if (weight.measure === 'kg') {
        const minWaterIntake = weight.target_weight * 0.033; 
        return minWaterIntake.toFixed(1)+" l";
    } else {
        const weightInPounds = weight.target_weight;
        const minWaterIntake = weightInPounds * 0.5; // 0.5 ounce per pound
        return minWaterIntake.toFixed(2)+" oz";
    }
}

export default resultSlice.reducer;
