import React, { useEffect, useState } from 'react';
import '../../assets/styles/FormSingleTextInput.css'
import RightArrowButton from '../button/RightArrowButton';
import BottomArrowButton from '../button/BottomArrowButton';
import PropTypes from 'prop-types';
import InfoComponent from '../../components/InfoComponent';


const FormMultiTextInput = ({ step, onSubmit, showInfo = false, info }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [measureSystem, setMeasureSystem] = useState('IMPERIAL');
    const [currentWeight, setCurrentWeight] = useState("");
    const [targetWeight, setTargetWeight] = useState("");
    const [infoShown, setInfoShown] = useState(false);

    const [currentWeightError, setCurrentWeightError] = useState('');
    const [targetWeightError, setTargetWeightError] = useState('');

    const onClicked = (event) => {

        event.preventDefault();

        if (currentWeight === "") {
            setCurrentWeightError('Current weight is required');
        } else if (targetWeight === "") {
            setCurrentWeightError("");
            setTargetWeightError('Target weight is required');
        } else {
            const cWeight = parseInt(currentWeight, 10);
            const tWeight = parseInt(targetWeight, 10);
            if (measureSystem === 'IMPERIAL') {
                if (cWeight < 77) {
                    setCurrentWeightError('Weight must be greater than or equal to 77 lb');
                }

                if (cWeight > 400) {
                    setCurrentWeightError('Weight must be less than or equal to 400 lb');
                }

                if (tWeight < 77) {
                    setTargetWeightError('Weight must be greater than or equal to 77 lb');
                }

                if (tWeight > 400) {
                    setTargetWeightError('Weight must be less than or equal to 400 lb');
                }


                if (cWeight >= 77 && tWeight >= 77 && cWeight <= 400 && tWeight <= 400) {
                    setCurrentWeightError("");
                    setTargetWeightError("");
                    if (cWeight === tWeight) {
                        if (showInfo) {
                            if (infoShown) {
                                onSubmit({
                                    current_weight: currentWeight,
                                    target_weight: targetWeight,
                                    measure: measureSystem === 'IMPERIAL' ? "lb" : "kg"
                                });
                            } else {
                                setInfoShown(true);
                            }
                        } else {
                            onSubmit({
                                current_weight: currentWeight,
                                target_weight: targetWeight,
                                measure: measureSystem === 'IMPERIAL' ? "lb" : "kg"
                            });
                        }

                    } else {
                        onSubmit({
                            current_weight: currentWeight,
                            target_weight: targetWeight,
                            measure: measureSystem === 'IMPERIAL' ? "lb" : "kg"
                        });
                    }
                }
            } else {
                if (cWeight < 35) {
                    setCurrentWeightError('Weight must be greater than or equal to 35 kg');
                }
                if (cWeight > 200) {
                    setCurrentWeightError('Weight must be less than or equal to 200 kg');
                }

                if (tWeight < 35) {
                    setTargetWeightError('Weight must be greater than or equal to 35 kg');
                }
                if (tWeight > 200) {
                    setTargetWeightError('Weight must be less than or equal to 200 kg');
                }

                if (cWeight >= 35 && tWeight >= 35 && cWeight <= 200 && tWeight <= 200 ) {
                    setCurrentWeightError("");
                    setTargetWeightError("");
                    if (cWeight === tWeight) {
                        if (showInfo) {
                            if (infoShown) {
                                onSubmit({
                                    current_weight: currentWeight,
                                    target_weight: targetWeight,
                                    measure: measureSystem === 'IMPERIAL' ? "lb" : "kg"
                                });
                            } else {
                                setInfoShown(true);
                                scrollToDivById("bottom_view");
                            }
                        } else {
                            onSubmit({
                                current_weight: currentWeight,
                                target_weight: targetWeight,
                                measure: measureSystem === 'IMPERIAL' ? "lb" : "kg"
                            });
                        }

                    } else {
                        onSubmit({
                            current_weight: currentWeight,
                            target_weight: targetWeight,
                            measure: measureSystem === 'IMPERIAL' ? "lb" : "kg"
                        });
                    }
                }
            }
        }
    }


    function scrollToDivById(id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }

    const handleMeasureSystemChange = (event) => {
        setCurrentWeightError('');
        setTargetWeightError('');
        setMeasureSystem(event.target.value);
    };

    const handleCurrentWeightChange = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '');
        setCurrentWeight(event.target.value);
        // Validate current weight
        if (!inputValue) {
        } else {
            setCurrentWeightError('');
        }
        event.target.value = `${inputValue}`;

    };


    const handleTargetWeightChange = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '');
        setTargetWeight(event.target.value);
        // Validate target weight
        if (!inputValue) {
        } else {
            setTargetWeightError('');
        }
        event.target.value = `${inputValue}`;
    };



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        if(step && step.currentWeight && step.currentWeight.measure){
            if(step.currentWeight.measure === "lb"){
                setMeasureSystem("IMPERIAL");
            }else{
                setMeasureSystem("METRIC");
            }
        }

        if(step && step.currentWeight && step.currentWeight.current_weight){
            setCurrentWeight(step.currentWeight.current_weight || '');
        }
         if(step && step.currentWeight && step.currentWeight.target_weight){
                setTargetWeight(step.currentWeight.target_weight || '');
            }
    }, [step.currentWeight]);



    return (
        <form noValidate="" className="erPtxw" data-gtm-form-interact-id="0">
            <div className="klBxhW">
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>
                    </div>
                    <div className="gppALw" style={{ color: "white" }}>
                        <div className="cUiCiF">
                            <span className="kWqtoM">Units</span>
                            <div className="kxtXWv">
                                <label id="imperial-radio" className={measureSystem === 'IMPERIAL' ? 'kHAZvO jcRkmu czwczU' : 'hjicdk3 jcRkmu czwczU'} tabIndex="0">
                                    <input
                                        name="measureSystem"
                                        type="radio"
                                        className="jQNPoG"
                                        value="IMPERIAL"
                                        checked={measureSystem === 'IMPERIAL'}
                                        onChange={handleMeasureSystemChange}

                                    />
                                    <span className="eBRzvd">lb</span>
                                </label>
                                <label id="metric-radio" className={measureSystem === 'METRIC' ? 'kHAZvO jcRkmu czwczU' : 'hjicdk3 jcRkmu czwczU'} tabIndex="0">
                                    <input
                                        name="measureSystem"
                                        type="radio"
                                        className="jQNPoG"
                                        value="METRIC"
                                        checked={measureSystem === 'METRIC'}
                                        onChange={handleMeasureSystemChange}
                                    />
                                    <span className="eBRzvd">kg</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="gppALw">
                        <label className="ieunKf">
                            <span className="efFBMo">
                                Current Weight (
                                {measureSystem === 'IMPERIAL' ? 'lb' : 'kg'}
                                )
                            </span>
                            <span className="dlDjfT">
                                <input
                                    type="text"
                                    name="currentWeightImperial"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="fPLSVc"
                                    autoComplete="off"
                                    value={currentWeight}
                                    onChange={handleCurrentWeightChange}
                                    placeholder={measureSystem === 'IMPERIAL' ? '__ lb' : '__ kg'}
                                />
                            </span>
                        </label>
                        {currentWeightError && <p style={{ color: 'red', alignSelf: 'start', fontSize: '14px' }}>{currentWeightError}</p>}

                        <label className="ieunKf" style={{ marginTop: "30px" }}>
                            <span className="efFBMo">
                                Target Weight (
                                {measureSystem === 'IMPERIAL' ? 'lb' : 'kg'}
                                )
                            </span>
                            <span className="dlDjfT">
                                <input
                                    type="text"
                                    name="currentWeightImperial"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="fPLSVc"
                                    autoComplete="off"
                                    value={targetWeight}
                                    onChange={handleTargetWeightChange}
                                    placeholder={measureSystem === 'IMPERIAL' ? ' __ lb' : '__ kg'}
                                />
                            </span>
                        </label>
                        {targetWeightError && <p style={{ color: 'red', alignSelf: 'start', fontSize: '14px' }}>{targetWeightError}</p>}
                    </div>
                    {infoShown &&
                        <div style={{ marginTop: '20px' }}>
                            <InfoComponent info={info} />
                        </div>
                    }
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', pointerEvents: (currentWeight === '' || targetWeight === '') ? "none" : "auto", opacity: (currentWeight === '' || targetWeight === '') ? 0.4 : 1, marginTop: "20px" }}>
                        {screenWidth >= 1023 && (<div className="container-arrow">
                            <RightArrowButton label={"Continue"} onClick={onClicked} width="200px" />
                        </div>)}
                    </div>
                </div>
                <div id="bottom_view" style={{marginBottom:'150px'}}></div>
                {screenWidth < 1023 &&
                    <div style={{ pointerEvents: (currentWeight === '' || targetWeight === '') ? "none" : "auto", opacity: (currentWeight === '' || targetWeight === '') ? 0.4 : 1 }}>
                        <BottomArrowButton onClick={onClicked} label="Continue" />
                    </div>
                }
            </div>
        </form>
    );
}

FormMultiTextInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        type: PropTypes.string.isRequired,
    }).isRequired,
    info: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }),
    showInfo: PropTypes.bool
};

export default FormMultiTextInput;