import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/MultiSelectCardItemCheckbox.css'

const MultiSelectCardItemCheckbox = ({ index, option, onItemSelected }) => {



    const onChange = (value) => {
    };

    const onItemClicked = (event) => {
        event.preventDefault();
        onItemSelected(index, !option.checked, option);
    };

    return (
        <label
            className={`hjicdk12 hhbXPR dwlkSV ${option.checked ? 'isChecked' : ''}`}
            tabIndex={index.toString()}
            style={{ height: '48px' }}
            onClick={onItemClicked}>
            <input name="goal" className="jQNPoG" />
            <div style={{ textAlign: 'start' }}>
                <span className="jxGXvKN">
                    <span className="checkbox-title">
                        <input
                            type="checkbox"
                            checked={option.checked}
                            onChange={onChange}
                        />
                        <span className="ghbokT1 hXbiJA">{option.title}</span>
                    </span>
                </span>
                {(!option.subtitle && !(option.subtitle.trim() === '')) ? null : (
                    <p className="dezAzo eGxKKN">{option.subtitle}</p>
                )}
            </div>
        </label>
    );
};


MultiSelectCardItemCheckbox.propTypes = {
    index: PropTypes.number.isRequired, // Adjust the PropTypes accordingly
    option: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        imageUrl: PropTypes.string,
        checked: PropTypes.bool
    }),
    onItemSelected: PropTypes.func.isRequired,
};

export default MultiSelectCardItemCheckbox;
