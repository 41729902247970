import React from "react";
import '../../assets/styles/SquareRadioWithTextCardItem.css'
import PropTypes from 'prop-types';


const SquareRadioWithTextCardItem = ({ index, option, onItemSelected, checked = false }) => {


    const handleRadioChange = () => {
        onItemSelected(index, !checked.option, option);
    };

    return (
        <label className={`hjicdk2 gxMAzA dArWKI ${checked ? "isChecked" : ""}`} tabIndex={index} style={{ display: 'flex', alignItems: 'self-start' }}>
            <input name="diets" type="radio"
                value={option.title} checked={checked}
                onChange={handleRadioChange}
                style={{ marginTop: '5px' }}
            />
            <div className="jvNbPD">
                <span className="fnzkYY">{option.title}</span>
                {(!option.subtitle || option.subtitle.trim() === '') ? <></> : (
                    <span className="dezAzo dAwHbU">{option.subtitle}</span>
                )}
            </div>
        </label>
    );
}


SquareRadioWithTextCardItem.propTypes = {
    index: PropTypes.number.isRequired, // Adjust the PropTypes accordingly
    option: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        imageUrl: PropTypes.string,
    }),
    onItemSelected: PropTypes.func.isRequired,
};

export default SquareRadioWithTextCardItem;