import { React, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/styles/ChooseAProgram.css'
import Header from "../../layout/header/Header";
import StepGoalItemCard from './carditem/StepGoalItemCard'
import eighteenToTwentyNine from "../../assets/images/18-29v2.webp"
import thirtyToThirtyNine from "../../assets/images/30-39v2.webp"
import fourtyToFourtyNine from "../../assets/images/40-49v2.webp"
import fiftyAbove from "../../assets/images/50+v2.webp"
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../../reducers/getStepsSlice';
import { updateProgress } from '../../reducers/progressSlice';
import { postData } from "../../reducers/uploadDataSlice";


const ChooseAProgram = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [agreeWithPolicy, setAgreeWithPolicy] = useState(false);


  const [showErrorBox, setShowErrorBox] = useState(false);

  const uploadData = useSelector((state) => state.upload.data);

  const handleAgreeWithPolicyChange = () => {
    setAgreeWithPolicy(!agreeWithPolicy);
    setShowErrorBox(!(!agreeWithPolicy))
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };


  const handleClick = async (value) => {
    if (agreeWithPolicy) {
      try {
        const result = await dispatch(
          postData({
            data: { age: value },
            docId: uploadData && uploadData.docId !== undefined ? uploadData.docId : "",
          })
        );

        if (result.success) {
          navigate('/step-body-goal');
        } else {
          console.error('Post data failed.');
        }
      } catch (error) {
        console.error('Error during postData:', error);
      }
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }); 
      setShowErrorBox(true);
    }
  };

  useEffect(() => {
    dispatch(fetchItems());
  }, []);

  useEffect(() => {
    dispatch(updateProgress({ progress: 0, total: 27, increament: 0 }));
    return () => {
    };
  }, []);

  return (
    <div className="grid-bg2">
      <div style={{ position: "fixed", width: "100%" }}>
        <Header />
      </div>
      <div className="kXlymQ">
        <div className="eNykmv">

          <h1 className="lfFgMS iWNVlm">
            BUILD YOUR PERFECT BODY
          </h1>
          <p className="dezAzo GnVCv">According to your age and BMI</p>
          <div className="iFeSRZ">
            <StepGoalItemCard imageSrc={eighteenToTwentyNine} title="18to29" bottomText="Age:18-29" onItemSelected={handleClick} />
            <StepGoalItemCard imageSrc={thirtyToThirtyNine} title="30to39" bottomText="Age:30-39" onItemSelected={handleClick} />
            <StepGoalItemCard imageSrc={fourtyToFourtyNine} title="40to49" bottomText="Age:40-49" onItemSelected={handleClick} />
            <StepGoalItemCard imageSrc={fiftyAbove} title="50+" bottomText="Age:50+" onItemSelected={handleClick} />
          </div>
          <div className="ffhNwi SXRCO" >
            <label id="selector-wrapper" className="bxKVqE jkXXc" tabIndex="0">
              <input
                name="agreeWithPolicy"
                type="checkbox"
                className="custom-checkbox-input"
                checked={agreeWithPolicy}
                onChange={handleAgreeWithPolicyChange}
                value=""
              />
            <span className="eIUMfR" onClick={handleAgreeWithPolicyChange}>By continuing, I agree with <Link style={{ color: "#B3B3B3" }} to="/terms-and-conditions" target='_blank'>Terms and Conditions</Link>, <Link style={{ color: "#B3B3B3" }} to="/refund-policy" target='_blank'>Refund policy</Link>, <Link style={{ color: "#B3B3B3" }} to="/cookie-policy" target='_blank'>Cookie policy</Link></span></label>
            {showErrorBox && (
              <div className="dgKhQM">
                <p className="dezAzo iRCzkM">To continue, please accept our terms and policies</p>
              </div>
            )}
            <p className="bVNcHg" style={{marginBottom:'24px'}} id="privacy-policy">We recommend that you consult with your physician before beginning any exercise program</p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ChooseAProgram;

