import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import '../assets/styles/RightNavDrawer.css';
import close from '../assets/images/close.svg';
import { Link } from 'react-router-dom';

const RightDrawerNav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon style={{ color: 'white' }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div className="klVzKw">
          <div className="hSXcLQ">
            <button title="Close nav" type="button" onClick={toggleDrawer(false)} style={{ backgroundColor: "transparent", border: 'none' }}>
              <img src={close} alt='close' style={{ height: "20px", width: "20px", backgroundColor: "transparent", color: "white" }} />
            </button>
          </div>
          <ul className="dbjdqK">
            <li className="fQvCds">
              <button type="button" className="cDpolK" style={{ backgroundColor: "transparent", border: 'none' }}> 
                <Link style={{color:'white', padding: '0px 20px', textDecoration: 'none'}} target='_blank' to="/terms-and-conditions" onClick={handleCloseDrawer}>Terms &amp; Conditions</Link>
              </button>
            </li>
            <li className="grKXJU">
              <a href="/support" title="Support" rel="noopener noreferrer" className="cDpolK" style={{ textDecoration: 'none' }} onClick={handleCloseDrawer}>Support</a>
            </li>
            <li className="grKXJU">
              <a title="Reviews" href="/reviews" className="cDpolK" style={{ textDecoration: 'none' }} onClick={handleCloseDrawer}>Reviews</a>
            </li>
            <li className="grKXJU">
              <a title="About Us" href="/aboutus" className="cDpolK" style={{ textDecoration: 'none' }} onClick={handleCloseDrawer}>About Us</a>
            </li>
          </ul>
        </div>
      </Drawer>
    </div>
  );
};

export default RightDrawerNav;
