import '../App.css';
import ChooseAProgram from '../pages/chooseprogram/ChooseProgram';
import Dashboard from '../pages/dashboard/Dashboard';
import '../App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import StepBodyGoals from '../pages/steps/StepBodyGoal';
import StepTargetBody from '../pages/steps/StepTargetBody';
import StepBodyTypes from '../pages/steps/StepBodyType';
import StepProblemAreas from '../pages/steps/StepProblemAreas';
import StepLevelOfFat from '../pages/steps/StepLevelOfFat';
import StepNutrition from '../pages/steps/StepNutrition';
import StepDiets from '../pages/steps/StepDiets';
import StepHowOftenSugar from '../pages/steps/StepHowOfterSugar';
import StepWater from '../pages/steps/StepWater';
import StepMeasures from '../pages/steps/StepMeasures';
import StepTargetWeight from '../pages/steps/StepTargetWeight';
import StepLevelOfFitness from '../pages/steps/StepLevelOfFitness';
import StepWorkouts from '../pages/steps/StepWorkouts';
import StepWorkoutIssues from '../pages/steps/StepWorkoutIssues';
import StepGiveUp from '../pages/steps/StepGiveUp';
import StepAdditionalGoals from '../pages/steps/StepAdditionalGoals';
import StepAttention from '../pages/steps/StepAttention';
import StepPushUps from '../pages/steps/StepPushUps';
import StepPullUpos from '../pages/steps/StepPullUps';
import StepWorkoutPlace from '../pages/steps/StepWorkoutPlace';
import StepEquipments from '../pages/steps/StepEquipment';
import StepWorkoutFrequency from '../pages/steps/StepWorkoutsFrequency';
import StepWorkoutDuration from '../pages/steps/StepWorkoutDuration';
import StepMealTime from '../pages/steps/StepMealTime';
import StepLevelOfHormones from '../pages/steps/StepLevelOfHewrmons';
import StepProducts from '../pages/steps/StepProducts';
import StepChallange from '../pages/steps/StepChallange';
import StepName from '../pages/steps/StepName';
import StepDateOfBirth from '../pages/steps/StepDOB';
import StepEmail from '../pages/steps/StepEmail';
import StepCommitment from '../pages/steps/StepCommitment';
import FinalResult from '../pages/steps/FinalResult';
import PaymentSuccessMessage from '../pages/payment/PaymentSuccessMessage';
import TermsAndContiton from '../pages/termscondition/TermsAndCondition';
import CookiePolicy from '../pages/cookie_policy/CookiePolicy';
import RefundPolicy from '../pages/refundpolicy/RefundPolicy';
import AboutUs from '../pages/aboutus/AboutUs';
import Reviews from '../pages/review/Reviews';
import Support from '../pages/support/Support';
import {useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';



function App() {
  

  const { data} = useSelector((state) => state.upload);

  const isAuthenticated = Boolean(data?.docId);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/step-goal" element={<ChooseAProgram />} />
        <Route path="/step-body-goal" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepBodyGoals /></ProtectedRoute>} />
        <Route path="/step-target-body" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepTargetBody /></ProtectedRoute>} />
        <Route path="/step-bodytype" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepBodyTypes /></ProtectedRoute>} />
        <Route path="/step-level-of-fat" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepLevelOfFat /></ProtectedRoute>} />
        <Route path="/step-problem-areas" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepProblemAreas /></ProtectedRoute>} />
        <Route path="/step-nutrition" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepNutrition /></ProtectedRoute>} />
        <Route path="/step-diets" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepDiets /></ProtectedRoute>} />
        <Route path="/step-how-often-sugar" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepHowOftenSugar /></ProtectedRoute>} />
        <Route path="/step-water" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepWater /></ProtectedRoute>} />
        <Route path="/step-measures" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepMeasures /></ProtectedRoute>} />
        <Route path="/step-target-weight" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepTargetWeight /></ProtectedRoute>} />
        <Route path="/step-level-of-fitness" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepLevelOfFitness /></ProtectedRoute>} />
        <Route path="/step-workouts" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepWorkouts /></ProtectedRoute>} />
        <Route path="/step-workout-issues" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepWorkoutIssues /></ProtectedRoute>} />
        <Route path="/step-give-up" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepGiveUp /></ProtectedRoute>} />
        <Route path="/step-additional-goals" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepAdditionalGoals /></ProtectedRoute>} />
        <Route path="/step-attention" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepAttention /></ProtectedRoute>} />
        <Route path="/step-push-ups" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepPushUps /></ProtectedRoute>} />
        <Route path="/step-pull-ups" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepPullUpos /></ProtectedRoute>} />
        <Route path="/step-workout-place" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepWorkoutPlace /></ProtectedRoute>} />
        <Route path="/step-equipment" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepEquipments /></ProtectedRoute>} />
        <Route path="/step-workout-frequency" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepWorkoutFrequency /></ProtectedRoute>} />
        <Route path="/step-workout-duration" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepWorkoutDuration /></ProtectedRoute>} />
        <Route path="/step-levels-of-hormones" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepLevelOfHormones /></ProtectedRoute>} />
        <Route path="/step-time" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepMealTime /></ProtectedRoute>} />
        <Route path="/step-products" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepProducts /></ProtectedRoute>} />
        <Route path="/step-challenge" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepChallange /></ProtectedRoute>} />
        <Route path="/step-name" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepName /></ProtectedRoute>} />
        <Route path="/step-date-of-birth" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepDateOfBirth /></ProtectedRoute>} />
        <Route path="/step-email" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepEmail /></ProtectedRoute>} />
        <Route path="/step-commitment" element={<ProtectedRoute isAuthenticated={isAuthenticated}><StepCommitment /></ProtectedRoute>} />
        <Route path="/final-v2" element={<ProtectedRoute isAuthenticated={isAuthenticated}><FinalResult /></ProtectedRoute>} />
        

        <Route path="/complete" element={<PaymentSuccessMessage />} />
        <Route path="/terms-and-conditions" element={<TermsAndContiton />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/support" element={<Support />} />
        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
    </div>
  );
}

export default App;
