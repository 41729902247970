import React, { useState, useEffect } from "react";
import '../../assets/styles/FormRangeWithCondition.css';
import RightArrowButton from '../../layout/button/RightArrowButton'
import PropTypes from 'prop-types';
import BottomArrowButton from "../button/BottomArrowButton";


const FormRangeWithCondition = ({ step, onSelected }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [bodyFat, setBodyFat] = useState(step.selectedIndex);
    const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: -50 });
    const [iconSrc, setIconSrc] = useState('');

    const bodyfatRangePercentage = [
        { range: "5-9", imagePath: "FROM_5_TO_9.webp", index: 0 },
        { range: "10-14", imagePath: "FROM_10_TO_14.webp", index: 1 },
        { range: "15-19", imagePath: "FROM_15_TO_19.webp", index: 2 },
        { range: "20-24", imagePath: "FROM_20_TO_24.webp", index: 3 },
        { range: "25-29", imagePath: "FROM_25_TO_29.webp", index: 4 },
        { range: "30-34", imagePath: "FROM_30_TO_34.webp", index: 5 },
        { range: "35-39", imagePath: "FROM_35_TO_39.webp", index: 6 },
        { range: ">40", imagePath: "MORE_THAN_40.webp", index: 7 }
    ];


    const onClicked = () => {
        onSelected(bodyfatRangePercentage[bodyFat]);
    }

    const handleBodyFatChange = (event) => {
        const newValue = event.target.value;
        setBodyFat(newValue);
        const sliderWidth = event.target.clientWidth;
        const sliderHeight = event.target.clientHeight;
        const newPosition = {
            left: (newValue / 8) * sliderWidth,
            top: -sliderHeight - 50, // Adjust based on your preference
        };
        setTooltipPosition(newPosition);
    };


    useEffect(() => {
        // Calculate initial tooltip position based on initial bodyFat value
        const sliderWidth = document.querySelector('.csZvvW0').clientWidth;
        const sliderHeight = document.querySelector('.csZvvW0').clientHeight;
        const newPosition = {
            left: (bodyFat / 8) * sliderWidth,
            top: -sliderHeight - 50, // Adjust based on your preference
        };
        setTooltipPosition(newPosition);

        import(`../../assets/images/${bodyfatRangePercentage[bodyFat].imagePath}`).then(icon => {
            setIconSrc(icon.default);
        });
    }, [bodyFat]);


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    return (
        <div className="fQWqWj">
            <div className="jlxXQZ grid-bg">
                <div className="eMcVWt">
                    <div className="uxnsv">
                        <span className="ePONvi visible">
                            <span>
                                <span >
                                    <img src={iconSrc}
                                        alt={`Body Fat Range: ${iconSrc}`}
                                        style={{ display: "block", maxWidth: "100%", width: "190px", height: "475px", background: "none", opacity: 1, border: "0px", alignSelf: 'center' }} />
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="fOdZdy">
                <div className="ltAyV">
                    <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                        <div className="osnNu">
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>
                    </div>
                    <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                        <div className="irmowo" style={{ marginTop: "20px" }}>
                            <div className="bUfyfo">
                                <div className="jHlIGZ">
                                    <div
                                        className="jGwXkI gvAqMT"
                                        style={{ left: `${tooltipPosition.left}px`, top: `${tooltipPosition.top}px`, position: 'absolute' }}
                                    >
                                        <p className="cDpolK0">{`${bodyfatRangePercentage[bodyFat].range}%`}</p>
                                    </div>
                                    <div>
                                    </div>
                                    <input type="range"
                                        min="0"
                                        max="7"
                                        step="1"
                                        name="bodyFat"
                                        className="csZvvW0"
                                        value={bodyFat}
                                        onChange={handleBodyFatChange}
                                        data-tooltip="Your Tooltip Text Here"
                                    />
                                    <div className="dAIBsK">
                                    </div>
                                </div><div className="ehFIUl">
                                    <p className="dezAzo">5-9%</p>
                                    <p className="dezAzo">&gt;40%</p>
                                </div>
                                {screenWidth > 1023 && <div style={{ alignSelf: "end", marginTop: "20px" }}>
                                    <RightArrowButton label={"Continue"} onClick={onClicked} width="200px" />
                                </div>}
                            </div>
                        </div>
                    </div>
                    {screenWidth < 1023 && <BottomArrowButton onClick={onClicked} label="Continue" />}

                </div>
            </div>
        </div >
    )
}

FormRangeWithCondition.propTypes = {
    onSelected: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default FormRangeWithCondition;