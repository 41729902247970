import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import { useNavigate, useLocation } from 'react-router-dom';
import RightArrowButton from '../../layout/button/RightArrowButton';
import '../../assets/styles/FormRangeWithCondition.css';
import healthstatus from '../../assets/images/health-status.png'
import BottomArrowButton from "../../layout/button/BottomArrowButton";

const StepAttention = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const currentStepIndex = location.state ? location.state.currentStepIndex : 0;

    const onClicked = () => {
        navigate('/step-push-ups', { state: { currentStepIndex: currentStepIndex } });
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);

    return (
        <div style={{ height: '100%' }}>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "transparent" }}>
                <Header/>
            </div>
            <div style={{ paddingTop: '0px', height: "100%" }}>
                <div className="fQWqWj">
                    <div className="jlxXQZ grid-bg">
                        <div className="uxnsv" style={{ padding: '0px' }}>
                            <span className="ePONvi visible" style={{ marginTop: '60px' }}>
                                <img alt="" aria-hidden="true" src={healthstatus} style={{ display: "block", width: "100%", maxWidth: "672px", height: "100%", maxHeight: "755.55px", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", alignSelf: 'center' }} />
                            </span>
                        </div>
                    </div>
                    <div className="fOdZdy">
                        <div className="ltAyV">
                            <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                                <div className="osnNu">
                                    <h1 className="bpzQjr bsCFUQ" style={{ marginTop: "0px" }}>We care about your health</h1>
                                </div>
                            </div>
                            <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                                <div className="irmowo">
                                    <div className="bUfyfo">
                                        <div style={{ width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: "start", color: 'white', textAlign: 'left' }}>
                                            <p style={{ fontSize: "18px" }}>
                                                If you have any of the following conditions:
                                            </p>
                                            <ul style={{ fontSize: '18px', textAlign: "start", paddingLeft: '15px' }}>
                                                <li>Heart disease</li>
                                                <li>Severe scoliosis</li>
                                                <li>Spinal damage</li>
                                                <li>Benign or malignant tumor</li>
                                                <li>Hypertension</li>
                                            </ul>
                                            <p style={{ textAlign: 'start', fontSize: "18px" }}>
                                                Please contact your physician before starting to follow the Health Haven program.                                                 </p>
                                            {screenWidth > 1023 && <div style={{ alignSelf: "end", marginTop: "60px" }}>
                                                <RightArrowButton label={"Got it"} onClick={onClicked} width="200px" />
                                            </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {screenWidth < 1023 && <BottomArrowButton onClick={onClicked} label="Got it" />}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default StepAttention;