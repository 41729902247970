
import React, { useEffect } from "react";
import Header from "../../layout/header/Header";
import '../../assets/styles/Dashboard.css'
import Main from "../../layout/main/Main";
import { useLocation } from 'react-router-dom';
import StaticFaqSection from "../../components/Faq";
import ReviewSection from "../../components/Review";
import WhyChooseSection from "../../layout/chooseus/Chooseus";
import HowDoesItworksSection from "../../layout/howdoesitwork/HowDoesItwork";

const Dashboard = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);

    return (
        <div className="root-container">
            <div className="header-container">
                <Header />
                <Main />
            </div>
            <div style={{ marginTop: "130px" }}>
                <StaticFaqSection />
            </div>
            <ReviewSection />
            <WhyChooseSection />
            <HowDoesItworksSection />
            <div style={{ height: "100px" }}></div>
        </div>


    );
};

export default Dashboard;