import React from "react";
import Header from "../../layout/header/Header";
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import Twitter from '../../assets/images/twitter.png';


function SupportItem({ title, icon }) {
    return (
        <div style={{color:'white'}}>
            <img src={icon} alt={title} style={{ color: 'white', width: '60px', height: '60px' }} />
            <div>
                <h4>{title}</h4>
            </div>
        </div>
    );
}

const Support = () => {

    const handleClick = () => {
        window.location.href = `mailto:support@healthhaven.co.uk`;
      };
    
    return (
        <div style={{ backgroundColor: "black", height: "100vh" , background: "radial-gradient(60.6% 40.78% at 49.85% 44.22%, rgba(31, 147, 255, 0.275) 0%, rgba(30, 30, 30, 0) 100%)"}}>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "black", zIndex: 3 }}>
                <Header isHumbergerVisible={true} />
            </div>
            <div className="container" style={{ color: 'white', paddingTop: "60px" }}>
                <div className="col-md-12" style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', textAlign: 'center', paddingTop: "100px", paddingLeft:'20px', paddingRight:'20px'}}>
                    <h2>Health Heaven Technologies Ltd</h2>
                    <br/>
                    <a href="mailto:support@healthhaven.co.uk" onClick={handleClick}>support@healthhaven.co.uk</a>
                    <br/>
                    <div>International House 142 Cromwell Rd SW7 4EF</div>
                </div>
                <br/><br/>
                <br/><br/>
                <div  style={{          
                    height:'500px' ,
                    padding: "40px",
                    background: "radial-gradient(64.53% 62.88% at 50.13% 45.92%, #000c 0, #0000 100%), linear-gradient(0deg, #232323, #232323)"
}}>
                    <h1>Socials</h1>
                    <br />
                    <div>Follow us on our socials to get the hottest fitness info delivered to you daily.</div>

                    <br />
                    <br />
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10vw' }}>
                        <a href="http://www.facebook.com/healthhavenofficial" title="Facebook"  rel="noopener noreferrer"><SupportItem title="Facebook" icon={facebook} /></a>
                        <a href="http://www.instagram.com/healthhavenuk" title="Instagram"  rel="noopener noreferrer"><SupportItem title="Instagram" icon={instagram} /></a>
                        <a href="http://www.twitter.com/healthhavenuk" title="space"  rel="noopener noreferrer"><SupportItem title="X" icon={Twitter} /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;
