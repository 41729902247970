// NetworkService.js

import axios from 'axios';
import { getStepsData, postData, getResults, updateCheckoutsData } from '../firebase/firebaseservice';

class NetworkService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }



  async getData() {
    var list = await getStepsData();
    return list;
  }

  async postData(data) {
    return await postData(data);
  }

  async getResults(docId) {
    var results = await getResults(docId);
    return results;
  }

  async onCheckoutSuccess(data) {
    return await updateCheckoutsData(data);
  }

  async getClientSecret(param) {
    const response = await this.api.post('/createPaymentIntent', param);
    return response.data;
  }

  async getProducts() {
    const response = await this.api.get('/getProductsList');
    return response.data;
  }


}



export default NetworkService;
