import React from 'react';
import '../../assets/styles/ProceedToMyPlanDialog.css'
import consentModelBg from '../../assets/images/consent_modal_bg.webp';

const ProceedToMyPlanDialog = ({ onClose }) => {

    return (

        <div className="kgPnpb cCVhFd" style={{ alignItems: "center", inset: "0px", display: "flex", overflow: "auto", position: "fixed" }}>
            <div tabIndex="-1" style={{ margin: "auto" }}>
                <div className="hFIkTS ModalInner">
                    <div className="dwUDVU">
                        <span style={{ boxSizing: "borderBox", display: "inlineBlock", overflow: "hidden", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", position: "relative", maxWidth: "100%" }}>
                            <span style={{ boxSizing: "borderBox", display: "block", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", maxWidth: "100%" }}>
                                <img alt="" aria-hidden="true" src={consentModelBg} style={{ display: "block", maxWidth: "100%", width: "initial", height: "initial", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px" }} />
                            </span>
                            <div style={{ position: "relative", inset: "0px", boxSizing: "borderBox", padding: "0px", border: "none", margin: "auto", display: "block", width: "0px", height: "0px", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "100%", background: "black" }} />
                        </span>
                    </div>
                    <div className="kyAyiJ">
                        <h3 className="ioepIY lnihYb">May we send product updates to your email — </h3>
                        <p className="fKEBTI">expert tips, promotions, special offers?</p>
                        <p className="lhlHag jXaydE">You can change your mind at any time by clicking the unsubscribe link in the footer of any email you receive from us. Our Privacy Policy is available at <a title="Privacy Policy" target="_blank" rel="noopener noreferrer" href="/privacy-policy" style={{ color: 'white' }}>link</a>.</p>
                        <button title="Yes, proceed to my plan" className="fdiIEf lbleke" onClick={onClose}>
                            <p className="kbMuAn" style={{ textAlign: 'center' }}>Yes, proceed to my plan</p>
                        </button>
                        <button title="No, send my program only" className="eTNYAs" onClick={onClose}>No, send my program only</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProceedToMyPlanDialog;