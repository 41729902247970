import React, { useEffect } from 'react';
import '../../assets/styles/PaymentSuccessMessage.css'; // Import CSS file for styling
import paymentCheck from '../../assets/images/payment-check.png'; // Import function to check if payment is successful


const PaymentSuccessMessage = () => {


  

  useEffect(() => {
    const handleBackButton = (event) => {
      console.log('Back button clicked');
      window.history.pushState(null, null, '/');
    };
  // Add event listener when component mounts
  window.history.pushState(null, document.title, '/');
  window.addEventListener('popstate', handleBackButton);    
  
  return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);


  const handleClick = () => {
    window.location.replace('/',{replace:true});
  };

  return (
    <div className="payment-success-message">
      <div style={{ alignSelf: 'center', justifyContent: 'center' }}>
        <img src={paymentCheck} alt="Payment Successful" style={{ width: '100px', height: '100px' }} />
        <h2>Thank you for your order!</h2>
        <p>Woohoo! Your payment was successful, and your order was completed. A receipt is on its way to your inbox.</p>
        <button
          onClick={handleClick}
          className="button-go-to-dashboard">
          Go to Dashboard
        </button>
      </div>
    </div>
  );
}

export default PaymentSuccessMessage;
