import React, { useEffect, useState } from 'react';
import Header from "../../layout/header/Header";
import { useNavigate, useLocation } from 'react-router-dom';
import RightArrowButton from '../../layout/button/RightArrowButton';
import '../../assets/styles/FormRangeWithCondition.css';
import rangeImage from '../../assets/images/FROM_5_TO_9.webp'
import BottomArrowButton from "../../layout/button/BottomArrowButton";

const StepGiveUp = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const currentStepIndex = location.state ? location.state.currentStepIndex : 0;

    const onClicked = () => {
        navigate('/step-additional-goals', { state: { currentStepIndex: currentStepIndex } });
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top of the page when the route changes
    }, [location.pathname]);

    return (
        <div style={{ height: "100%" }}>
            <div style={{ position: "fixed", width: '100%', backgroundColor: "transparent" }}>
                <Header />
            </div>
            <div style={{ paddingTop: '0px', height: "100%" }}>
                <div className="fQWqWj">
                    <div className="jlxXQZ grid-bg">
                        <div className="uxnsv">
                            <span className="ePONvi visible">
                                <span>
                                    <span >
                                        <img alt="" aria-hidden="true" src={rangeImage} style={{ display: "block", maxWidth: "100%", width: "190px", height: "475px", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", alignSelf: 'center' }} />
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="fOdZdy">
                        <div className="ltAyV">
                            <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                                <div className="osnNu">
                                    <h1 className='bpzQjr' style={{ marginBottom: "0px" }}>
                                        Halfway there!
                                    </h1>
                                    <h1 className="bpzQjr bsCFUQ" style={{ marginTop: "0px" }}>Why do people give up on exercise?</h1>
                                </div>
                            </div>
                            <div className="gppALw" style={{ backgroundColor: "#1E1E1E" }}>
                                <div className="irmowo">
                                    <div className="bUfyfo">
                                        <div style={{ width: "100%", justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: "start", color: 'white', textAlign: 'start' }}>
                                            <p style={{ fontSize: "18px", fontWeight: 700 }}>
                                                The #1 reason is starting too big too quickly
                                            </p>
                                            <p style={{ fontSize: "16px" }}>
                                                You’ll achieve much more than just a few weeks of exercising.
                                            </p>
                                            <p style={{ textAlign: 'start', fontSize: "16px" }}>
                                                We won’t promise you quick results. Our program’s primary goal is to change your lifestyle for better health, whatever that looks like for you.
                                            </p>
                                            {screenWidth > 1023 && <div style={{ alignSelf: "end", marginTop: "20px" }}>
                                                <RightArrowButton label={"Got it"} onClick={onClicked} width="200px" />
                                            </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {screenWidth < 1023 && <BottomArrowButton onClick={onClicked} label="Got it" />}

                        </div>
                    </div>
                </div>

                {/* <div style={{display:"flex", flexDirection:'row'}}>
            <div  className="jYMLVW grid-bg" style={{width:"50%", alignItems:"center"}}>
                <span class="sc-cf530145-1 ePONvi visible">
                    <span style={{boxSizing: "border-box",display: "inline-block" , overflow: "hidden", width: "initial" , height: "initial" ,  background: "none" , opacity: 1 , border: "0px",  margin: "0px" , padding: "0px" , position: "relative" , maxWidth: "100%"}}>
                        <span style={{boxSizing: "borderBox",  display: "block",  width: "initial", height: "initial" , background: "none" , opacity: 1, border: "0px",  margin: "0px",  padding: "0px" ,  maxWidth: "100%"}}>
                            <img alt="" aria-hidden="true" src={rangeImage} style={{display: "block", maxWidth: "100%",  width: "190px", height: "475px", background: "none", opacity: 1, border: "0px", margin: "0px", padding: "0px", alignSelf:'center'}} /> 
                        </span>
                    </span>
                </span>
            </div>
            <div  style={{width:"50%", justifyContent:'center', display:'flex', flexDirection:'column', marginLeft:"200px", marginRight:"200px", alignItems:"start", color:'white'}}>
                <h1 style={{color:'white'}}>81% of your results are about nutrition</h1>
                <p>
                To make the most gains in muscle mass and strength, you need:
                </p>
                <p>
                Enough total calories each day.
                </p>
                <p>
                Adequate protein to rebuild more muscle tissue you break down through lifting (leading to hypertrophy).
                </p>
                <div style={{alignSelf:"end"}}>
                <RightArrowButton label={"Got it" } onClick={onClicked} width="200px"/>
                </div>
            </div>
        </div> */}
            </div>
        </div>
    )

}

export default StepGiveUp;