import React, { useState, useEffect } from "react";
import '../../assets/styles/FormSquareRadioBoxWithImage.css';
import PropTypes from 'prop-types';
import RightArrowButton from "../button/RightArrowButton";
import BottomArrowButton from "../button/BottomArrowButton";


const FormRangeWithInfo = ({ step, onSubmit }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [level, setLevel] = useState(5);

    const fitnessLevel = [
        {
            title: "Inception",
            desc: "Whenever I sit on the floor, it's hard for me to stand up.",
            index: 0
        },
        {
            title: "Inception",
            desc: "After climbing up or down several flights of stairs, I need to catch my breath.",
            index: 1
        },
        {
            title: "Inception",
            desc: "I can walk comfortably for cardio, but running is tough.",
            index: 2
        },
        {
            title: "Inception",
            desc: "I work out a few times a year, enough to break a sweat.",
            index: 3
        },
        {
            title: "Proficient",
            desc: "I try to exercise once a month, but still not regularly.",
            index: 4
        },
        {
            title: "Proficient",
            desc: "I try to move once a week, but still not regularly.",
            index:5
        },
        {
            title: "Proficient",
            desc: "I started to workout at least once a week on a regular basis.",
            index: 6
        },
        {
            title: "Proficient",
            desc: "I exercised twice a week at least for the last 3 months.",
            index:7
        },
        {
            title: "Adept",
            desc: "I'm committed to fitness and I move my body whenever I can.",
            index:8
        },
        {
            title: "Adept",
            desc: "Oh, trust me. My health is in great knick, but I still want to give my hardware an upgrade.",
            index:9
        },
        {
            title: "Pro",
            desc: "I'm on fire! My health could not be better (I think?)",
            index:10
        }
    ];


    const onClicked = () => {
        onSubmit(
            {
                level: level,
                fitnessLevel: fitnessLevel[level]
            }
        );
    }

    const handleLevelOfFitnessChange = (event) => {
        setLevel(event.target.value);
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {

        if(step.selectedLevelOfFitness && step.selectedLevelOfFitness.fitnessLevel){
            setLevel(step.selectedLevelOfFitness.fitnessLevel.index||5);
        }
    }, [step.selectedLevelOfFitness?.fitnessLevel?.index]);

    return (
        <div style={{ justifyContent: 'center' }}>
            <div className="iopLoU">
                <div className="cKVRhv">
                    <div className="gppALw">
                        <div className="osnNu">
                            {!step || (!step.subtitle && !(step.subtitle.trim() === '')) ? null : (
                                <p className="dezAzo eGxKKN">{step.subtitle}</p>
                            )}
                            <h1 className="bpzQjr bsCFUQ">{step.title}</h1>
                        </div>
                    </div>
                    <div className="gppALw">

                        <div className="bIrthQ">
                            <p className="dezAzo">1</p>
                            <p className="dezAzo">10</p>
                        </div>
                        <div className="liJdVi" style={{ alignSelf: 'center' }}>
                            <input type="range"
                                min="0"
                                max="10"
                                step="1"
                                name="fitnessLavel"
                                className="csZvvW"
                                value={level}
                                onChange={handleLevelOfFitnessChange}
                                data-tooltip="Your Tooltip Text Here"
                            />
                            <div className="dAIBsK">
                            </div>
                            <div className="ehFIUl" style={{ maxWidth: "476px", width: "100%" }}>
                                <p className="dezAzo">Worst shape</p>
                                <p className="dezAzo">I'm on fire</p>
                            </div>
                            <div className="jwNpUC">
                                <p className="ecFHhf eqMVoN">{fitnessLevel[level].title}</p>
                                <p className="dezAzo">{fitnessLevel[level].desc}</p>
                            </div>
                        </div>

                    </div>
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <div className="container-arrow">
                            {screenWidth >= 1023 && (<RightArrowButton label={"Continue"} onClick={onClicked} width="200px" />)}
                        </div>
                    </div>
                </div>
            </div>


            {screenWidth < 1023 && <BottomArrowButton onClick={onClicked} label="Continue" />}

        </div>
    );
}

FormRangeWithInfo.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    step: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default FormRangeWithInfo;