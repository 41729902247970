import React from "react";
import Header from "../../layout/header/Header";
import '../../assets/styles/AboutUs.css';
import WhyChooseSection from "../../layout/chooseus/Chooseus";
import HowDoesItworksSection from "../../layout/howdoesitwork/HowDoesItwork";




const AboutUs = () => {
    return (
        <div className="about-us-container">
            <div style={{ position: "fixed", width: '100%', backgroundColor: "black", zIndex: 3 }}>
                <Header isHumbergerVisible={false} />
            </div>
            <div class="kJLZZt">
                <div class="jkzPLI"><h1>About Us</h1>
                    <p>
                        Health Haven gives you the power to find personal trainers, health coaches and fitness professionals from the palm of your hand. <br />Whatever your fitness or wellbeing goals. Health Haven is here for you by improving your relationship and progress with your trainer by offering you an array of features. Such as,<br /><br />- Workout and Nutrition Plans <br />- 1-2-1 Training Sessions <br />- Massage Therapy<br /><br />Book one of these services through the app with any trainer at any time. Pay as you go to your sessions, on a per session basis.
                    </p>
                </div>
            </div>
            <WhyChooseSection />
            <HowDoesItworksSection />
        </div>
    );
}

export default AboutUs;